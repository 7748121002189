import { createAsyncThunk } from "@reduxjs/toolkit";
import * as customerApi from "../../api/customer.api";
import { setCustomers } from "../slices/customers.slice";
import { CustomerSortMethods } from "../../api/customer.api";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (
    params: {
      text?: string;
      page?: number;
      ids?: string[];
      ordering?: CustomerSortMethods;
    },
    { dispatch, getState }
  ) => {
    const customers = await customerApi.fetchCustomers(
      params.text,
      params.page,
      params.ids,
      params.ordering
    );
    dispatch(setCustomers(customers));
    return customers;
  }
);
