import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import * as jose from "jose";
import LogRocket from "logrocket";

export type UserJobCategory = {
  id: string;
  name: string;
  order: number;
  type: string;
};

export type UserJobSeniority = {
  id: string;
  name: string;
  order: number;
};

export type UserJob = {
  id: string;
  category: string;
  name: string;
  order: number;
};

export type AuthState = {
  access_token: string;
  refresh_token: string;
  isAuthenticated: boolean;
  email: string;
  user_id: string;
  firstName: string;
  lastName: string;
  password: string;
  profile?: {
    job_category: string;
    job_name: string;
    job_seniority: string;
  };
  userInfo: {
    admin_user: string;
    avatar: string;
    timezone: string;
    customer_name: string;
    customer_id?: string;
    allowed_customers?: string[];
  };
  userJobs: {
    user_job_categories: Array<UserJobCategory>;
    user_jobs: Array<UserJob>;
    user_job_seniorities: Array<UserJobSeniority>;
  };
};

const initialState: AuthState = {
  access_token: "",
  refresh_token: "",
  isAuthenticated: false,
  email: "",
  user_id: "",
  firstName: "",
  lastName: "",
  password: "",
  profile: {
    job_category: "",
    job_name: "",
    job_seniority: "",
  },
  userInfo: { admin_user: "", avatar: "", timezone: "", customer_name: "" },
  userJobs: {
    user_job_categories: [],
    user_jobs: [],
    user_job_seniorities: [],
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      const payload = jose.decodeJwt(action.payload) as Record<string, string>;
      LogRocket.identify(payload.user_id, {
        //name: 'James Morrison',
        email: payload.email,
      });
      state.access_token = action.payload;
      state.email = payload.email;
      state.user_id = payload.user_id;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refresh_token = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    logout: (state) => {
      state.access_token = initialState.access_token;
      state.refresh_token = initialState.refresh_token;
      state.isAuthenticated = initialState.isAuthenticated;
      localStorage.clear();
    },
    setUserInfo: (
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        profile?: {
          job_category: string;
          job_name: string;
          job_seniority: string;
        };
      }>
    ) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.password = action.payload.password;
      state.profile = action.payload.profile;
    },
    setUserMe: (state, action: PayloadAction<any>) => {
      LogRocket.identify(state.user_id, {
        email: state.email,
        job_category: action.payload.profile?.job_category,
        job_name: action.payload.profile?.job_name,
        job_seniority: action.payload.profile?.job_seniority,
        timezone: action.payload.timezone,
      });
      state.userInfo = action.payload;
    },
    setUserJobs: (state, action: PayloadAction<AuthState["userJobs"]>) => {
      state.userJobs = action.payload;
    },
  },
});

export const getAuthTokens = (state: RootState) => state.auth;
export const getUserInfo = (state: RootState) => state.auth;
export const getUserAvatar = (state: RootState) => state.auth.userInfo.avatar;
export const getUserJobs = (state: RootState) => state.auth.userJobs;

export const {
  setAccessToken,
  setRefreshToken,
  setIsAuthenticated,
  logout,
  setUserInfo,
  setUserMe,
  setUserJobs,
} = authSlice.actions;

export default authSlice.reducer;
