import StyledTypography from "./StyledTypography";
import { Grid } from "@mui/material";
import * as typeset from "../../util/typeset";
import StyledButton from "./StyledButton";
import { useNavigate } from "react-router-dom";
export interface ReturnToLink {
  link: string;
  text: string;
}
export interface PageNotFoundProps {
  text?: string;
  returnToText?: string;
  returnToLink?: ReturnToLink;
}

export default function PageNotFound(props: PageNotFoundProps) {
  const { text, returnToText, returnToLink } = props;
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      sx={{ height: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      <StyledTypography
        sx={typeset.Headline}
        style={{ height: "1rem", paddingTop: "0.5rem" }}
      >
        {text ? text : "404: Page Not Found"}
      </StyledTypography>
      {returnToLink && (
        <StyledButton
          size={"medium"}
          variant={"text"}
          style={{ marginTop: "1.25rem", padding: "0 1.5rem" }}
          onClick={() => navigate(returnToLink.link)}
        >
          {returnToText ? returnToText : "Return to"} {returnToLink.text}
        </StyledButton>
      )}
    </Grid>
  );
}
