import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Trip } from "../../types/trip.type";

export interface LayoutState {
  tripDetails: {
    isOpen: boolean;
    id?: string;
    stopId?: string;
    trip?: Trip;
  };
  opsDrawer: {
    isOpen: boolean;
  };
}

const initialState: LayoutState = {
  tripDetails: { id: "", isOpen: false },
  opsDrawer: { isOpen: true },
};

export const LayoutSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openTripDetails: (
      state,
      action: PayloadAction<Omit<LayoutState["tripDetails"], "isOpen">>
    ) => {
      state.tripDetails = { ...action.payload, isOpen: true };
    },
    closeTripDetails: (state) => {
      state.tripDetails.isOpen = initialState.tripDetails.isOpen;
    },
  },
});

export const { openTripDetails, closeTripDetails } = LayoutSlice.actions;
export const getTripDetailsState = (state: RootState) =>
  state.layout.tripDetails;

export default LayoutSlice.reducer;
