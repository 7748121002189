import { SxProps, Tab, TabProps, Tabs, TabsProps, Theme } from "@mui/material";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";

export interface StyledTabsProps extends TabsProps {
  selectedTab: Number;
  labels: Array<string>;
  tabProps?: TabProps;
  fullWidth?: boolean;
  customTabStyles?: SxProps<Theme>;
  selectedNewTab: (tabNumber: number) => void;
}

export default function StyledTabs(props: StyledTabsProps) {
  const { selectedTab, labels, tabProps, customTabStyles, selectedNewTab } =
    props;

  return (
    <Tabs
      value={selectedTab}
      onChange={(_, newValue) => {
        selectedNewTab(newValue);
      }}
      TabIndicatorProps={{ sx: { height: "4px" } }}
    >
      {labels.map((label) => (
        <Tab
          {...tabProps}
          key={label}
          sx={
            customTabStyles || {
              ...typeset.Headline,
              color: colors.TrueGray[500],
              "&.Mui-selected": { color: colors.TrueGray[900] },
            }
          }
          label={label}
        />
      ))}
    </Tabs>
  );
}
