import { CircularProgress, CssBaseline, Grid } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginForm from "./components/authentication/LoginForm";
import SignUpForm from "./components/authentication/SignUpForm";
import DispatchSplash from "./components/DispatchSplash";
import LoginLanding from "./features/authentication/LoginLanding";
import LoginPortal from "./features/authentication/LoginPortal";
import LoginReset from "./features/authentication/LoginReset";
import LoginVerify from "./features/authentication/LoginVerify";
import TripDetails from "./features/trips/TripDetails";
import TripOverview from "./features/trips/TripOverview";
import { ProtectedRoute, useAuth, useLogin } from "./util/auth";
import { routes } from "./util/routes";
import StyledSnackbar from "./components/UI/StyledSnackbar";
import LoginSuccess from "./features/authentication/LoginSuccess";
import LoginInbox from "./features/authentication/LoginInbox";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { checkStaffUser, useAsyncEffect } from "./util/util";
import { fetchTimeStopsDisplay } from "./redux/thunks/config.thunk";
import { fetchUserInfo } from "./redux/thunks/auth.thunk";
import TripPortal from "./features/trips/TripPortal";
import TripsManagement from "./features/ops/TripsManagement";
import OpsLayout from "./features/ops/OpsLayout";
import AVManagement from "./features/ops/AVManagement";
import { getUserInfo } from "./redux/slices/auth.slice";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import PageNotFound from "./components/UI/PageNotFound";
import TrackingAdminTools from "./features/trips/TrackingAdminTools";

function App() {
  const dispatch = useAppDispatch();
  const loading = useLogin();
  const { isAuthenticated } = useAuth();
  const userInfo = useAppSelector(getUserInfo).userInfo;
  const isStaff = checkStaffUser(userInfo.admin_user);

  const { loading: userLoading } = useAsyncEffect(async () => {
    if (isAuthenticated) {
      await dispatch(fetchTimeStopsDisplay());
      await dispatch(fetchUserInfo());
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        sx={{ height: "100vh" }}
        alignItems="center"
        justifyContent="center"
      >
        <CssBaseline />
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <CssBaseline />
      <StyledSnackbar />
      <Routes>
        <Route path={routes.loginRoutes.root} element={<LoginPortal />}>
          <Route index element={<LoginForm />} />
          <Route path="sign-up/:page" element={<SignUpForm />} />
          <Route path="success" element={<LoginSuccess />} />
          <Route path="inbox" element={<LoginInbox />} />
        </Route>
        <Route path={"/account"} element={<LoginPortal />}>
          <Route path="password-reset/:signature?" element={<LoginReset />} />
        </Route>
        <Route
          path="/account/register/activate/:token"
          element={<LoginVerify />}
        />
        <Route path="/help" element={<DispatchSplash />} />
        <Route path={routes.loginRoutes.landing} element={<LoginLanding />} />
        {userLoading ? (
          <Route path="*" element={<LoadingSpinner height="100vh" />} />
        ) : (
          <Route path="/" element={<ProtectedRoute />}>
            <Route
              index
              element={
                <Navigate
                  to={isStaff ? "/ops/trips/manage" : "/trips"}
                  replace
                />
              }
            />
            <Route path="trips" element={<TripPortal />}>
              <Route index element={<TripOverview />} />
              <Route path="details/:id/:stopId" element={<TripDetails />} />
            </Route>
            <Route
              path="ops"
              element={
                !isStaff ? (
                  <Navigate to={{ pathname: "/trips" }} />
                ) : (
                  <OpsLayout />
                )
              }
            >
              <Route index element={<Navigate to="/ops/trips/manage" />} />
              <Route path="trips">
                <Route index element={<Navigate to="/ops/trips/manage" />} />
                <Route
                  path="*"
                  element={
                    <PageNotFound
                      returnToLink={{
                        text: isStaff ? "Trips Management" : "Trips",
                        link: isStaff ? "/ops/trips/manage" : "/trips",
                      }}
                    />
                  }
                />
                <Route path="manage" element={<TripsManagement />} />
                <Route path="customer" element={<TripOverview isOpsPortal />} />
                <Route path="tracking-admin" element={<TrackingAdminTools />} />
              </Route>
              <Route path="avs">
                <Route index element={<AVManagement />} />
              </Route>
            </Route>
          </Route>
        )}
        <Route
          path="*"
          element={
            <PageNotFound
              returnToLink={{
                text: isStaff ? "Trips Management" : "Trips",
                link: isStaff ? "/ops/trips/manage" : "/trips",
              }}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
