import { DialogProps, Grid, Box } from "@mui/material";
import * as typeset from "../../util/typeset";
import * as colors from "../../util/colors";
import StyledTypography from "../UI/StyledTypography";
import { Formik } from "formik";
import * as yup from "yup";
import { exportTripData } from "../../redux/thunks/trip.thunk";
import { useAppDispatch } from "../../redux/hooks";
import StyledDatePicker from "../UI/StyledDatePicker";
import { EXPORT_MAX_DAYS_AMOUNT } from "../../util/constants";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import { useAsyncEffect } from "../../util/util";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import CustomerFilter from "../UI/CustomerFilter";
import dayjs, { Dayjs } from "dayjs";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { Customer } from "../../types/customer.type";
import { useEffect, useState } from "react";
import StyledDialog from "../UI/StyledDialog";

const TripExportSchema = yup.object().shape({
  // customer_id: yup.string().required().label("Customer"),
  startDate: yup.date().required().label("Start date"),
  endDate: yup.date().required().label("End date"),
});

export interface TripReportDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void;
}
export interface TripReportDialogFormProps {
  customer_id: string;
  startDate?: Dayjs | undefined | null;
  endDate?: Dayjs | undefined | null;
}

const TripReportDialog = (props: TripReportDialogProps) => {
  const { onClose, open } = props;
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [isDateError, setIsDateError] = useState<boolean>(false);

  const initialValues: TripReportDialogFormProps = {
    customer_id: "",
    startDate: null,
    endDate: null,
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCustomer(undefined);
  }, [open]);

  useAsyncEffect(async () => {
    setCustomer(undefined);
    await Promise.all([dispatch(fetchCustomers({}))]);
  }, []);

  const maxEndDate = (startDate: undefined | Date) => {
    const today = dayjs(new Date());
    const maxDate = dayjs(startDate).add(EXPORT_MAX_DAYS_AMOUNT, "day");
    return maxDate < today ? maxDate : today;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ startDate, endDate }, { resetForm }) => {
        const customer_name = customer?.name || "";

        const start_date = startDate
          ? dayjs(startDate).format("YYYY-MM-DD")
          : "";
        const end_date = endDate
          ? dayjs(endDate).add(1, "day").format("YYYY-MM-DD")
          : "";

        await Promise.all([
          dispatch(
            exportTripData({
              customer_id: customer?.id || "",
              customer_name,
              start_date,
              end_date,
            })
          ),
        ]).then(() => {
          dispatch(
            openSnackbar({
              severity: "success",
              message: "Trip report successfully created.",
              position: { horizontal: "center", vertical: "bottom" },
            })
          );
          resetForm();
          onClose();
        });
      }}
      validationSchema={TripExportSchema}
    >
      {({ isValid, values, resetForm, setFieldValue, isSubmitting, dirty }) => (
        <StyledDialog
          // key={0}
          sx={{
            "& .MuiDialog-container": {
              // "not" statement here prevents autocomplete dropdowns from also changing
              "& .MuiPaper-root:not(.MuiAutocomplete-paper)": {
                height: "350px",
              },
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "750px", // Set your width here
              },
            },
          }}
          open={open}
          onClose={onClose}
          headerText={"Create trip report"}
          primaryButtonText={"Export CSV"}
          isPrimaryButtonDisabled={
            !(dirty && isValid && values.endDate) ||
            isDateError ||
            isSubmitting ||
            !customer
          }
          primaryButtonProps={{
            type: "submit",
          }}
          secondaryButtonText={"Cancel"}
          onSecondaryClick={onClose}
          onClickClose={onClose}
        >
          {isSubmitting && <LoadingSpinner />}
          {!isSubmitting && (
            <Grid
              container
              spacing={2}
              item
              sx={{ marginBottom: 1.5, marginTop: 5 }}
            >
              {/* First Column */}
              <Grid item xs={5}>
                <Box sx={{ marginBottom: 2.5 }}>
                  <StyledTypography sx={typeset.Headline}>
                    Select customer
                  </StyledTypography>
                </Box>
                <CustomerFilter
                  hideLabel
                  id="customer_id"
                  onChangeSingle={(val) => {
                    setCustomer(val.data);
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ marginBottom: 2.5 }}>
                  <StyledTypography sx={typeset.Headline}>
                    Select date range
                  </StyledTypography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={5.5}>
                    <StyledDatePicker
                      label={"Start date"}
                      disableFormikLabel={true}
                      name={"startDate"}
                      disableFuture={true}
                      sx={{
                        ".Mui-error .MuiOutlinedInput-notchedOutline": {
                          // disabled red error state when no date has been selected
                          borderColor:
                            values.startDate === null
                              ? "inherit !important"
                              : null,
                        },
                        ".MuiInputBase-input": {
                          // used to match customer select height
                          padding: "8.5px 14px",
                          color:
                            values.startDate === undefined
                              ? colors.Grey[500]
                              : undefined,
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StyledTypography sx={typeset.Headline}>-</StyledTypography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <StyledDatePicker
                      label={"End date"}
                      name={"endDate"}
                      disableFormikLabel={true}
                      disabled={!values.startDate}
                      minDate={values.startDate ? values.startDate : undefined}
                      // @ts-expect-error
                      maxDate={maxEndDate(values.startDate)}
                      disableHighlightToday={true}
                      sx={{
                        maxHeight: "42px",
                        ".Mui-error .MuiOutlinedInput-notchedOutline": {
                          // disabled red error state when no date has been selected
                          borderColor:
                            values.endDate === null ? "inherit" : null,
                        },
                        ".MuiInputBase-input": {
                          // used to match customer select height
                          padding: "8.5px 14px",
                          WebkitTextFillColor: !values.startDate
                            ? colors.Grey[200]
                            : undefined,
                        },
                      }}
                      onError={(newError) => setIsDateError(newError !== null)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </StyledDialog>
      )}
    </Formik>
  );
};

export default TripReportDialog;
