import { createTheme } from "@mui/material";
import * as colors from "./colors";

declare module "@mui/material/styles" {
  interface Palette {
    secondaryAction?: PaletteOptions["primary"];
    errorAction?: PaletteOptions["primary"];
  }
  interface PaletteOptions {
    secondaryAction?: PaletteOptions["primary"];
    errorAction?: PaletteOptions["primary"];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    secondaryAction?: true;
    errorAction?: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 550,
      sm: 702,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: { main: "#5921DB" },
    secondary: { main: colors.Grey[700] },
    background: { default: "#EBECF6" },
    error: { main: "#be063f" },
    action: {
      disabledBackground: "", // don't set the disable background color
      disabled: colors.TrueGray[300], // set the disable foreground color
    },
    secondaryAction: {
      main: colors.Blue[500],
      contrastText: "#FFFFFF",
    },
    errorAction: {
      main: colors.Red[500],
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "DM Sans",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: colors.TrueGray[300],
            color: colors.WHITE,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {},
        standardError: {
          backgroundColor: colors.Rose[500],
          color: "white",
        },
        standardWarning: {},
        standardInfo: {},
      },
    },
    MuiCard: { styleOverrides: { root: { padding: "12px !important" } } },
  },
});

export default theme;
