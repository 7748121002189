import { useNavigate } from "react-router";
import LoginButtonSplash from "../../components/authentication/LoginButtonSplash";
import { routes } from "../../util/routes";
import { ReactComponent as SuccessIcon } from "../../assets/success.svg";
import { useQuery } from "../../util/util";

export default function LoginSuccess() {
  const navigate = useNavigate();
  const query = useQuery();

  return (
    <LoginButtonSplash
      buttonText="Login now"
      buttonVariant="contained"
      onClick={() => {
        navigate(routes.loginRoutes.root);
      }}
      icon={<SuccessIcon />}
      title="Success!"
      subTitle={
        query.get("action") === "CREATED"
          ? "Your account has been activated"
          : "Your password has been reset."
      }
    />
  );
}
