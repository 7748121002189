import { useMemo, useState } from "react";
import StyledFilter, {
  AutoCompleteItem,
  AutoCompleteProps,
} from "../../components/UI/StyledFilter";
import { CustomerSortMethods, fetchCustomers } from "../../api/customer.api";
import { Customer } from "../../types/customer.type";
import { useAsyncEffect } from "../../util/util";

export interface CustomerSelectExtraProps {
  showAllOption?: boolean;
}

export type CustomerSelectProps = Omit<
  AutoCompleteProps<string, Customer>,
  "options" | "label"
> &
  CustomerSelectExtraProps;

const CustomerFilter = (props: CustomerSelectProps) => {
  const { showAllOption, ...otherProps } = props;
  const [customers, setCustomers] = useState<Customer[]>([]);

  const { loading } = useAsyncEffect(async () => {
    const customersResp = await fetchCustomers(
      undefined,
      undefined,
      undefined,
      CustomerSortMethods.Name
    );
    setCustomers(customersResp.results);
  }, []);
  const genericCustomerObject: Customer = {
    id: "",
    name: "",
    full_name: "",
  };
  const allOption = { label: "All", value: "All", data: genericCustomerObject };
  const customerOptions: AutoCompleteItem<string, Customer>[] = useMemo(() => {
    let options = customers.map((c: Customer) => ({
      label: c.name,
      value: c.id,
      data: c,
    }));
    if (showAllOption) {
      options.unshift(allOption);
      return options;
    } else {
      return options;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  return (
    <StyledFilter
      {...otherProps}
      label="Customer"
      isLoading={loading}
      options={customerOptions}
    />
  );
};

export default CustomerFilter;
