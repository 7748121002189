import { InputAdornment } from "@mui/material";
import StyledTextfield, { IStyledTextfieldProps } from "./StyledTextfield";
import SearchIcon from "@mui/icons-material/Search";

export type IStyledSearchFieldProps = IStyledTextfieldProps;

export default function StyledSearchField(props: IStyledSearchFieldProps) {
  return (
    <StyledTextfield
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
