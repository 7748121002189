import axios from "axios";
import { logout } from "../redux/slices/auth.slice";
import { store } from "../redux/store";
import { refreshLogin } from "../redux/thunks/auth.thunk";

const fleetAxios = axios.create({
  baseURL: "/api/",
  headers: {
    Accept: "application/json",
  },
  paramsSerializer: {
    indexes: null,
  },
});

fleetAxios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("access_token"))
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fleetAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest.isAuth) {
      store.dispatch(logout());
      return Promise.reject(error);
    }
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const action = await store.dispatch(
        refreshLogin(localStorage.getItem("refresh_token") || "")
      );
      originalRequest.headers[
        "Authorization"
      ] = `Bearer ${action.payload.access}`;
      return fleetAxios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default fleetAxios;
