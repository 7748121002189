import { Grid, useTheme } from "@mui/material";
import { useLocation } from "react-router";
import { ReactComponent as LoginHeaderIcon } from "../../assets/gatik_omni.svg";
import { routes } from "../../util/routes";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import LoginSmallHeader from "./LoginSmallHeader";

export interface ILoginHeaderProps {}

export default function LoginHeader(props: ILoginHeaderProps) {
  const theme = useTheme();
  const location = useLocation();
  let headerText = "";
  let header = (
    <LoginHeaderIcon
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        height: 35,
        width: "fit-content",
      }}
    />
  );
  if (location.pathname.includes("sign-up")) {
    headerText = "Create Account";
    header = <LoginSmallHeader />;
  }
  switch (location.pathname) {
    case routes.loginRoutes.resetPassword:
      header = <LoginSmallHeader />;
      break;
    case routes.loginRoutes.inbox:
      header = <LoginSmallHeader />;
      break;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(4),
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        {header}
      </Grid>
      {headerText && (
        <Grid item sx={{ marginTop: theme.spacing(6) }}>
          <StyledTypography sx={typeset.Header1Small}>
            {headerText}
          </StyledTypography>
        </Grid>
      )}
    </Grid>
  );
}
