import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { addMinutes, format } from "date-fns";
import dayjs from "dayjs";
import { Formik, useFormikContext } from "formik";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { ReactComponent as ForwardIcon } from "../../assets/forward_arrow.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useAppDispatch } from "../../redux/hooks";
import { fetchSites } from "../../redux/thunks/site.thunk";
import { createTrip } from "../../redux/thunks/trip.thunk";
import { Customer } from "../../types/customer.type";
import { CreatedStop, Site } from "../../types/trip.type";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import {
  calculateDepartureTimes,
  calculateStartTimes,
  convertTimezone,
  formatHourlyTime,
  getTimezoneText,
  handleMove,
  replaceObj,
  useAsyncEffect,
} from "../../util/util";
import AvFilter from "../UI/AvFilter";
import CustomerFilter from "../UI/CustomerFilter";
import LoadingSpinner from "../UI/LoadingSpinner";
import StyledButton from "../UI/StyledButton";
import StyledDatePicker from "../UI/StyledDatePicker";
import StyledDialog from "../UI/StyledDialog";
import StyledTextfield from "../UI/StyledTextfield";
import StyledTypography from "../UI/StyledTypography";
import StopCreateCard from "./StopCreateCard";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { ReactComponent as WarningIcon } from "../../assets/warning_icon.svg";
import { FILTER_ITEMS_TO_RETURN } from "../../util/constants";

export type StopFormValues = {
  customer_id: string;
  av_id: string;
  scheduled_start?: Date | null;
  stops: CreatedStop[];
  name: string;
};

export const CreateTripLabelContextDefault = {
  customer: undefined,
  avName: "",
  sites: [],
  setCustomer: () => {},
  setAvName: () => {},
  setSites: () => {},
  setActiveStep: () => {},
};

export const CreateTripLabelContext = createContext<{
  customer: Customer | undefined;
  avName: string;
  sites: Site[];
  setCustomer: Dispatch<SetStateAction<Customer | undefined>>;
  setAvName: Dispatch<SetStateAction<string>>;
  setSites: Dispatch<SetStateAction<Site[]>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
}>(CreateTripLabelContextDefault);

const stageOneSchema = yup.object().shape({
  // TODO: Removed this validation and am using hard checks instead. Add this back in in the future?
  // customer_id: yup
  //   .string()
  //   .min(3, "You must select a customer id to continue")
  //   .required(),
  // av_id: yup
  //   .string()
  //   .min(3, "You must select a customer id to continue")
  //   .required(),
});

const stageTwoSchema = yup.object().shape({
  scheduled_start: yup
    .date()
    .min(
      new Date(),
      "The selected date and time must be after the current day and time."
    )
    .required("You must select a start date"),
  stops: yup.array().min(3, "You must select at least 3 stops").required(),
});

const stageThreeSchema = yup.object().shape({
  name: yup.string().required("You must enter a trip name"),
});

export const TripFormSchema = stageOneSchema
  .concat(stageTwoSchema)
  .concat(stageThreeSchema);

const TRIP_CREATE_STEPS = [
  "1. Get started",
  "2. Plan stops and schedule",
  "3. Review and confirm",
];

const initialValues: StopFormValues = {
  customer_id: "",
  av_id: "",
  scheduled_start: null,
  stops: [],
  name: "",
};

const DialogStageOne = () => {
  const formik = useFormikContext<StopFormValues>();

  const { customer, avName, setCustomer, setAvName } = useContext(
    CreateTripLabelContext
  );

  return (
    <Grid container direction="column">
      <Grid item sx={{ marginBottom: 1.5, marginTop: 5 }}>
        <StyledTypography sx={{ ...typeset.Header3, fontWeight: 700 }}>
          Get Started
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 5 }}>
        <StyledTypography sx={{ color: colors.TrueGray[500] }}>
          Schedule a one-time trip for a customer using one of their assigned
          AVs
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 1 }}>
        <StyledTypography sx={typeset.HeadlineSmall}>
          Select customer
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 5, width: 250 }}>
        <CustomerFilter
          id="customer"
          hideLabel
          defaultValue={customer?.name}
          onChangeSingle={(val) => {
            setCustomer(val.data);
            formik.setFieldValue("customer_id", val.value);
            formik.setFieldValue("stops", []);
            setAvName("");
          }}
        />
      </Grid>
      <Grid item sx={{ marginBottom: 1 }}>
        <StyledTypography sx={typeset.HeadlineSmall}>
          Select AV
        </StyledTypography>
      </Grid>
      <Grid item sx={{ width: 250 }}>
        <AvFilter
          id="av_id"
          // TODO: Remove this key. It forces a component reload when switching customer so that the dropdown doesn't need to be controlled.
          key={customer?.name}
          customerId={customer?.id}
          hideLabel
          defaultValue={avName}
          isDisabled={!customer?.id}
          onChangeSingle={(val) => {
            setAvName(val.label);
            formik.setFieldValue("av_id", val.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

const DialogStageTwo = ({
  showFinalDestinationWarning,
  onClickAddStop,
}: createDialogStageTwo) => {
  const formik = useFormikContext<StopFormValues>();
  const { customer } = useContext(CreateTripLabelContext);
  const customerTimezone = customer?.timezone;
  const stops = formik.values.stops;
  //const [dragIdx, setDragIdx] = useState(-1);
  const [addMode, setAddMode] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [currentStopBeingDragHovered, setCurrentStopBeingDragHovered] =
    useState<number | undefined>();

  const stopStartTimes = useMemo(
    () => calculateStartTimes(formik.values),
    [formik.values]
  );

  const stopDepartureTimes = useMemo(
    () => calculateDepartureTimes(formik.values),
    [formik.values]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveCard = useCallback(
    handleMove((x) => formik.setFieldValue("stops", x), stops),
    [stops]
  );

  if (!customerTimezone)
    return (
      <Grid container>
        <StyledTypography align="center" sx={{ width: "100%" }} mt={2}>
          This customer has no timezone set, you must set a timezone in the
          admin panel before creating a trip.
        </StyledTypography>
      </Grid>
    );

  return (
    <Grid
      container
      direction="column"
      // minHeight is set here so that the datepicker does not get cut off at the bottom of the screen
      sx={{ minHeight: `${isDatePickerOpen ? "500px" : null}` }}
    >
      <Grid item sx={{ marginBottom: 1.5, marginTop: 5 }}>
        <StyledTypography sx={{ ...typeset.Header3, fontWeight: 700 }}>
          Plan stops and schedule
        </StyledTypography>
      </Grid>
      <Grid item mb={5}>
        <StyledTypography sx={{ color: colors.TrueGray[500] }}>
          Set the scheduled start time of the trip, and input the planned stop
          sequence, service times, and travel times
        </StyledTypography>
      </Grid>
      <Grid item mb={1}>
        <StyledTypography sx={typeset.HeadlineSmall}>
          Select trip start date and time
        </StyledTypography>
      </Grid>
      <Grid
        item
        sx={{ width: 400, display: "flex", flexDirection: "row" }}
        mb={2}
      >
        <StyledDatePicker
          returnVanillaDate
          minDateTime={dayjs(new Date())}
          timezone={customerTimezone}
          variant="datetime"
          name="scheduled_start"
          slotProps={{ textField: { placeholder: "" } }}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          sx={{
            minWidth: "250px",
            ".Mui-error .MuiOutlinedInput-notchedOutline": {
              // disabled red error state when no date has been selected
              borderColor:
                formik.values.scheduled_start === null
                  ? "inherit !important"
                  : null,
            },
          }}
        />
        <Grid
          item
          sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
        >
          {getTimezoneText(customer.timezone)}
        </Grid>
      </Grid>
      {stops.length > 0 && (
        <Grid item mb={2}>
          <StopCreateCard
            previousTime={stopStartTimes[0]}
            defaultOpen={false}
            stop={stops[0]}
            idx={0}
            onSave={(stop) =>
              formik.setFieldValue("stops", replaceObj(0, stops, stop))
            }
            selectedCustomer={customer}
          />
        </Grid>
      )}
      {stops.map((stop, idx) => {
        if (idx === 0 || idx === stops.length - 1) {
          return <div key={idx}></div>;
        }
        return (
          <div key={idx}>
            <Grid item mb={2} key={stop.internal_id}>
              <StopCreateCard
                id={stop.internal_id}
                idx={idx}
                moveCard={moveCard}
                previousTime={stopStartTimes[idx]}
                defaultOpen={false}
                stop={stop}
                onSave={(stop) =>
                  formik.setFieldValue("stops", replaceObj(idx, stops, stop))
                }
                onDelete={() => {
                  formik.setFieldValue(
                    "stops",
                    stops.filter((_, itemIdx) => idx !== itemIdx)
                  );
                }}
                selectedCustomer={customer}
                onDragCard={(cardIndex) => {
                  // This logic checks if we should allow the user to drag the stop to another stop. This logic checks the stop being hovered, not the stop being dragged.
                  // This is similar to the more complex logic in TripEditDialog. Here, we don't need to check if the stop being hovered is arrived or departed.
                  const isHoveredCardOrigin = cardIndex === 0;
                  const isHoveredCardFinalDestination =
                    cardIndex === (stops.length || 0) - 1;
                  // Now we have the 2 restrictions to changing a stop.
                  const isStopEditable =
                    !isHoveredCardOrigin && !isHoveredCardFinalDestination;
                  // If the stop being hovered meets the criteria, allow the user to drop the card.
                  if (isStopEditable) {
                    setCurrentStopBeingDragHovered(cardIndex);
                  }
                }}
                isDragHovered={currentStopBeingDragHovered === idx}
              />
            </Grid>
          </div>
        );
      })}
      {stops.length === 0 && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mb={2}
          >
            <Grid item>
              <StyledTypography sx={typeset.Header3} mb={1}>
                Add origin
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography
                sx={{
                  ...typeset.body,
                  color: colors.TrueGray[500],
                  marginTop: "0.5rem",
                }}
              >
                Select the location from which the AV will depart at the trip
                start time
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              size="small"
              color="secondaryAction"
              endIcon={<PlusIcon />}
              onClick={() => {
                setAddMode(true);
                onClickAddStop && onClickAddStop();
              }}
              disabled={!formik.values.scheduled_start || addMode}
            >
              Add origin
            </StyledButton>
          </Grid>
        </Grid>
      )}
      {stops.length === 1 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Grid container direction="column" sx={{ width: "fit-content" }}>
            <Grid item>
              <StyledTypography sx={typeset.Header3}>
                Add final destination
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography
                sx={{
                  ...typeset.body,
                  color: colors.TrueGray[500],
                  marginTop: "0.5rem",
                }}
              >
                Select the location where the AV will arrive at the end of the
                trip
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              size="small"
              color="secondaryAction"
              endIcon={<PlusIcon />}
              onClick={() => {
                setAddMode(true);
                onClickAddStop && onClickAddStop();
              }}
              disabled={addMode}
            >
              Add final destination
            </StyledButton>
          </Grid>
        </Grid>
      )}
      <Grid item mb={addMode ? 2 : 0}>
        {addMode ? (
          <StopCreateCard
            previousTime={
              stops.length > 1
                ? stopDepartureTimes[stopDepartureTimes.length - 2]
                : undefined
            }
            onSave={(stop) => {
              const modifiedStop = { ...stop, id: uuidv4() };
              if (stops.length > 1) {
                formik.setFieldValue("stops", [
                  ...formik.values.stops.slice(
                    0,
                    formik.values.stops.length - 1
                  ),
                  modifiedStop,
                  formik.values.stops[formik.values.stops.length - 1],
                ]);
                setAddMode(false);
              } else {
                formik.setFieldValue("stops", [
                  ...formik.values.stops,
                  modifiedStop,
                ]);
                setAddMode(false);
              }
            }}
            onCancel={() => setAddMode(false)}
            // This needs set to undefined so that the "NEW STOP" label displays.
            idx={formik.values.stops.length === 0 ? 0 : undefined}
            selectedCustomer={customer}
          />
        ) : (
          <></>
        )}
      </Grid>
      {stops.length > 1 && (
        <Grid container justifyContent="space-between" alignItems="center">
          {stops.length > 2 ? (
            <Grid item></Grid>
          ) : (
            <Grid container direction="column" sx={{ width: "fit-content" }}>
              <Grid item>
                <StyledTypography sx={{ ...typeset.Header3 }}>
                  Add stops
                </StyledTypography>
              </Grid>
              <Grid item>
                <StyledTypography
                  sx={{
                    ...typeset.body,
                    color: colors.TrueGray[500],
                    marginTop: "0.5rem",
                  }}
                >
                  Add stops to the trip
                </StyledTypography>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <StyledButton
              variant="contained"
              size="small"
              color="secondaryAction"
              endIcon={<PlusIcon />}
              onClick={() => {
                setAddMode(true);
                onClickAddStop && onClickAddStop();
              }}
              disabled={addMode}
            >
              Add stop
            </StyledButton>
          </Grid>
        </Grid>
      )}
      {showFinalDestinationWarning &&
        formik.values.stops[formik.values.stops.length - 1]?.travel_time ===
          0 && (
          <Grid container sx={{ marginTop: "16px" }}>
            <Alert
              severity="error"
              icon={<WarningIcon />}
              sx={{ borderRadius: 2, backgroundColor: "#ED074F" }}
            >
              <AlertTitle sx={{ fontWeight: 700, marginBottom: "0" }}>
                Action Required: Set a travel time to final destination.
              </AlertTitle>
            </Alert>
          </Grid>
        )}
      {stops.length > 1 && (
        <Grid item mb={2} mt={2}>
          <StopCreateCard
            previousTime={stopStartTimes[stops.length - 1]}
            defaultOpen={false}
            stop={stops[stops.length - 1]}
            idx={stops.length - 1}
            onSave={(stop) =>
              formik.setFieldValue(
                "stops",
                replaceObj(stops.length - 1, stops, stop)
              )
            }
            selectedCustomer={customer}
          />
        </Grid>
      )}
    </Grid>
  );
};

const DialogStageThree = () => {
  const formik = useFormikContext<StopFormValues>();
  const stops = formik.values.stops;
  const { customer, avName, setActiveStep } = useContext(
    CreateTripLabelContext
  );
  const customerTimezone = customer?.timezone;
  const customerName = customer?.name;

  const scheduled_start = formik.values.scheduled_start;

  const { endTime, hours, minutes } = useMemo(() => {
    const totalMinutes = stops.reduce(
      (acc, curr) => (curr.service_time || 0) + (curr.travel_time || 0) + acc,
      0
    );
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor((totalMinutes / 60 - hours) * 60);
    return {
      endTime: scheduled_start
        ? addMinutes(scheduled_start, totalMinutes)
        : new Date(),
      hours,
      minutes,
    };
  }, [stops, scheduled_start]);

  const stopTimes = useMemo(
    () => calculateStartTimes(formik.values),
    [formik.values]
  );

  return (
    <Grid container direction="column">
      <Grid item sx={{ marginBottom: 1.5, marginTop: 5 }}>
        <StyledTypography sx={{ ...typeset.Header3, fontWeight: 700 }}>
          Review and confirm
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 1 }}>
        <StyledTypography
          sx={{ ...typeset.HeadlineSmall, color: colors.TrueGray[500] }}
        >
          Give the trip a name, and check that all the details are correct
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 1 }}>
        <StyledTypography sx={{ ...typeset.HeadlineSmall, marginTop: "1rem" }}>
          Enter trip name
        </StyledTypography>
      </Grid>
      <Grid item sx={{ marginBottom: 5, width: 350 }}>
        <StyledTextfield
          InputProps={{
            placeholder: "New Trip Name",
            endAdornment: (
              <InputAdornment position="end">
                -
                {scheduled_start
                  ? format(
                      convertTimezone(
                        scheduled_start,
                        customerTimezone ||
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                      ),
                      "MMddyy"
                    )
                  : "na"}
              </InputAdornment>
            ),
          }}
          name="name"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        p={2}
        sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
      >
        <Grid container sx={{ width: "fit-content" }}>
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mr={4}
          >
            <Grid item mb={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                CUSTOMER
              </StyledTypography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              {customer ? (
                <Avatar src={customer?.avatar} sx={{ marginRight: "8px" }} />
              ) : (
                <></>
              )}
              <StyledTypography>{customerName}</StyledTypography>
            </Grid>
          </Grid>
          <Grid container direction="column" sx={{ width: "fit-content" }}>
            <Grid item mb={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                ASSIGNED AV
              </StyledTypography>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              // 40px height is to match the customer avatar height
              sx={{ height: "40px" }}
            >
              <StyledTypography>{avName}</StyledTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <StyledButton
              size="small"
              variant="contained"
              color="secondaryAction"
              onClick={() => setActiveStep(0)}
            >
              Edit
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        mt={2}
        sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
      >
        <Grid container justifyContent="space-between" flexWrap="nowrap" p={2}>
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mr={4}
          >
            <Grid item mb={1} mr={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                TRIP START DATE
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography>
                {scheduled_start
                  ? format(
                      convertTimezone(
                        scheduled_start,
                        customerTimezone ||
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                      ),
                      "EEEE, LLLL d, yyyy"
                    )
                  : "No start date selected"}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mr={4}
          >
            <Grid item mb={1} mr={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                TRIP START TIME
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography>
                {scheduled_start
                  ? formatHourlyTime(scheduled_start, customerTimezone)
                  : "No start time selected"}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mr={1}
          >
            <Grid item mb={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                TRIP END TIME
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography>
                {formatHourlyTime(endTime, customerTimezone)}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            sx={{ width: "fit-content" }}
            mr={1}
          >
            <Grid item mb={1}>
              <StyledTypography
                sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
              >
                TRIP DURATION
              </StyledTypography>
            </Grid>
            <Grid item>
              <StyledTypography>{`${hours} ${
                hours > 1 ? "hrs" : "hr"
              } ${minutes} min`}</StyledTypography>
            </Grid>
          </Grid>
          <Grid item>
            <StyledButton
              size="small"
              variant="contained"
              color="secondaryAction"
              onClick={() => setActiveStep(1)}
            >
              Edit
            </StyledButton>
          </Grid>
        </Grid>
        <Divider />
        {stops.map((stop, idx) => (
          <StopCreateCard
            stop={stop}
            idx={idx}
            previousTime={stopTimes[idx]}
            readOnly
            selectedCustomer={customer}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export interface createDialogStages {
  activeStep: number;
  showFinalDestinationWarning: boolean;
  onClickAddStop?: () => void;
}

export interface createDialogStageTwo {
  showFinalDestinationWarning: boolean;
  onClickAddStop?: () => void;
}

const CreateDialogContent = ({
  activeStep,
  showFinalDestinationWarning,
  onClickAddStop,
}: createDialogStages) => {
  switch (activeStep) {
    case 0:
      return <DialogStageOne />;
    case 1:
      return (
        <DialogStageTwo
          showFinalDestinationWarning={showFinalDestinationWarning}
          onClickAddStop={onClickAddStop}
        />
      );
    case 2:
      return <DialogStageThree />;
    default:
      return <></>;
  }
};

const FormikRevalidate = ({ step }: { step: number }) => {
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [step, validateForm]);
  return null;
};

export interface TripCreateDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const TripCreateDialog = (props: TripCreateDialogProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { onClose, isOpen } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [avName, setAvName] = useState("");
  const [sites, setSites] = useState<Array<Site>>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [showFinalDestinationWarning, setShowFinalDestinationWarning] =
    useState<boolean>(false);
  const progressPercent = activeStep === 0 ? 25 : activeStep === 1 ? 50 : 75;

  useEffect(() => {
    setActiveStep(0);
  }, [isOpen]);

  useEffect(() => {
    if (scrollRef) {
      scrollToTop();
    }
  }, [activeStep]);

  const scrollToTop = () => scrollRef?.current?.scrollIntoView(true);
  const scrollToBottom = () => scrollRef?.current?.scrollIntoView(false);

  const { loading: sitesLoading } = useAsyncEffect(async () => {
    if (!customer?.id) {
      return;
    }
    const sites: Site[] = (
      await dispatch(
        fetchSites({ customer_id: customer.id, size: FILTER_ITEMS_TO_RETURN })
      )
    ).payload.features;

    setSites(sites);
  }, [customer]);

  let schema = undefined;

  switch (activeStep) {
    case 0:
      schema = stageOneSchema;
      break;
    case 1:
      schema = stageTwoSchema;
      break;
    case 2:
      schema = TripFormSchema;
      break;
    default:
      break;
  }

  const isFinal = activeStep === 2;

  const CancelModal = () => {
    return (
      <StyledDialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px", // Set your width here
            },
          },
        }}
        open={isCancelModalOpen}
        primaryButtonText="Yes, delete trip"
        tertiaryButtonText="Go back"
        onPrimaryClick={() => {
          setIsCancelModalOpen(false);
          // TODO: Currently these are not needed since we are manually reloading the component using a key. Ideally, we use these and formik.resetForm() from the parent level to reset all fields in this modal.
          setCustomer(undefined);
          setAvName("");
          setSites([]);
          setActiveStep(0);
          onClose();
        }}
        onTertiaryClick={() => setIsCancelModalOpen(false)}
        headerText={"Delete trip"}
      >
        <StyledTypography sx={{ ...typeset.body, paddingTop: "30px" }}>
          This trip is not saved. Do you want to proceed?
        </StyledTypography>
      </StyledDialog>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (val, { resetForm }) => {
        const removedIds = {
          ...val,
          name: `${val.name}-${
            val.scheduled_start
              ? format(
                  convertTimezone(
                    val.scheduled_start,
                    customer?.timezone ||
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                  ),
                  "MMddyy"
                )
              : "na"
          }`,
        };
        removedIds.stops = removedIds.stops.map((stop) => ({
          travel_time: stop.travel_time,
          service_time: stop.service_time || 0,
          site_id: stop.site_id,
        }));
        setLoading(true);
        await Promise.all([dispatch(createTrip(removedIds))])
          .then(() => {
            dispatch(
              openSnackbar({
                severity: "success",
                message: `${val.name}-${
                  val.scheduled_start
                    ? format(
                        convertTimezone(
                          val.scheduled_start,
                          customer?.timezone ||
                            Intl.DateTimeFormat().resolvedOptions().timeZone
                        ),
                        "MMddyy"
                      )
                    : "na"
                } successfully created.`,
                position: { horizontal: "center", vertical: "bottom" },
              })
            );
            setLoading(false);
            resetForm();
            onClose();
          })
          .catch(() => {
            dispatch(
              openSnackbar({
                severity: "error",
                message: "Failed to create trip.",
                position: { horizontal: "center", vertical: "bottom" },
              })
            );
            onClose();
          });
      }}
      validationSchema={schema}
      validateOnMount={true}
    >
      {(formik) => (
        <StyledDialog
          key={0}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px", // Set your width here
              },
            },
          }}
          open={isOpen}
          onClose={() => setIsCancelModalOpen(true)}
          headerText={"Create one-time trip"}
          primaryButtonText={isFinal ? "Create trip" : "Next"}
          isPrimaryButtonDisabled={
            !formik.isValid || sitesLoading || !customer || !avName
          }
          primaryButtonProps={{
            endIcon: isFinal ? <></> : <ForwardIcon />,
            type: isFinal ? "submit" : undefined,
          }}
          secondaryButtonText={activeStep === 0 ? "Cancel" : "Go back"}
          onSecondaryClick={
            activeStep === 0
              ? () => setIsCancelModalOpen(true)
              : () => setActiveStep(activeStep - 1)
          }
          onPrimaryClick={() => {
            if (
              activeStep === 1 &&
              formik.values.stops[formik.values.stops.length - 1]
                ?.travel_time === 0
            ) {
              setShowFinalDestinationWarning(true);
              setTimeout(() => {
                scrollToBottom();
              }, 1);
            } else {
              setActiveStep(activeStep + 1);
            }
          }}
          onClickClose={() => setIsCancelModalOpen(true)}
        >
          <FormikRevalidate step={activeStep} />
          <CreateTripLabelContext.Provider
            value={{
              customer,
              avName,
              sites,
              setCustomer,
              setAvName,
              setSites,
              setActiveStep,
            }}
          >
            <DialogContent sx={{ width: "100%" }} ref={scrollRef}>
              <Box sx={{ width: "100%", marginTop: 2.5 }}>
                <LinearProgress
                  variant="determinate"
                  value={progressPercent}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: colors.BlueGray[300],
                  }}
                />
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ marginTop: 2 }}
                >
                  {TRIP_CREATE_STEPS.map((step, idx) => (
                    <Grid
                      item
                      key={idx}
                      sx={{
                        ...typeset.HeadlineSmall,
                        color:
                          activeStep < idx
                            ? colors.BlueGray[300]
                            : colors.Violet[700],
                      }}
                    >
                      {step}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <CreateDialogContent
                  activeStep={activeStep}
                  showFinalDestinationWarning={showFinalDestinationWarning}
                  onClickAddStop={() =>
                    setTimeout(() => {
                      scrollToBottom();
                    }, 1)
                  }
                />
              )}
            </DialogContent>
            {CancelModal()}
          </CreateTripLabelContext.Provider>
        </StyledDialog>
      )}
    </Formik>
  );
};

export default TripCreateDialog;
