import { Grid } from "@mui/material";
import { Trip } from "../../types/trip.type";
import TripProgess from "./TripProgress";
import * as colors from "../../util/colors";
import { useAppDispatch } from "../../redux/hooks";
import { openTripDetails } from "../../redux/slices/layout.slice";
import { formatHourlyTime, useTablet } from "../../util/util";
import TripChip from "./TripChip";
export interface TripCellProps {
  trip: Trip;
  customerTimeZone?: string;
}

const TripCell = (props: TripCellProps) => {
  const { trip, customerTimeZone } = props;
  const dispatch = useAppDispatch();
  const isTablet = useTablet();
  // .slice(-1) creates an array of only the last item in the array, then [0] pulls the single object out of the array
  const lastStop =
    trip.itinerary.stops.length === 0
      ? undefined
      : trip.itinerary.stops.slice(-1)[0];
  // if the arrived_at is undefined or null, do not show a time. Needed for bugs/errors
  const completionTime = !lastStop
    ? undefined
    : lastStop.arrived_at
    ? formatHourlyTime(new Date(lastStop.arrived_at), customerTimeZone)
    : undefined;
  if (isTablet) {
    return <TripChip status={trip.status} completionTime={completionTime} />;
  }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ width: "100%", maxWidth: "400px" }}
      flexWrap="nowrap"
    >
      <Grid item sx={{ marginTop: "-20px", width: "100%", minWidth: 350 }}>
        <TripProgess trip={trip} customerTimeZone={customerTimeZone} />
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        sx={{
          color: colors.Violet[700],
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        <Grid item onClick={() => dispatch(openTripDetails({ id: trip.id }))}>
          View full trip
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TripCell;
