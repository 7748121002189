import { Button, Grid, Tooltip, useTheme } from "@mui/material";
import * as colors from "../../util/colors";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { useEffect, useState } from "react";
export interface IHelpFooterProps {}

export default function HelpFooter(props: IHelpFooterProps) {
  const theme = useTheme();

  const [justCopiedEmail, setJustCopiedEmail] = useState<boolean>(false);

  useEffect(() => {
    if (justCopiedEmail) {
      // "Trip URL copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedEmail(false);
      }, 1500);
    }
  }, [justCopiedEmail]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", marginTop: theme.spacing(8) }}
    >
      <StyledTypography
        align="center"
        sx={typeset.Caption1}
        style={{ color: colors.BlueGray[800] }}
      >
        Need help with your account? Contact us&nbsp;&nbsp;-
      </StyledTypography>
      <Tooltip
        open={justCopiedEmail}
        placement={"top"}
        sx={{ marginTop: 0.5 }}
        slotProps={{
          tooltip: {
            sx: {
              backgroundColor: "rgba(0,0,0,1)",
              fontSize: "12px",
            },
          },
        }}
        title={`Email address copied to clipboard!`}
      >
        <Button
          // color="secondary"
          sx={{
            padding: "6px 8px",
            // marginLeft: "4px",
            fontWeight: "700",
            minWidth: "unset",
            ":hover": {
              backgroundColor: "rgba(200,200,255,0.3)",
            },
            textDecoration: "underline",
            "& svg.copy-icon path": {
              stroke: colors.Violet[600],
              // stroke: colors.Grey[500],
              strokeWidth: "3",
            },
          }}
          onClick={() => {
            navigator.clipboard.writeText("omni@gatik.ai");
            setJustCopiedEmail(true);
          }}
        >
          omni@gatik.ai
          <CopyIcon
            style={{ width: "1.2em", height: "1.2em", marginLeft: "5px" }}
          />
        </Button>
      </Tooltip>
    </Grid>
  );
}
