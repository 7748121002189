import {
  Box,
  Button,
  Collapse,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ReactComponent as HeaderIcon } from "../../assets/drawer_header.svg";
import { ReactComponent as TruckIcon } from "../../assets/layout_truck.svg";
import { ReactComponent as AVIcon } from "../../assets/layout_av.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout_icon.svg";
import { ReactComponent as Caret } from "../../assets/caret.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/hamburger_handle.svg";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import * as typeset from "../../util/typeset";
import { useState } from "react";
import { DRAWER_WIDTH_OPEN } from "../../util/constants";
import { useAppDispatch } from "../../redux/hooks";
import { logout } from "../../redux/slices/auth.slice";
import { useMobile } from "../../util/util";
import * as colors from "../../util/colors";

const OPS_URL = "/ops";
const TRIP_URL = "/trips";
const TRIP_KEYS = ["manage", "customer", "tracking-admin"];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}));

const OpsLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const endPath = location.pathname.split("/").pop();
  const [tripsSelected, setTripsSelected] = useState(endPath === "trips");
  const [expandTrips, setExpandTrips] = useState(
    tripsSelected || TRIP_KEYS.includes(endPath || "")
  );
  const isMobile = useMobile();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      {isMobile && (
        <Button
          sx={{
            position: "fixed",
            backgroundColor: isDrawerOpen ? "transparent" : "white",
            padding: "15px 11px",
            margin: "16px 10px",
            transform: isDrawerOpen
              ? `translateX(${DRAWER_WIDTH_OPEN}px)`
              : null,
            // This transition matches the default MUI drawer open/close animation
            transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;",
            minWidth: "unset",
            height: "45px",
            // border: `solid 1px ${colors.TrueGray[300]}`,
            "& svg path": {
              stroke: isDrawerOpen ? "white" : colors.TrueGray[700],
              strokeWidth: isDrawerOpen ? "3" : undefined,
            },
            zIndex: "10000",
            ":hover": {
              background: isDrawerOpen
                ? "rgba(255,255,255,0.4)"
                : colors.TrueGray[200],
            },
          }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
        </Button>
      )}
      <Drawer
        sx={{
          // zIndex: "100000",
          width: DRAWER_WIDTH_OPEN,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH_OPEN,
            boxSizing: "border-box",
          },
          "& .MuiModal-backdrop": {
            pointerEvents: "none",
          },
        }}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={!isMobile || isDrawerOpen}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Grid item>
            {/* This margin matches the vertical position of the content header */}
            <DrawerHeader
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "42px",
              }}
            >
              <HeaderIcon />
            </DrawerHeader>
            <List component="nav">
              <ListItemButton
                selected={tripsSelected}
                onClick={() => {
                  if (!expandTrips) {
                    if (!TRIP_KEYS.includes(endPath || ""))
                      navigate(`${OPS_URL}${TRIP_URL}/manage`);
                  }
                  setTripsSelected(expandTrips);
                  setExpandTrips(!expandTrips);
                }}
              >
                <ListItemIcon>
                  <TruckIcon />
                </ListItemIcon>
                <ListItemText sx={{ ml: -2.25 }}>
                  <div style={{ display: "flex" }}>
                    <b style={{ marginRight: "0.5rem" }}>Trips</b>
                    <Caret
                      style={{
                        transform: `rotate(${
                          expandTrips ? "0.25turn" : "0turn"
                        })`,
                        transition: "transform 0.2s linear",
                      }}
                    />
                  </div>
                </ListItemText>
              </ListItemButton>
              <Collapse in={expandTrips} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsDrawerOpen(false);
                      navigate(`${OPS_URL}${TRIP_URL}/manage`);
                    }}
                    selected={endPath === "manage"}
                    sx={{ paddingLeft: 7 }}
                  >
                    <ListItemText>
                      <b style={typeset.HeadlineSmall}>Trip management</b>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      setIsDrawerOpen(false);
                      navigate(`${OPS_URL}${TRIP_URL}/customer`);
                      setExpandTrips(false);
                    }}
                    selected={endPath === "customer"}
                    sx={{ paddingLeft: 7 }}
                  >
                    <ListItemText>
                      <b style={typeset.HeadlineSmall}>Customer view</b>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      setIsDrawerOpen(false);
                      navigate(`${OPS_URL}${TRIP_URL}/tracking-admin`);
                    }}
                    selected={endPath === "tracking-admin"}
                    // padding right due to long text
                    sx={{ paddingLeft: 7, paddingRight: 0 }}
                  >
                    <ListItemText>
                      <b style={typeset.HeadlineSmall}>Tracking admin tools</b>
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>
              <ListItemButton
                selected={endPath === "avs"}
                onClick={() => {
                  setIsDrawerOpen(false);
                  navigate(`${OPS_URL}/avs`);
                  setTripsSelected(false);
                  setExpandTrips(false);
                }}
              >
                <ListItemIcon>
                  <AVIcon />
                </ListItemIcon>
                <ListItemText sx={{ ml: -2.25 }}>
                  <b>AVs</b>
                </ListItemText>
              </ListItemButton>
            </List>
          </Grid>
          <Grid item sx={{ marginBottom: 6 }}>
            <ListItemButton
              onClick={() => {
                dispatch(logout());
              }}
            >
              <div style={{ display: "flex", padding: "0.5rem" }}>
                <b>Log out</b>
                <LogoutIcon style={{ marginLeft: "0.75rem" }} />
              </div>
            </ListItemButton>
          </Grid>
        </Grid>
      </Drawer>
      <Box
        display="flex"
        sx={{ position: "relative" }}
        onClick={() => setIsDrawerOpen(false)}
      >
        <div
          onClick={() => setIsDrawerOpen(false)}
          style={{
            width: `calc(100vw - ${isMobile ? "54" : DRAWER_WIDTH_OPEN}px)`,
            overflow: "hidden",
            marginLeft: isMobile ? "54px" : DRAWER_WIDTH_OPEN + "px",
          }}
        >
          <Outlet />
        </div>
      </Box>
    </>
  );
};

export default OpsLayout;
