import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AV } from "../../types/av.type";
import { PagingResults } from "../../types/util.type";
import { RootState } from "../store";

export interface AVState {
  requestId?: string;
  count: number;
  avs: AV[];
}

const initialState: AVState = {
  requestId: "",
  count: 0,
  avs: [],
};

export const avSlice = createSlice({
  name: "avs",
  initialState,
  reducers: {
    setAVs: (state, action: PayloadAction<PagingResults<AV>>) => {
      state.avs = action.payload.results;
      state.count = action.payload.count;
    },
    setLatestRequestId: (
      state,
      action: PayloadAction<AVState["requestId"]>
    ) => {
      state.requestId = action.payload;
    },
  },
});

export const { setAVs, setLatestRequestId } = avSlice.actions;
export const getAVs = (state: RootState) => state.avs.avs;
export const getAVsCount = (state: RootState) => state.avs.count;
export const getLatestAVRequestId = (state: RootState) => state.avs.requestId;
export default avSlice.reducer;
