import { Alert, AlertTitle, Grid, Link } from "@mui/material";
import StyledButton from "../UI/StyledButton";
import * as colors from "../../util/colors";
import { ReactComponent as WarningIcon } from "../../assets/warning_icon.svg";
import { ReactComponent as NorthEastArrow } from "../../assets/northeast_arrow.svg";
import React, { useState, useEffect } from "react";
import { ConditionalWrapper } from "../../util/util";

export interface IAVUnassignedProps {
  index: number;
  tripId: string;
  scheduledStart: string;
  itineraryName: string;
  hideEditTripButton?: boolean;
  setEditId?: React.Dispatch<React.SetStateAction<string>>;
  setEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultEditAvValue?: (value: boolean) => void;
  shouldLinkTripName?: boolean;
}

const getRemainingTime = (scheduledStart: string) => {
  if (!scheduledStart) {
    return `0 hr 0 min`;
  }
  const diff = Math.max(new Date(scheduledStart).valueOf() - Date.now(), 0);

  const mm = Math.floor(diff / 1000 / 60) % 60;
  const hh = Math.floor(diff / 1000 / 60 / 60);

  return `${hh} hr ${mm} min`;
};

export default function AVUnassignedNotification(props: IAVUnassignedProps) {
  const {
    index,
    scheduledStart,
    itineraryName,
    tripId,
    setEditId,
    setEditOpen,
    hideEditTripButton,
    setDefaultEditAvValue,
    shouldLinkTripName,
  } = props;
  const [remainingTime, setRemainingTime] = useState(
    getRemainingTime(scheduledStart)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(getRemainingTime(scheduledStart));
    }, 60000); // Update every 1 min

    return () => clearInterval(intervalId);
  }, [remainingTime, scheduledStart]);

  return (
    <Alert
      key={index}
      severity="error"
      icon={<WarningIcon />}
      sx={{ borderRadius: 2, marginBottom: 2, backgroundColor: "#ED074F" }}
    >
      <AlertTitle sx={{ fontWeight: 700 }}>
        Action Required: Assign AV to an upcoming trip
      </AlertTitle>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          Trip{" "}
          <ConditionalWrapper
            condition={shouldLinkTripName}
            wrapper={(children: any) => {
              return (
                <Link
                  href={`?view_trip=${tripId}`}
                  sx={{ fontWeight: 700 }}
                  color={colors.WHITE}
                >
                  {children}
                </Link>
              );
            }}
          >
            {itineraryName}
          </ConditionalWrapper>{" "}
          has no AV assigned and begins in {remainingTime}. Upcoming trips
          without assigned AVs cannot be tracked on Omni.
        </Grid>
        {!hideEditTripButton && (
          <Grid item>
            <StyledButton
              onClick={() => {
                setEditId && setEditId(tripId);
                setEditOpen && setEditOpen(true);
                setDefaultEditAvValue && setDefaultEditAvValue(false);
              }}
              sx={{
                backgroundColor: colors.TrueGray[100],
                color: colors.BLACK,
                fontWeight: 700,
                px: 3,
                ":hover": {
                  backgroundColor: colors.TrueGray[200],
                },
              }}
              buttonStyle="rectangle"
              size="small"
              endIcon={<NorthEastArrow />}
            >
              Assign AV
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </Alert>
  );
}
