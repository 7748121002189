import StyledButton from "./StyledButton";
import { ReactComponent as ReloadIcon } from "../../assets/reload_icon.svg";

export interface RefreshButtonProps {
  isRefreshing?: boolean;
  onClick: () => void;
  title?: string;
}

export default function LoadingSpinner(props: RefreshButtonProps) {
  const { isRefreshing, onClick, title } = props;
  const spinsPerSecond = 4.25;
  return (
    <StyledButton size="icon" variant="text" color="inherit" onClick={onClick}>
      <ReloadIcon
        title={title}
        style={{
          // Transition duration and rotate degrees set to large arbitrary
          // numbers so that the refresh duration is determined by the parent
          transform: isRefreshing ? `rotate(${360 * 60}deg)` : "rotate(0deg)",
          transition: isRefreshing
            ? `transform ${60 / spinsPerSecond}s linear`
            : undefined,
        }}
      />
    </StyledButton>
  );
}
