import { Grid, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import TripDetailCard from "../../components/trips/TripDetailCard";
import TripDetailHeader from "../../components/trips/TripDetailHeader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCurrentTrip } from "../../redux/slices/trip.slice";
import { fetchTrip } from "../../redux/thunks/trip.thunk";
import { createCombinedStops, useAsyncEffect } from "../../util/util";
import { POLLING_DELAY_MS } from "../../util/constants";

export interface ITripDetailsProps {}

export default function TripDetails(props: ITripDetailsProps) {
  // eslint-disable-next-line
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const trip = useAppSelector(getCurrentTrip);

  const combinedStops = useMemo(() => {
    return trip?.itinerary
      ? createCombinedStops(trip?.itinerary.stops, [trip])
      : [];
  }, [trip]);

  const { loading } = useAsyncEffect(async () => {
    if (id) await dispatch(fetchTrip(id));
  }, [id]);

  useEffect(() => {
    const diffInterval = setInterval(() => {
      if (id) dispatch(fetchTrip(id));
    }, POLLING_DELAY_MS);
    return () => {
      clearInterval(diffInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        direction="column"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1268,
          padding: theme.spacing(2),
        }}
      >
        <Grid item>
          <TripDetailHeader />
        </Grid>
        {loading || !trip ? (
          <Skeleton height={500} sx={{ marginTop: "-100px" }} />
        ) : (
          <Grid item sx={{ marginTop: theme.spacing(2) }}>
            <TripDetailCard
              trip={{
                ...trip,
                itinerary: { ...trip.itinerary, stops: combinedStops },
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
