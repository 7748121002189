import { createAsyncThunk } from "@reduxjs/toolkit";
import * as siteApi from "../../api/site.api";

export const fetchSites = createAsyncThunk(
  "sites/fetchSites",
  async (params: { customer_id: string; size?: number }) => {
    const sites = await siteApi.fetchSites(params.customer_id, params.size);
    return sites;
  }
);
