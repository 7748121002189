import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as tripApi from "../../api/trip.api";
import { TripSortMethods } from "../../api/trip.api";
import { StopFormValues } from "../../components/trips/TripCreateDialog";
import { Status, Stop, Trip, TripStatus } from "../../types/trip.type";
import { parseErrorResponse } from "../../util/util";
import { openSnackbar } from "../slices/snackbar.slice";
import {
  setCurrentTrip,
  setStops,
  setTrips,
  setLatestRequestId,
} from "../slices/trip.slice";
import { RootState } from "../store";

export const fetchStops = createAsyncThunk(
  "trips/fetchStops",
  async ({ customerId }: { customerId?: string }, { dispatch, getState }) => {
    let stopsFilter = (getState() as RootState).trips.stopsFilter;
    stopsFilter = stopsFilter ? { ...stopsFilter } : null;
    const stopStatus = stopsFilter?.stop_status;
    if (
      stopsFilter?.stop_status &&
      stopsFilter.stop_status === Status.AV_DEPARTED
    ) {
      // For completed stops is necesary to get the AV_DEPARTED + AV_ARRIVED
      stopsFilter.stop_status = undefined;
    }
    if (stopsFilter && customerId) {
      stopsFilter.customer_id = customerId;
    }
    const stops = await tripApi.fetchStops(stopsFilter);

    stops.results = stops.results.map((stop: Stop) => ({
      ...stop,
    }));
    if (stopStatus && stopStatus === Status.AV_ARRIVED) {
      // Handle in progress stops
      stops.results = stops.results.filter(
        (stop: Stop) => stop.trip_status !== TripStatus.COMPLETED
      );
    } else if (stopStatus && stopStatus === Status.AV_DEPARTED) {
      // handle completed stops
      stops.results = stops.results.filter(
        (stop: Stop) =>
          stop.status === Status.AV_DEPARTED ||
          stop.trip_status === TripStatus.COMPLETED
      );
    }
    dispatch(setStops(stops));
    return stops;
  }
);

export const fetchTrip = createAsyncThunk(
  "trips/fetchTrip",
  async (id: string, { dispatch }) => {
    const trip = await tripApi.fetchTrip(id);
    dispatch(setCurrentTrip(trip));
    return trip;
  }
);

export const fetchTrips = createAsyncThunk(
  "trips/fetchTrips",
  async ({ customerId }: { customerId?: string }, { dispatch, getState }) => {
    const tripsFilter = (getState() as RootState).trips.tripsFilter;
    if (customerId && tripsFilter) {
      tripsFilter.customer_id = customerId;
    }
    const trips = await tripApi.fetchTrips(tripsFilter);
    dispatch(setTrips(trips));
    return trips;
  }
);

export const fetchTripsV2 = createAsyncThunk(
  "trips/fetchTrips",
  async (
    params: {
      text?: string;
      customer_ids?: string[];
      av_ids?: string[];
      external_ids?: string[];
      page?: number;
      statuses?: string[];
      start_date?: string;
      end_date?: string;
      ordering?: TripSortMethods;
      size?: number;
    },
    { dispatch, getState, requestId }
  ) => {
    // dispatch the latest request ID before making the API call. We then use this request ID to match it to the latest request. This prevents older api requests overriding the latest request if the first took longer.
    dispatch(setLatestRequestId(requestId));
    const trips = await tripApi.fetchTripsV2(
      params.text,
      params.customer_ids,
      params.av_ids,
      params.external_ids,
      params.page,
      params.statuses,
      params.start_date,
      params.end_date,
      params.ordering,
      params.size
    );
    // this latestRequestId will not match requestId if the call is now out of date.
    let latestRequestId = (getState() as RootState).trips.requestId;
    if (requestId === latestRequestId) {
      dispatch(setTrips(trips));
    }
    return trips;
  }
);

export const exportTripData = createAsyncThunk(
  "trips/exportTripData",
  async (
    body: {
      customer_id: string;
      customer_name: string;
      start_date: string;
      end_date: string;
    },
    { dispatch }
  ) => {
    try {
      await tripApi.exportTripData(body);
      return true;
    } catch (e) {
      if (e instanceof AxiosError) {
        dispatch(
          openSnackbar({
            message: e.response?.data
              ? parseErrorResponse(e)
              : "There was an issue with this request",
            severity: "error",
          })
        );
      }
    }
  }
);

export const createTrip = createAsyncThunk(
  "trips/createTrip",
  async (trip: StopFormValues) => {
    await tripApi.createTrip(trip);
  }
);

export const getTripById = createAsyncThunk(
  "trips/getTripById",
  async (id: string): Promise<Trip> => {
    const trip = await tripApi.fetchTripV2(id);
    return trip;
  }
);

export const updateTrip = createAsyncThunk(
  "trips/updateTrip",
  async ({ id, payload }: { id: string; payload: StopFormValues }) => {
    const result = await tripApi.updateTrip(id, payload);
    return result;
  }
);

export const deleteTrip = createAsyncThunk(
  "trips/deleteTrip",
  async (id: string) => {
    const result = await tripApi.deleteTrip(id);
    return result;
  }
);

export const updateStopStatus = createAsyncThunk(
  "trips/updateStopStatus",
  async ({ id, status }: { id: string; status: Status }) => {
    const result = await tripApi.updateStopStatus(id, status);
    return result;
  }
);
