import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AlertProps, SnackbarOrigin } from "@mui/material";

export interface SnackbarState {
  open: boolean;
  message: string | React.ReactNode;
  severity: AlertProps["severity"];
  position?: SnackbarOrigin;
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "success",
  position: { horizontal: "center", vertical: "top" },
};

export const SnackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (
      state,
      action: PayloadAction<Omit<SnackbarState, "open">>,
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.position = action.payload.position;
    },
    closeSnackbar: (state) => {
      state.open = initialState.open;
      state.message = initialState.message;
      state.severity = initialState.severity;
      state.position = initialState.position;
    },
  },
});

export const { openSnackbar, closeSnackbar } = SnackbarSlice.actions;
export const getSnackbarState = (state: RootState) => state.snackbar;

export default SnackbarSlice.reducer;
