import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import theme from "./util/theme";
import { BrowserRouter } from "react-router-dom";
import LogRocket from "logrocket";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// LogRocket.init("ddlx1a/gatik");
LogRocket.init(process.env.REACT_APP_LOGROCKET_API_KEY || "");

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // Add React.StrictMode back when troubleshooting render errors and memory leaks.
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  // </React.StrictMode>
);
