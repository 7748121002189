import { useParams } from "react-router-dom";
import LoginResetForm from "../../components/authentication/LoginResetForm";
import LoginAccountCheck from "../../components/authentication/LoginAccountCheck";
export interface ILoginResetProps {}

export default function LoginReset(props: ILoginResetProps) {
  const { signature } = useParams();
  if (signature) {
    return <LoginResetForm />;
  } else {
    return <LoginAccountCheck />;
  }
}
