import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotifications } from "../../api/notifications.api";
import { setNotifications } from "../slices/notifications.slice";


export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (_, { dispatch }) => {
    const data = await getNotifications();
    dispatch(setNotifications(data));
  },
);
