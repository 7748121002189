import { Grid, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { routes } from "../../util/routes";
import StyledButton from "../UI/StyledButton";
import StyledTextfield from "../UI/StyledTextfield";
import { signUp } from "../../redux/thunks/auth.thunk";
import { useAppDispatch } from "../../redux/hooks";
import { setUserInfo } from "../../redux/slices/auth.slice";

const stageOne = {
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
};

export const passwordSchema = {
  password: yup
    .string()
    .min(9)
    .required()
    .label("Password")
    .matches(/\d/, "You must have at least one number")
    .matches(/[A-Za-z]/, "You must have at least one letter"),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm Password")
    .oneOf([yup.ref("password")], "Passwords do not match"),
};

const stageTwo = {
  username: yup.string().required().label("Email Address"),
  ...passwordSchema,
};
const stageOneSchema = yup.object().shape(stageOne);
const stageTwoSchema = yup.object().shape(stageTwo);

export interface ISignUpFormProps {}

export default function SignUpForm(props: ISignUpFormProps) {
  const theme = useTheme();
  const { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (!page) {
    navigate(routes.loginRoutes.root);
    return null;
  }

  const numericPage = parseInt(page);
  let mainContent = <></>;
  let schema = undefined;
  switch (numericPage) {
    case 1:
      schema = stageOneSchema;
      mainContent = (
        <>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="firstName"
              name="firstName"
              fullWidth
              color="primary"
              label="First Name"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="lastName"
              name="lastName"
              fullWidth
              color="primary"
              label="Last Name"
            />
          </Grid>
        </>
      );
      break;
    case 2:
      schema = stageTwoSchema;
      mainContent = (
        <>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="username"
              name="username"
              fullWidth
              color="primary"
              label="Email Address"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="password"
              name="password"
              fullWidth
              color="primary"
              label="Password"
              type="password"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="confirmPassword"
              name="confirmPassword"
              fullWidth
              color="primary"
              label="Re-enter Password"
              type="password"
            />
          </Grid>
        </>
      );
      break;
    default:
      break;
  }
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        jobFunction: "",
        seniority: "",
      }}
      onSubmit={async (values) => {
        if (numericPage !== 2) {
          navigate(routes.loginRoutes.signUp(numericPage + 1));
          return;
        }
        dispatch(
          setUserInfo({
            email: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
          })
        );
        const action = await dispatch(
          signUp({
            email: values.username,
            password: values.password,
            confirm_password: values.confirmPassword,
            first_name: values.firstName,
            last_name: values.lastName,
          })
        );
        if (action.type === "auth/signUp/rejected") {
          if (action.payload && Object.keys(action.payload).includes("user")) {
            navigate(routes.loginRoutes.resetPassword);
          } else {
            navigate(-1);
          }
        } else if (action.payload) {
          navigate({
            pathname: routes.loginRoutes.inbox,
            search: "?action=CREATE",
          });
        }
      }}
      validationSchema={schema}
      validateOnChange={true}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          flexGrow: 1,
        }}
      >
        {mainContent}
        <Grid item sx={{ marginTop: theme.spacing(4), width: "100%" }}>
          <StyledButton
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            fullWidth
            disabled={false}
          >
            {numericPage === 2 ? "Create my account" : "Continue"}
          </StyledButton>
        </Grid>
      </Grid>
    </Formik>
  );
}
