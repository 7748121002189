import { Skeleton, Typography, TypographyProps } from "@mui/material";
import * as React from "react";

export interface IStyledTypographyProps extends TypographyProps {
  isLoading?: boolean;
}

export default function StyledTypography({
  isLoading,
  children,
  ...otherProps
}: IStyledTypographyProps) {
  return (
    <Typography {...otherProps}>
      {isLoading ? <Skeleton /> : children}
    </Typography>
  );
}
