import {
  FormControl,
  FormLabel,
  TextField,
  TextFieldProps,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useRef, useState } from "react";
import * as colors from "../../util/colors";
import CloseIcon from "@mui/icons-material/Close";

export type IStyledTextfieldProps = TextFieldProps & ExtraTextInputProps;

export interface ExtraTextInputProps {
  onClearInput?: () => void;
}

export default function StyledTextfield<T extends Record<string, any>>(
  props: IStyledTextfieldProps
) {
  const formik = useFormikContext<T>();
  const [visible, setVisible] = useState(false);
  const {
    name = "",
    label,
    onChange,
    type,
    value,
    onClearInput,
    ...otherProps
  } = props;

  const textFieldRef = useRef<HTMLInputElement>(null);

  return (
    <FormControl sx={props.fullWidth ? { width: "100%" } : undefined}>
      <FormLabel htmlFor={`${label}-field`}>{label}</FormLabel>
      <TextField
        {...otherProps}
        inputRef={textFieldRef}
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisible(!visible)}
                      edge="end"
                    >
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : onClearInput && textFieldRef?.current?.value !== ""
            ? {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      padding: "20px 8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (textFieldRef?.current) {
                        // resets the input, still need onClearInput to control/reset the parent's value state
                        textFieldRef.current.value = "";
                        onClearInput && onClearInput();
                      }
                    }}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
                ...otherProps.InputProps,
              }
            : otherProps.InputProps
        }
        type={type === "password" ? (visible ? "text" : "password") : type}
        name={name}
        onChange={(e) => {
          if (onChange) onChange(e);
          if (formik) {
            formik.setTouched({ ...formik.touched, [name]: true }, true);
            formik.handleChange(e);
          }
        }}
        value={value || formik?.values[name]}
        onBlur={formik?.handleBlur}
        error={formik && formik.touched[name] && Boolean(formik.errors[name])}
        helperText={
          formik &&
          formik.touched[name] &&
          formik.errors[name]?.toLocaleString()
        }
        id={`${label}-field`}
        sx={{
          ...otherProps.sx,
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderWidth: 2,
            },
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            borderRadius: 2,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.Violet[300],
          },
        }}
      />
    </FormControl>
  );
}
