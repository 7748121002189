import { CardActions, CardContent, Grid, Link, SxProps } from "@mui/material";
import { CombinedStop, Status, TripStatus } from "../../types/trip.type";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import * as colors from "../../util/colors";
import StyledButton from "../UI/StyledButton";
import { useNavigate } from "react-router";
import StopChip from "./StopChip";
import { useMemo } from "react";
import {
  formatHourlyTime,
  generateHighlight,
  generateMapLink,
  scrollToTop,
  useMobile,
} from "../../util/util";
import { ReactComponent as NavIcon } from "../../assets/nav_arrow.svg";
import { ReactComponent as TripArrow } from "../../assets/trip_arrow.svg";
import { ReactComponent as PurpleTruck } from "../../assets/purple_truck.svg";
import { ReactComponent as PurpleLane } from "../../assets/purple_lane.svg";
import React from "react";
import StyledCard from "../UI/StyledCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getTripDetailsState,
  openTripDetails,
} from "../../redux/slices/layout.slice";
import { routes } from "../../util/routes";
import CallGatikButton from "../UI/CallGatikButton";
import { Customer } from "../../types/customer.type";

export interface IStopCardProps {
  stop: CombinedStop;
  search?: string;
  sx?: SxProps;
  customer?: Customer;
}

export default React.memo(function StopCard(props: IStopCardProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  const tripDetails = useAppSelector(getTripDetailsState);
  const { stop, search, customer } = props;

  const mapsLink = stop.stop_info
    ? generateMapLink(stop.stop_info.location.properties.address)
    : "";
  const isSelected = stop.id === tripDetails.stopId;

  const stopStatus = useMemo(() => {
    if (stop.status === Status.AWAITING_AV && stop.arriving && stop.eta) {
      return Status.AWAITING_AV;
    } else if (
      stop.trip_status === TripStatus.COMPLETED ||
      stop.trip_status === TripStatus.SCHEDULED ||
      stop.status === Status.AWAITING_AV
    ) {
      return stop.trip_status;
    } else if (stop.status === Status.AV_DEPARTED) {
      return TripStatus.COMPLETED;
    } else {
      return Status.AV_ARRIVED;
    }
  }, [stop]);

  return (
    <StyledCard
      onClick={() => {
        if (!isMobile) {
          scrollToTop();
          dispatch(openTripDetails({ id: stop.trip_id, stopId: stop.id }));
        }
      }}
      sx={{
        border: isSelected
          ? `1px solid ${colors.Violet[700]}`
          : `1px solid ${colors.Violet[200]}`,
        ":hover": isMobile
          ? {}
          : {
              border: `1px solid ${colors.Violet[700]}`,
              marginBottom: "-2px",
            },
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Grid item sx={{ width: "100%" }}>
          <StopChip
            customerTimeZone={customer?.timezone}
            fullWidth
            status={stopStatus}
            sequenceStatus={stop.sequence_status}
            time={
              stopStatus === Status.AV_ARRIVED ||
              (stopStatus === TripStatus.COMPLETED && !stop.departed_at)
                ? stop.arrived_at || ""
                : stopStatus === TripStatus.COMPLETED
                ? stop.departed_at || ""
                : stop.ett.toString()
            }
          />
        </Grid>
        <Grid
          container
          sx={{
            padding: 1,
            backgroundColor: colors.Grey[100],
            borderRadius: 1.5,
            marginTop: 1.5,
            marginBottom: 1.5,
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{ marginBottom: 3, textOverflow: "ellipsis" }}
            flexWrap="nowrap"
          >
            <Grid item sx={{ paddingRight: "12px" }}>
              <StyledTypography sx={typeset.Header3}>
                <b>
                  {generateHighlight(
                    stop.site?.properties.name || stop.stop_info?.name || "",
                    search
                  )}
                </b>
              </StyledTypography>
            </Grid>
            <Grid item>
              {stop.eta && stop.arriving && (
                <StopChip
                  status={stop.arrival_status}
                  sequenceStatus={stop.sequence_status}
                  showBackground={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{ flexWrap: "nowrap" }}
          >
            <Grid
              item
              sx={{
                maxHeight: "39px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Link
                href={mapsLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <Grid container flexWrap={"nowrap"}>
                  <NavIcon />
                  <StyledTypography
                    sx={{
                      color: colors.BlueGray[700],
                      marginRight: 1,
                      marginLeft: 1,
                      flexShrink: 1,
                      width: "fit-content",
                      fontSize: isMobile ? 12 : 13,
                      cursor: "default",
                      paddingRight: 2,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {stop.site?.properties.location ||
                      stop.stop_info?.location.properties.address ||
                      ""}
                  </StyledTypography>
                </Grid>
              </Link>
            </Grid>

            <Grid
              container
              direction="column"
              sx={{ width: 150, minWidth: 150, paddingLeft: 3 }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <StyledTypography
                    sx={{ ...typeset.Caption3, color: colors.BlueGray[800] }}
                  >
                    {stop.status !== Status.AWAITING_AV ? "Arrived at" : "ETA"}
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography
                    sx={{ ...typeset.Caption1, color: colors.BlueGray[800] }}
                  >
                    {stop.status !== Status.AWAITING_AV ? (
                      <b>
                        {stop.arrived_at
                          ? formatHourlyTime(
                              new Date(Date.parse(stop.arrived_at)),
                              customer?.timezone
                            )
                          : "--"}
                      </b>
                    ) : (
                      <b>
                        {stop.eta && stop.arriving
                          ? formatHourlyTime(
                              new Date(Date.parse(stop.eta)),
                              customer?.timezone
                            )
                          : "--"}
                      </b>
                    )}
                  </StyledTypography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <StyledTypography
                    sx={{ ...typeset.Caption3, color: colors.BlueGray[800] }}
                  >
                    Scheduled
                  </StyledTypography>
                </Grid>
                <Grid item>
                  {" "}
                  <StyledTypography
                    sx={{ ...typeset.Caption1, color: colors.BlueGray[800] }}
                  >
                    <b>
                      {stop.scheduled_arrival
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.scheduled_arrival)),
                            customer?.timezone
                          )
                        : "--"}
                    </b>
                  </StyledTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container gap={1} alignItems="center" justifyContent="flex-end">
          <Grid
            container
            alignItems="center"
            sx={{ width: "fit-content", flexWrap: "nowrap" }}
          >
            <PurpleTruck style={{ marginRight: 8 }} />
            <StyledTypography sx={{ ...typeset.HeadlineSmall, marginRight: 3 }}>
              <span style={typeset.Caption3}>AV#</span>{" "}
              {generateHighlight(`${stop.av_id}`, search)}
            </StyledTypography>
          </Grid>
          <Grid
            container
            alignItems="center"
            sx={{ width: "fit-content", flexWrap: "nowrap" }}
          >
            <PurpleLane style={{ marginRight: 8 }} />{" "}
            <StyledTypography sx={{ ...typeset.HeadlineSmall }}>
              <span style={typeset.Caption3}>Lane ID</span>{" "}
              {generateHighlight(
                `${stop.trip_template_external_id || "N/A"}`,
                search
              )}
            </StyledTypography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing sx={{ width: "100%", padding: 0 }}>
        <Grid container justifyContent={"flex-end"} sx={{ marginTop: 3 }}>
          {stopStatus === TripStatus.INTERRUPTED ? (
            <CallGatikButton variant={"large"} />
          ) : (
            <>
              {isMobile && (
                <StyledButton
                  variant="contained"
                  color="secondary"
                  size="x-small"
                  onClick={() =>
                    navigate(routes.tripRoutes.details(stop.trip_id, stop.id))
                  }
                  endIcon={
                    <>
                      <TripArrow />
                    </>
                  }
                >
                  View full trip
                </StyledButton>
              )}
            </>
          )}
        </Grid>
      </CardActions>
    </StyledCard>
  );
});
