import StyledTable, { HeadCell } from "../../components/UI/StyledTable";
import { useAppDispatch } from "../../redux/hooks";
import { Grid, Tooltip } from "@mui/material";
import { AV, AVWithCustomerName } from "../../types/av.type";
import {
  formatHourlyTime,
  getTimezone,
  getTimezoneString,
  useAsyncEffect,
  useMobile,
} from "../../util/util";
import { useState, useMemo, useEffect } from "react";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import StyledTypography from "../../components/UI/StyledTypography";
import TripMenu from "../../components/trips/TripMenu";
import StyledSearchField from "../../components/UI/StyledSearchField";
import StyledTabs from "../../components/UI/StyledTabs";
import RefreshButton from "../../components/UI/RefreshButton";
import EditAVModal from "../avs/EditAVModal";
import { fetchAvs } from "../../redux/thunks/av.thunk";
import { useDebouncedCallback } from "use-debounce";
import { AVSortMethods, AVStatuses } from "../../api/av.api";
import CustomerFilter from "../../components/UI/CustomerFilter";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import { Customer } from "../../types/customer.type";
import { Sort } from "../../types/util.type";

type AVKey = HeadCell<AVWithCustomerName>["id"];

const AVManagement = () => {
  const dispatch = useAppDispatch();
  const [sort, setSort] = useState<AVSortMethods>(AVSortMethods.Id);
  const [order, setOrder] = useState<Sort>("asc");
  const [orderBy, setOrderBy] = useState<AVKey>("long_av_id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [avsCount, setAVsCount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [status, setStatus] = useState<AVStatuses | undefined>(
    AVStatuses.Operational
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEditAVModalOpen, setIsEditAVModalOpen] = useState(false);
  const [avSearchValue, setAVSearchValue] = useState<string>("");
  const [currentAVs, setCurrentAVs] = useState<AV[]>([]);
  const [customerFilterValues, setCustomerFilterValues] = useState<string[]>(
    []
  );
  const [currentSelectedAV, setCurrentSelectedAV] = useState<
    string | undefined
  >(undefined);
  const [customerMap, setCustomerMap] = useState(new Map());
  const [reloadNumber, setReloadNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const debounceSetSearch = useDebouncedCallback(
    (val) => setAVSearchValue(val),
    500
  );

  const isMobile = useMobile();

  useEffect(() => {
    // "Operational" tab
    if (tab === 0) {
      setStatus(AVStatuses.Operational);
    }
    // "Decommissioned" tab
    else if (tab === 1) {
      setStatus(AVStatuses.Decommissioned);
    }
    // "All" tab
    else if (tab === 2) {
      setStatus(undefined);
    }
  }, [tab]);

  useEffect(() => {
    setIsEditAVModalOpen(currentSelectedAV ? true : false);
  }, [currentSelectedAV]);

  useEffect(() => {
    if (order === "asc") {
      setSort(AVSortMethods.Id);
    }
    if (order === "desc") {
      setSort(AVSortMethods.ReverseId);
    }
  }, [order]);

  useEffect(() => {
    setPage(0);
    setReloadNumber(reloadNumber + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, order, avSearchValue, customerFilterValues]);

  const handleRefresh = () => {
    setIsRefreshing(true);
    // TODO: This triggers the useEffect for fetchTrips, that function should be pulled out of the useEffect to be called directly
    setReloadNumber(reloadNumber + 1);
    // TODO: Is there a callback we can use here instead of setTimeout?
    setTimeout(() => {
      setIsRefreshing(false);
    }, 200);
  };

  const handleSort = (_: any, sortKey: AVKey) => {
    setOrderBy(sortKey);
    setOrder(order === "asc" || sortKey !== orderBy ? "desc" : "asc");
  };

  const closeEditAVModal = () => {
    setCurrentSelectedAV(undefined);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useAsyncEffect(async () => {
    setIsLoading(true);
    // This if statement lets us know whether to reload AVs or not
    if (!isEditAVModalOpen) {
      const newAvsPayload = (
        await dispatch(
          fetchAvs({
            text: avSearchValue,
            customer_ids: customerFilterValues,
            status: status,
            page: page + 1,
            ordering: sort,
            size: rowsPerPage,
          })
        )
      ).payload;

      setAVsCount(newAvsPayload.count);
      setCurrentAVs(newAvsPayload.results);
    }
  }, [
    isEditAVModalOpen,
    page,
    status,
    sort,
    avSearchValue,
    customerFilterValues,
    reloadNumber,
    rowsPerPage,
  ]);

  useAsyncEffect(async () => {
    // Get all unique customer IDs from the current list of AVs
    const allAVCustomerIds = currentAVs.map((av) => av.customer_id);

    // Get full customer object from each unique customer id
    const customers: Customer[] = (
      await dispatch(fetchCustomers({ ids: allAVCustomerIds }))
    ).payload.results;

    // Map each customer object to the customer ID
    const newCustomerMap = new Map();
    customers.forEach((customer) => newCustomerMap.set(customer.id, customer));
    setCustomerMap(newCustomerMap);
    setIsLoading(false);
  }, [currentAVs]);

  const fullAvArray: AVWithCustomerName[] = useMemo(() => {
    // Returns AVs with customer names added
    return currentAVs.map((av) => ({
      ...av,
      customer_name: customerMap.get(av.customer_id)?.name || "Unassigned",
    }));
  }, [currentAVs, customerMap]);

  const columns = useMemo(() => {
    return [
      {
        id: "long_av_id",
        label: "AV #",
        sortable: true,
        width: 150,
        customRender: (av) => (
          <StyledTypography sx={{ color: colors.TrueGray[700] }}>
            <b>{av.long_av_id.substring(0, av.long_av_id.indexOf("-"))}</b>
            {av.long_av_id.substring(
              av.long_av_id.indexOf("-"),
              av.long_av_id.length
            )}
          </StyledTypography>
        ),
      },
      {
        id: "av.customer_id",
        label: "Customer",
        sortable: false,
        width: 100,
        customRender: (av) => (
          <Grid container direction="column">
            <Grid item>
              <StyledTypography sx={{ color: colors.TrueGray[700] }}>
                {av.customer_name}
              </StyledTypography>
            </Grid>
          </Grid>
        ),
      },
      {
        id: "id",
        label: "Actions",
        width: 50,
        customRender: (av) => (
          <TripMenu
            key={av.id}
            options={[
              {
                label: "Edit AV",
                onClick: () => {
                  setCurrentSelectedAV(av.id);
                },
              },
            ]}
          />
        ),
      },
    ] as Array<HeadCell<AVWithCustomerName>>;
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        margin: 12,
        marginRight: 0,
        borderRadius: isMobile ? "20px 0 0 20px" : "40px 0 0 40px",
        boxSizing: "content-box",
      }}
    >
      <Grid
        container
        direction="column"
        sx={{ padding: isMobile ? 4 : 7.5, paddingTop: isMobile ? 2 : 4 }}
      >
        <StyledTypography sx={typeset.Header1} mb={2}>
          Manage AVs
        </StyledTypography>
        <StyledTypography
          sx={{ ...typeset.body, color: colors.Grey[500], mb: 2.5 }}
        >
          View and edit AV-to-customer assignments
        </StyledTypography>
        <Grid
          container
          mb={2}
          sx={{ borderBottom: `1px solid ${colors.TrueGray[500]}` }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <StyledTabs
              labels={["Operational", "Decommissioned", "All"]}
              selectedTab={tab}
              selectedNewTab={(newTabNumber) => setTab(newTabNumber)}
            />
          </Grid>
          <Grid
            container
            sx={{ width: "fit-content" }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item mr={1}>
              <Tooltip
                sx={{ marginTop: 0.5 }}
                title={`Last updated ${formatHourlyTime(
                  new Date(),
                  getTimezone()
                )}
            ${getTimezoneString()}`}
              >
                <div>
                  <RefreshButton
                    onClick={() => handleRefresh()}
                    isRefreshing={isRefreshing}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <StyledSearchField
          onChange={(e) => {
            debounceSetSearch(e.target.value);
          }}
          onClearInput={() => {
            setAVSearchValue("");
          }}
          sx={{ maxWidth: 450 }}
          placeholder="Search by AV#"
        />
        <Grid container alignItems="center">
          <Grid item sx={{ marginTop: 2, marginBottom: 2 }}>
            <CustomerFilter
              isMulti={true}
              onChangeMulti={(val) =>
                setCustomerFilterValues(val.map((item) => item.value))
              }
              borderRadius="20px"
            />
          </Grid>
        </Grid>
        {isLoading || fullAvArray.length > 0 ? (
          <div style={{ maxWidth: "680px" }}>
            <StyledTable
              onSort={handleSort}
              order={order}
              orderBy={orderBy}
              data={fullAvArray}
              columns={columns}
              tablePagination={{
                count: avsCount,
                rowsPerPageOptions: [10, 25, 50, 100],
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
                sx: { overflow: "hidden", minWidth: "300px" },
                labelRowsPerPage: isMobile ? "Rows" : "Rows per page",
              }}
              isLoading={isLoading}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <StyledTypography align="center" sx={typeset.Header2Small}>
              There are no matches found for filter applied. Please try again.
            </StyledTypography>
          </div>
        )}
      </Grid>
      {isEditAVModalOpen && (
        <EditAVModal
          AV={currentAVs.filter((av) => av.id === currentSelectedAV)[0]}
          isOpen={isEditAVModalOpen}
          onClose={closeEditAVModal}
          onCancel={closeEditAVModal}
        />
      )}
    </div>
  );
};

export default AVManagement;
