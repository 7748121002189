import { ButtonProps, Grid, Theme, useTheme } from "@mui/material";
import * as typeset from "../../util/typeset";
import StyledTypography from "../../components/UI/StyledTypography";
import StyledButton from "../UI/StyledButton";

const itemPadding = (theme: Theme) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
});

export interface ILoginButtonSplash {
  buttonText?: string;
  onClick?: () => void;
  buttonVariant?: ButtonProps["variant"];
  icon?: React.ReactElement;
  title: string;
  subTitle: string;
}

export default function LoginButtonSplash(props: ILoginButtonSplash) {
  const { buttonText, buttonVariant, title, subTitle, onClick, icon } = props;
  const theme = useTheme();
  return (
    <Grid container direction="column">
      <Grid container direction="row" justifyContent="center">
        <Grid item>{icon}</Grid>
      </Grid>
      <Grid item sx={itemPadding(theme)}>
        <StyledTypography align="center" sx={typeset.Header1Small}>
          {title}
        </StyledTypography>
        <StyledTypography align="center" sx={typeset.body} p={theme.spacing(2)}>
          {subTitle}
        </StyledTypography>
      </Grid>
      <Grid
        item
        sx={{
          marginTop: theme.spacing(4),
          width: "318px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <StyledButton
          color="primary"
          size="large"
          fullWidth
          variant={buttonVariant}
          onClick={onClick}
        >
          {buttonText}
        </StyledButton>
      </Grid>
    </Grid>
  );
}
