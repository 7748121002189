import { Grid, useTheme } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import * as colors from "../../util/colors";
import { routes } from "../../util/routes";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import HelpFooter from "./HelpFooter";
export interface ILoginFooterProps {}

export default function LoginFooter(props: ILoginFooterProps) {
  const theme = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let footerText = "";
  let linkLocation = "";
  switch (location.pathname) {
    case routes.loginRoutes.root:
      footerText = "Don’t have an account? Create it now";
      linkLocation = routes.loginRoutes.signUp(1);
      break;
    case routes.loginRoutes.signUp(1):
      footerText = "I already have an account";
      linkLocation = routes.loginRoutes.root;
      break;
    case routes.loginRoutes.landing:
      footerText = "I already have an account";
      linkLocation = routes.loginRoutes.root;
      break;
    case routes.loginRoutes.inbox:
      footerText = "Go to login page";
      linkLocation = routes.loginRoutes.root;
      break;
    case routes.loginRoutes.resetPassword:
      if (searchParams.get("code")) {
        footerText = "I already have an account";
        linkLocation = routes.loginRoutes.root;
      } else {
        footerText = "Don't have an account? Create it now";
        linkLocation = routes.loginRoutes.signUp(1);
      }

      break;
    default:
      break;
  }
  return (
    <Grid container justifyContent="center" sx={{ width: "100%", paddingBottom: "16px" }}>
      <Link to={linkLocation} style={{ textDecoration: "none" }}>
        <StyledTypography
          align="center"
          sx={typeset.Caption1}
          style={{ color: colors.BLACK, marginTop: theme.spacing(3) }}
        >
          {footerText}
        </StyledTypography>
      </Link>
      <HelpFooter />
    </Grid>
  );
}
