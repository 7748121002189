import fleetAxios from "./axios";

export enum CustomerSortMethods {
  Name = "name",
  ReverseName = "-name",
  FullName = "full_name",
  ReverseFullName = "-full_name",
}

export const fetchCustomers = async (
  text?: string,
  page?: number,
  ids?: string[],
  ordering?: CustomerSortMethods
) => {
  return await fleetAxios
    .get("/v1/customers/", { params: { text, page, ids, ordering } })
    .then((resp) => resp.data);
};
