import { Autocomplete, Checkbox, SxProps, TextField, Theme } from "@mui/material";
import * as colors from "../../util/colors";

// DataType = typescript Type of data associated with that option. i.e. CustomerFilter uses Customer type
export type AutoCompleteItem<T, DataType extends Record<string, any> = {}> = {
  label: string;
  value: T;
  data?: DataType;
};

export type AutoCompleteProps<T, DataType extends Record<string, any> = {}> = {
  id?: string;
  label?: string;
  hideLabel?: boolean;
  onChangeSingle?: (option: AutoCompleteItem<T, DataType>) => void;
  onChangeMulti?: (options: AutoCompleteItem<T, DataType>[]) => void;
  options: AutoCompleteItem<T, DataType>[];
  isLoading?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  // showAllOption?: boolean;
  // hasCheckbox?: boolean;
  noResultsText?: string;
  // TODO: Fix defaultValue type to not be "any"
  defaultValue?: any;
  borderRadius?: string;
  sx?: SxProps<Theme>,
};

const StyledFilter = <
  T extends string,
  DataType extends Record<string, any> = {}
>(
  props: AutoCompleteProps<T, DataType>
) => {
  const {
    id,
    onChangeSingle,
    onChangeMulti,
    options,
    label,
    hideLabel,
    isMulti,
    isLoading,
    isDisabled,
    // showAllOption,
    // hasCheckbox = true,
    noResultsText,
    defaultValue,
    borderRadius,
    sx,
  } = props;
  let value: AutoCompleteItem<T, DataType>[] | null | undefined;

  return (
    <Autocomplete
      loading={isLoading}
      id={id}
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      // hides clear icon if not multi select
      clearIcon={isMulti ? undefined : null}
      disablePortal
      options={options}
      sx={{
        ...sx,
        minWidth: 180,
        ".MuiAutocomplete-inputRoot": {
          backgroundColor: colors.WHITE,
          borderRadius: borderRadius,
          cursor: isDisabled ? "not-allowed" : undefined,
        },
        // This 1px setting reduces white space in the autocomplete input. The whole field is still clickable so no need for this to be big.
        ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
          minWidth: "1px",
          px: "0",
          cursor: isDisabled ? "not-allowed" : undefined,
        },
        ".MuiAutocomplete-listbox .MuiAutocomplete-option": {
          fontSize: "10px",
        },
      }}
      limitTags={1}
      renderInput={(params) => (
        <TextField
          {...params}
          label={hideLabel ? undefined : label}
          // InputLabelProps={{
          //   style: { fontWeight: "700" },
          // }}
        />
      )}
      renderOption={
        !isMulti
          ? undefined
          : (props, option, { selected }) => (
              <li {...props} style={{ paddingLeft: "4px", fontSize: "14px" }}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </li>
            )
      }
      onChange={
        onChangeSingle
          ? (event: any, newValue: any | null) => onChangeSingle(newValue)
          : onChangeMulti
          ? (event: any, newValue: any | null) => onChangeMulti(newValue)
          : undefined
      }
      value={value}
      disabled={isDisabled}
      size="small"
      openOnFocus
      noOptionsText={noResultsText}
      defaultValue={defaultValue}
    />
  );
};

export default StyledFilter;
