import { useMemo, useState } from "react";
import StyledFilter, { AutoCompleteProps } from "./StyledFilter";
import { useAsyncEffect } from "../../util/util";
import { useAppDispatch } from "../../redux/hooks";
import { fetchSites } from "../../redux/thunks/site.thunk";
import { Site } from "../../types/trip.type";
import { FILTER_ITEMS_TO_RETURN } from "../../util/constants";

//TODO replace with site type
export interface SiteFilterProps
  extends Omit<AutoCompleteProps<string, Site>, "options"> {
  customer_id: string;
}

const SiteFilter = (props: SiteFilterProps) => {
  const { customer_id, ...otherProps } = props;
  const dispatch = useAppDispatch();
  const [sites, setSites] = useState<any[]>([]);

  const { loading } = useAsyncEffect(async () => {
    const newSites = (
      await dispatch(fetchSites({ customer_id, size: FILTER_ITEMS_TO_RETURN }))
    ).payload;
    setSites(newSites.features);
  }, [customer_id]);

  const options = useMemo(
    () =>
      sites.map((site) => ({
        label: site.properties.name,
        value: site.id,
        data: site,
      })),
    [sites]
  );
  return (
    <StyledFilter
      {...otherProps}
      label={"Site"}
      options={options}
      isLoading={loading}
    />
  );
};

export default SiteFilter;
