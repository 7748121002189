import {
  Avatar,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useTheme,
  FormControl,
  Select,
  Divider,
  Autocomplete,
  Box,
  Fab,
  Icon,
  Tooltip,
  ListItem,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import StyledTypography from "../../components/UI/StyledTypography";
import * as typeset from "../../util/typeset";
import * as colors from "../../util/colors";
import StyledSearchField from "../../components/UI/StyledSearchField";
import {
  checkStaffUser,
  createCombinedStops,
  getTimezoneText,
  sortStops,
  sortTrips,
  useAsyncEffect,
  useMobile,
  usePortraitMobile,
  useTablet,
} from "../../util/util";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchStops, fetchTrips } from "../../redux/thunks/trip.thunk";
import {
  calculateSets,
  getSearchOptions,
  getStops,
  getTrips,
  setStopFilter,
  setTripFilter,
} from "../../redux/slices/trip.slice";
import {
  getUserAvatar,
  getUserInfo,
  logout,
  setUserMe,
} from "../../redux/slices/auth.slice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { POLLING_DELAY_MS } from "../../util/constants";
import StopCard from "../../components/trips/StopCard";
import TripCard from "../../components/trips/TripCard";
import {
  closeTripDetails,
  getTripDetailsState,
} from "../../redux/slices/layout.slice";
import TripDetailCard from "../../components/trips/TripDetailCard";
import { Status, TripStatus } from "../../types/trip.type";
import { ReactComponent as OverviewLogo } from "../../assets/overview_logo.svg";
import { ReactComponent as MobileLogo } from "../../assets/mobile_logo.svg";
import { ReactComponent as FabArrow } from "../../assets/fab_arrow.svg";
import { ReactComponent as ToolTip } from "../../assets/tooltip.svg";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useDebouncedCallback } from "use-debounce";
import CustomerFilter from "../../components/UI/CustomerFilter";
import CallGatikButton from "../../components/UI/CallGatikButton";
import ManualTrackingAlert from "./ManualTrackingAlert";
import { dayjs } from "../../util/date-util";
import { Customer } from "../../types/customer.type";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import { updateUserInfo } from "../../api/auth.api";

let finishedFetch = false; //Boolean flag to prevent re-fetching trips/stops before the previous calls have finished

const TOP_MARGIN = 4;
const TripStatuses = [
  { key: "In Progress", value: TripStatus.IN_PROGRESS },
  { key: "Upcoming", value: TripStatus.SCHEDULED },
  { key: "Completed", value: TripStatus.COMPLETED },
];

const StopStatuses = [
  { key: "In Progress", value: Status.AV_ARRIVED },
  { key: "Upcoming", value: Status.AWAITING_AV },
  { key: "Completed", value: Status.AV_DEPARTED },
];

export interface ITripOverviewProps {
  isOpsPortal?: boolean;
}

export default function TripOverview(props: ITripOverviewProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const stops = useAppSelector(getStops);
  const trips = useAppSelector(getTrips);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tab, setTab] = useState(0);
  const [filterStatus, setFilterStatus] = useState<string>("All");
  const [searchOpen, setSearchOpen] = useState(false);
  const [customerId, setCustomerId] = useState<string>("All");
  const [filterTime, setFilterTime] = useState<string>("");
  const [selectedCustomerInfo, setSelectedCustomerInfo] = useState<
    Customer | undefined
  >();
  const tripLayout = useAppSelector(getTripDetailsState);
  const searchOptions = useAppSelector(getSearchOptions);
  const avatarLink = useAppSelector(getUserAvatar);
  const userInfo = useAppSelector(getUserInfo).userInfo;
  const [y, setY] = useState(window.scrollY);
  const open = Boolean(anchorEl);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isPortraitMobile = usePortraitMobile();

  const isStaff = checkStaffUser(userInfo.admin_user);

  const debounceSetSearch = useDebouncedCallback((val) => setSearch(val), 1000);

  const selectedCustomerValue = customerId === "All" ? undefined : customerId;

  const timeZoneText = useMemo(() => {
    return getTimezoneText(selectedCustomerInfo?.timezone || userInfo.timezone);
  }, [userInfo, selectedCustomerInfo]);

  const validDates = useMemo(() => {
    const RealTimeDateTimeBasedOnCustomerTz = dayjs(
      new Date(
        new Intl.DateTimeFormat("en-US", {
          timeZone: selectedCustomerInfo?.timezone || userInfo.timezone,
          timeZoneName: "short",
        })
          .format()
          .split(",", 1)[0]
      )
    ).toString();

    return [
      dayjs(RealTimeDateTimeBasedOnCustomerTz).subtract(1, "day"),

      dayjs(RealTimeDateTimeBasedOnCustomerTz),

      dayjs(RealTimeDateTimeBasedOnCustomerTz).add(1, "day"),
    ];
  }, [userInfo, selectedCustomerInfo]);

  useEffect(() => {
    setFilterTime(validDates[1].toString());
  }, [validDates]);

  useEffect(() => {
    if (!selectedCustomerValue) {
      setSelectedCustomerInfo(undefined);
    }
  }, [selectedCustomerValue]);

  const dateNameMap: Record<number, string> = {
    0: "Yesterday",
    1: "Today",
    2: "Tomorrow",
  };

  const selectedTrip = useMemo(() => {
    const trip = trips.find((trip) => trip.id === tripLayout.id);
    if (!trip) {
      return null;
    }
    const newTrip = {
      ...trip,
      itinerary: {
        ...trip.itinerary,
        stops: createCombinedStops(
          trip.itinerary.stops.map((stop) => ({ ...stop, trip_id: trip.id })),
          [trip]
        ),
      },
    };
    return newTrip;
  }, [trips, tripLayout]);

  const { loading: searchLoading } = useAsyncEffect(async () => {
    const date = new Date(Date.parse(filterTime));
    if (tab === 0) {
      dispatch(
        setStopFilter({ date: format(date, "yyyy-MM-dd"), text: search })
      );
      dispatch(
        setTripFilter({
          date: format(date, "yyyy-MM-dd"),
          text: search,
          trip_status:
            filterStatus === "All" ? undefined : (filterStatus as TripStatus),
          customer_id: selectedCustomerValue,
        })
      );
    } else {
      dispatch(
        setTripFilter({ date: format(date, "yyyy-MM-dd"), text: search })
      );
      dispatch(
        setStopFilter({
          date: format(date, "yyyy-MM-dd"),
          text: search,
          stop_status:
            filterStatus === "All" ? undefined : (filterStatus as Status),
          customer_id: selectedCustomerValue,
        })
      );
    }
    await Promise.all([
      dispatch(fetchTrips({})),
      dispatch(fetchStops({ customerId: selectedCustomerValue })),
    ]);
  }, [search, filterStatus, filterTime, tab, dispatch, customerId]);

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      dispatch(fetchStops({})),
      dispatch(fetchTrips({})),
    ]).then(() => {
      finishedFetch = true;
      dispatch(calculateSets());
    });
  }, []);

  const combinedStops = useMemo(() => {
    return sortStops(createCombinedStops(stops, trips));
  }, [stops, trips]);

  const sortedTrips = useMemo(() => {
    return sortTrips(trips);
  }, [trips]);

  useAsyncEffect(async () => {
    if (customerId && customerId !== "All") {
      // In case multiple customers are returned, filter the list and match the customer ID
      setSelectedCustomerInfo(
        (
          await dispatch(fetchCustomers({ ids: [customerId] }))
        ).payload.results.filter((c: Customer) => c.id === customerId)[0]
      );
    }
  }, [customerId]);

  // TODO: Is this needed?
  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
      } else if (y < window.scrollY) {
      }
      setY(window.scrollY);
    },
    [y]
  );

  // TODO: Is this needed?
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    const diffInterval = setInterval(() => {
      if (!finishedFetch) {
        return;
      }
      finishedFetch = false;
      Promise.all([
        dispatch(fetchStops({ customerId: selectedCustomerValue })),
        dispatch(fetchTrips({})),
      ]).then(() => {
        finishedFetch = true;
        dispatch(calculateSets());
      });
    }, POLLING_DELAY_MS);
    return () => {
      clearInterval(diffInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (isMobile) {
      dispatch(closeTripDetails());
    }
  }, [dispatch, isMobile]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeCustomer = async (customerId: string) => {
    await Promise.all([
      updateUserInfo({
        customer_id: customerId,
      }),
    ])
      .then((resp) => {
        setCustomerId(customerId);
        dispatch(setUserMe(resp[0].data));
      })
      .catch((resp) => {
        console.error(resp);
      });
  };

  const isFilteringStatus = filterStatus !== "All";
  const isSearching = search !== "";
  const isSplitView = Boolean(tripLayout.isOpen && selectedTrip) && !isMobile;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        marginTop: theme.spacing(TOP_MARGIN),
        paddingLeft: isTablet ? theme.spacing(2) : "15%",
        paddingRight: isTablet ? theme.spacing(2) : "15%",
        marginBottom: theme.spacing(4),
      }}
    >
      <ManualTrackingAlert isOpsPortal={props.isOpsPortal} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginBottom: theme.spacing(isMobile ? 3 : 4),
        }}
      >
        <Grid
          item
          onClick={() => {
            window.location.href = "/";
          }}
          sx={{ cursor: "pointer", height: isMobile ? 18 : 35 }}
        >
          {isMobile ? <MobileLogo /> : <OverviewLogo />}
        </Grid>
        <Grid container sx={{ width: "fit-content" }} alignItems="center">
          {!props.isOpsPortal && (
            <Grid item sx={{ marginRight: 3 }}>
              <CallGatikButton variant={isMobile ? "icon" : "small"} />
            </Grid>
          )}
          {!props.isOpsPortal && (
            <Grid
              container
              onClick={handleClick}
              sx={{ cursor: "pointer", width: "fit-content" }}
              alignItems="center"
            >
              <Avatar
                sx={{ border: `1px solid ${colors.PURPLE}` }}
                src={avatarLink || undefined}
              />
              <ExpandMoreIcon />
            </Grid>
          )}
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-elevation": {
              boxShadow: "none",
              marginTop: "3px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(logout());
              handleClose();
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </Grid>
      <Grid
        container
        flexDirection={isPortraitMobile ? "column" : "row"}
        justifyContent="flex-start"
        alignItems={"flex-end"}
        sx={{
          borderBottom: "1px solid #000000",
          paddingBottom: 1.5,
          marginBottom: isPortraitMobile ? 1 : 2,
          gap: 1.5,
        }}
      >
        {/* This dropdown is for customers, to switch between different divisions/allowed customers */}
        {!props.isOpsPortal &&
          userInfo.allowed_customers &&
          userInfo.allowed_customers.length > 1 && (
            <Grid
              item
              sx={{
                minWidth: 220,
                width: isPortraitMobile ? "100%" : undefined,
              }}
              mt={1}
            >
              <StyledTypography sx={typeset.Headline} mb={1}>
                {isPortraitMobile ? "Deployment" : "Select deployment"}
              </StyledTypography>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                wrap="nowrap"
              >
                <CustomerFilter
                  sx={{ width: isPortraitMobile ? "100%" : "220px" }}
                  hideLabel={true}
                  showAllOption={false}
                  defaultValue={userInfo.customer_name}
                  onChangeSingle={(val) => {
                    handleChangeCustomer(val.value);
                  }}
                  isDisabled={isStaff}
                />
                {isStaff && (
                  <Tooltip
                    title={
                      "Customer/deployment dropdown is disabled for staff users on /trips. To switch customers on this page, please use the Ops Portal customer view."
                    }
                  >
                    <Icon sx={{ marginBottom: "4px" }}>
                      <ToolTip />
                    </Icon>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          )}
        {/* This dropdown is for ops/admins, to switch between all different customers */}
        {props.isOpsPortal && isStaff && (
          <Grid
            item
            sx={{
              minWidth: 220,
              width: isPortraitMobile ? "100%" : undefined,
            }}
            mt={1}
          >
            <StyledTypography sx={typeset.Headline} mb={1}>
              {isPortraitMobile ? "Customer" : "Select customer"}
            </StyledTypography>
            <Grid container direction="row" justifyContent="flex-end">
              {selectedCustomerInfo && (
                <Avatar
                  sx={{ border: `1px solid ${colors.PURPLE}`, marginRight: 1 }}
                  src={selectedCustomerInfo?.avatar}
                />
              )}
              <CustomerFilter
                sx={{
                  width:
                    isPortraitMobile && selectedCustomerInfo
                      ? "calc(100% - 50px)"
                      : isPortraitMobile
                      ? "100%"
                      : selectedCustomerInfo
                      ? "170px"
                      : "220px",
                }}
                showAllOption
                hideLabel={true}
                defaultValue={customerId}
                onChangeSingle={(val) => setCustomerId(val.value)}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          sx={{
            minWidth: 220,
            width: isPortraitMobile ? "100%" : undefined,
          }}
        >
          <StyledTypography sx={{ ...typeset.Headline, marginBottom: 1 }}>
            <b>{isPortraitMobile ? "Date" : "Select date"}</b>
          </StyledTypography>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid
              item
              sx={{
                maxWidth: isPortraitMobile ? "100%" : undefined,
                width: isPortraitMobile ? "100%" : undefined,
              }}
            >
              <FormControl fullWidth>
                <Select
                  size={"small"}
                  id="select_date"
                  value={filterTime}
                  onChange={(e) => {
                    setSearch("");
                    setSearchInput("");
                    setFilterStatus("All");
                    setFilterTime(e.target.value);
                  }}
                >
                  {validDates.map((date, idx) => (
                    <MenuItem key={date.toString()} value={date.toString()}>
                      <b>
                        {dateNameMap[idx]}
                        {": "}
                        {date.format("ddd, MMMM D, YYYY")}
                      </b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isPortraitMobile && (
              <Grid item sx={{ marginLeft: 1, width: "fit-content" }}>
                <StyledTypography sx={{ color: colors.BlueGray[900] }}>
                  {timeZoneText}
                </StyledTypography>
              </Grid>
            )}

            <Grid item>
              <Tooltip
                title={
                  !isStaff
                    ? `All dates and times are displayed in ${timeZoneText}`
                    : `All dates and times are displayed in the customers' timezones. However, if you are viewing trips for "all" customers, filtering of trips by date is based on the timezone set on your profile.`
                }
              >
                <Icon>
                  <ToolTip />
                </Icon>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          sx={{
            width: isSplitView ? (isTablet ? "50%" : "60%") : "100%",
            boxSizing: "border-box",
            backgroundColor: colors.BackgroundWhite,
            padding: 1.5,
            borderRadius: "12px",
            transition: theme.transitions.create(["all", "transform"], {
              duration: theme.transitions.duration.standard,
            }),
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              marginBottom: theme.spacing(1),
            }}
          >
            <Autocomplete
              freeSolo={true}
              options={[{ value: searchInput, type: "" }].concat(searchOptions)}
              groupBy={(option) => option.type}
              getOptionLabel={(option) =>
                option instanceof Object ? option.value || "" : option
              }
              renderGroup={(params) => (
                <li key={params.key}>
                  <ListItem sx={{ pl: 1 }}>
                    <b>{params.group.toUpperCase()}</b>
                  </ListItem>
                  <div>{params.children}</div>
                </li>
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.value, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.value, matches);

                return (
                  <Box component="li" {...props}>
                    {parts.map((part, index) => (
                      <>
                        {" "}
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: part.highlight
                              ? undefined
                              : colors.Grey[400],
                            whiteSpace: "pre",
                          }}
                        >
                          {part.text}
                        </span>{" "}
                      </>
                    ))}
                  </Box>
                );
              }}
              inputValue={searchInput}
              value={searchOptions.find((option) => option.value === search)}
              onChange={(e, newValue) => {
                if (!newValue) {
                  setSearch("");
                  setSearchInput("");
                } else {
                  if (newValue instanceof Object && newValue.type !== "Trip")
                    setTab(1);
                  else setTab(0);
                  debounceSetSearch(
                    newValue instanceof Object ? newValue.value : newValue
                  );
                  setSearchInput(
                    newValue instanceof Object ? newValue.value : newValue
                  );
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.length >= 1) setSearchOpen(true);
                else setSearchOpen(false);
                debounceSetSearch(newInputValue);
                setSearchInput(newInputValue);
              }}
              renderInput={(params) => (
                <StyledSearchField
                  {...params}
                  size="small"
                  fullWidth
                  placeholder={
                    isMobile
                      ? "Trip, Stop, AV#, Lane ID"
                      : "Search by Trip, Stop, AV#, Lane ID"
                  }
                  value={search}
                />
              )}
              onKeyDown={(e) => {
                if (e.key === "Enter") setSearchOpen(false);
              }}
              onClose={() => setSearchOpen(false)}
              open={searchOpen}
              sx={{
                width: "100%",
                borderColor: isSearching ? colors.Grey[800] : undefined,
              }}
            />
          </Grid>
          {isSearching && !isFilteringStatus && (
            <Grid item>
              <StyledTypography>
                <b>{trips.length + stops.length}</b> results found
              </StyledTypography>
            </Grid>
          )}
          <Grid
            item
            sx={{
              width: "100%",
              marginBottom: theme.spacing(isMobile ? 2 : 3),
            }}
          >
            <Tabs
              value={tab}
              onChange={(_, newValue) => {
                setFilterStatus("All");
                setTab(newValue);
              }}
              TabIndicatorProps={{ style: { display: "none" } }}
              sx={{
                width: "100%",
                borderRadius: "20px",
                backgroundColor: colors.Grey[200],
                outline: `1px ${colors.Grey[500]} solid`,
                outlineOffset: "-1px",
              }}
            >
              <Tab
                label={
                  <StyledTypography
                    sx={{
                      fontSize: 20,
                      color: tab === 0 ? colors.BLACK : colors.BlueGray[500],
                    }}
                  >
                    <b>
                      Trips{" "}
                      {isSearching || (isFilteringStatus && tab === 0)
                        ? `(${trips.length})`
                        : ""}
                    </b>
                  </StyledTypography>
                }
                sx={{
                  width: "50%",
                  maxWidth: "none",
                  backgroundColor:
                    tab === 0 ? colors.BackgroundWhite : undefined,
                  border: tab === 0 ? `2px ${colors.PURPLE} solid` : "none",
                  borderRadius: "20px",
                }}
              />
              <Tab
                label={
                  <StyledTypography
                    sx={{
                      fontSize: 20,
                      color: tab === 1 ? colors.BLACK : colors.BlueGray[500],
                    }}
                  >
                    <b>
                      Stops{" "}
                      {isSearching || (isFilteringStatus && tab === 1)
                        ? `(${stops.length})`
                        : ""}
                    </b>
                  </StyledTypography>
                }
                sx={{
                  width: "50%",
                  maxWidth: "none",
                  backgroundColor:
                    tab === 1 ? colors.BackgroundWhite : undefined,
                  border: tab === 1 ? `2px ${colors.PURPLE} solid` : "none",
                  borderRadius: "20px",
                }}
              />
            </Tabs>
          </Grid>

          <Grid container>
            <Grid item sx={{ width: 200, marginBottom: isMobile ? 1 : 2 }}>
              {!isMobile && (
                <StyledTypography sx={{ ...typeset.Header3, marginBottom: 1 }}>
                  <b>Filter {tab === 0 ? "trips" : "stops"} by status</b>
                </StyledTypography>
              )}
              <FormControl fullWidth>
                <Select
                  id="status"
                  value={filterStatus}
                  onChange={(e) => {
                    setFilterStatus(e.target.value);
                  }}
                  size={"small"}
                  sx={{ width: isMobile ? 125 : undefined }}
                >
                  <MenuItem value={"All"}>
                    All {tab === 0 ? "Trips" : "Stops"}
                  </MenuItem>
                  {(tab === 0 ? TripStatuses : StopStatuses).map((status) => (
                    <MenuItem value={status.value} key={status.key}>
                      {status.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: isMobile ? 1 : 2 }} />
          <Grid item>
            {loading || searchLoading ? (
              <Grid container direction="row" justifyContent="center">
                <CircularProgress color="primary" />
              </Grid>
            ) : tab === 0 ? (
              <>
                {sortedTrips.length > 0 ? (
                  <Grid
                    container
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(320px, 1fr))",
                      gap: theme.spacing(3),
                    }}
                  >
                    {sortedTrips.map((trip, index) => (
                      <TripCard
                        trip={trip}
                        key={index}
                        search={search}
                        customer={selectedCustomerInfo}
                      />
                    ))}
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item sx={{ width: "100%" }}>
                      <StyledTypography sx={typeset.Sort} align="center">
                        No{" "}
                        {filterStatus === "All"
                          ? ""
                          : TripStatuses.find(
                              (status) => status.value === filterStatus
                            )?.key}{" "}
                        trips found {search ? `for "${search}"` : ""}
                      </StyledTypography>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {combinedStops.length > 0 ? (
                  <Grid
                    container
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(320px, 1fr))",
                      gap: theme.spacing(3),
                    }}
                  >
                    {combinedStops.map((stop, index) => (
                      <StopCard
                        stop={stop}
                        key={index}
                        search={search}
                        customer={selectedCustomerInfo}
                      />
                    ))}
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item sx={{ width: "100%" }}>
                      <StyledTypography sx={typeset.Sort} align="center">
                        No{" "}
                        {filterStatus === "All"
                          ? ""
                          : StopStatuses.find(
                              (status) => status.value === filterStatus
                            )?.key}{" "}
                        stops found {search ? `for "${search}"` : ""}
                      </StyledTypography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            width: isSplitView ? (isTablet ? "50%" : "40%") : "0%",
            boxSizing: "border-box",
            paddingLeft: 4,
            transition: theme.transitions.create(["all", "transform"], {
              duration: theme.transitions.duration.standard,
            }),
          }}
        >
          {selectedTrip && tripLayout.isOpen && (
            <TripDetailCard
              onClose={() => dispatch(closeTripDetails())}
              trip={selectedTrip}
              search={search}
            />
          )}
        </Grid>
      </Grid>
      <Grid item>
        {isMobile && y > 350 && (
          <Fab
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            color="secondary"
            sx={{ borderRadius: 4, position: "fixed", bottom: 16, right: 16 }}
          >
            <FabArrow />
          </Fab>
        )}
      </Grid>
    </Grid>
  );
}
