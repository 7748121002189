import { CircularProgress, Grid } from "@mui/material";

export enum spinnerHeight {
  fullContainer = "100%",
  fullPage = "100vh",
}

export interface LoadingSpinnerProps {
  height?: spinnerHeight | string;
}

export default function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: props.height || spinnerHeight.fullContainer,
      }}
    >
      <CircularProgress color="primary" />
    </Grid>
  );
}
