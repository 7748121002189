import { Grid, Skeleton } from "@mui/material";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import StyledDialog from "../../components/UI/StyledDialog";
import { ReactComponent as AVIcon } from "../../assets/layout_av.svg";
import { ReactComponent as BackArrow } from "../../assets/back_arrow.svg";
import { AV } from "../../types/av.type";
import StyledTypography from "../../components/UI/StyledTypography";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { useAppDispatch } from "../../redux/hooks";
import { Customer } from "../../types/customer.type";
import { useAsyncEffect } from "../../util/util";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import CustomerFilter from "../../components/UI/CustomerFilter";
import { updateAV } from "../../api/av.api";

export interface EditAVModalProps {
  AV: AV;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const EditAVModal = (props: EditAVModalProps) => {
  const { AV, onClose, onCancel, isOpen } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >();
  const [previousCustomer, setPreviousCustomer] = useState<
    Customer | undefined
  >();

  const formRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  // set the AV's previous customer on modal load
  useAsyncEffect(async () => {
    if (AV.customer_id) {
      // In case multiple customers are returned, filter the list and match the customer ID
      setPreviousCustomer(
        (
          await dispatch(fetchCustomers({ ids: [AV.customer_id] }))
        ).payload.results.filter((c: Customer) => c.id === AV.customer_id)[0]
      );
    }
  }, []);

  const submitAVChange = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const selectScreen = () => {
    return (
      <>
        <Grid
          container
          direction="column"
          sx={{
            backgroundColor: colors.Grey[100],
            color: colors.TrueGray[500],
            borderRadius: "8px",
            padding: "20px 16px",
          }}
        >
          <StyledTypography sx={typeset.Caption1}>
            <AVIcon
              style={{
                marginRight: "8px",
                marginBottom: "2px",
                verticalAlign: "middle",
              }}
            />
            AV#
          </StyledTypography>
          <StyledTypography
            sx={{ color: colors.TrueGray[700], paddingTop: "12px" }}
          >
            <b>{AV?.long_av_id.substring(0, AV?.long_av_id.indexOf("-"))}</b>
            {AV?.long_av_id.substring(
              AV?.long_av_id.indexOf("-"),
              AV?.long_av_id.length
            )}
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 5 }}>
          <StyledTypography sx={typeset.HeadlineSmall}>
            Edit customer assignment
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 0.5 }}>
          <StyledTypography sx={typeset.BodySmall}>
            This will not change existing trips scheduled with this AV
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 1, width: "212px" }}>
          {!previousCustomer ? (
            <Skeleton height={75} />
          ) : (
            <CustomerFilter
              // key={previousCustomer?.id}
              defaultValue={previousCustomer?.name}
              onChangeSingle={(val) => {
                setSelectedCustomer(val.data);
              }}
            />
          )}
        </Grid>
      </>
    );
  };
  const reviewScreen = () => {
    return (
      <>
        <Grid item sx={{ marginTop: 0 }}>
          <StyledTypography sx={typeset.body} style={{ lineHeight: 1.75 }}>
            Are you sure you want to apply these edits to AV#{" "}
            <b>{AV?.long_av_id}</b>?
          </StyledTypography>
          <StyledTypography sx={typeset.body}>
            Note that existing trips assigned to this AV will not be changed.
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 5 }}>
          <StyledTypography sx={typeset.body}>
            Customer assignment changed:
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 1 }}>
          <StyledTypography sx={typeset.body}>
            <b>
              {previousCustomer?.name}
              <BackArrow
                style={{
                  transform: "rotate(180deg)",
                  verticalAlign: "middle",
                  margin: "0 5px 3px",
                }}
              />
              {selectedCustomer?.name}
            </b>
          </StyledTypography>
        </Grid>
      </>
    );
  };
  const modalBody = () => {
    return (
      <Grid
        container
        direction="column"
        sx={{
          // padding + margin of default modal styles = 128px
          width: "calc(100vw - 128px)",
          maxWidth: "592px",
          padding: "20px",
          paddingTop: 4,
        }}
      >
        {activeStep === 0 && selectScreen()}

        {activeStep === 1 && reviewScreen()}
      </Grid>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ customer_id: previousCustomer?.id, av_id: AV?.id }}
      enableReinitialize={true}
      onSubmit={async () => {
        if (selectedCustomer) {
          await Promise.all([updateAV(AV?.id, selectedCustomer?.id)])
            .then(() => {
              dispatch(
                openSnackbar({
                  severity: "success",
                  message: `AV# ${AV?.av_id} Successfully edited.`,
                  position: { horizontal: "center", vertical: "bottom" },
                })
              );
            })
            .catch(() => {
              dispatch(
                openSnackbar({
                  severity: "error",
                  message: "Failed to update AV",
                  position: { horizontal: "center", vertical: "bottom" },
                })
              );
            });
          onClose();
        }
      }}
    >
      <StyledDialog
        onClose={onClose}
        onPrimaryClick={
          activeStep === 0 ? () => setActiveStep(1) : () => submitAVChange()
        }
        onClickClose={onClose}
        primaryButtonText={
          activeStep === 0
            ? "Save changes"
            : activeStep === 1
            ? "Confirm"
            : undefined
        }
        isPrimaryButtonDisabled={
          !selectedCustomer || selectedCustomer?.id === previousCustomer?.id
        }
        onSecondaryClick={activeStep === 0 ? onCancel : undefined}
        onTertiaryClick={activeStep === 1 ? () => setActiveStep(0) : undefined}
        headerText={activeStep === 0 ? "Edit AV" : "Review changes"}
        open={isOpen}
        maxWidth={false}
      >
        {modalBody()}
      </StyledDialog>
    </Formik>
  );
};

export default EditAVModal;
