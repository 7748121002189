import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import { ReactComponent as ActionMenu } from "../../assets/dot_menu.svg";
import * as colors from "../../util/colors";
import React, { useEffect, useState } from "react";
import { ConditionalWrapper } from "../../util/util";

export interface TripMenuOption {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  disabledHoverText?: string;
  isCopyUrl?: boolean;
}
export interface TripMenuProps {
  options: TripMenuOption[];
}

const TripMenu = (props: TripMenuProps) => {
  const { options } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [justCopiedTripUrl, setJustCopiedTripUrl] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (justCopiedTripUrl) {
      // "Trip URL copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedTripUrl(false);
      }, 1500);
    }
  }, [justCopiedTripUrl]);

  return (
    <>
      <Tooltip
        open={justCopiedTripUrl}
        sx={{ marginTop: 0.5 }}
        slotProps={{
          tooltip: {
            sx: {
              fontSize: "12px",
            },
          },
        }}
        title={`Trip URL copied to clipboard!`}
      >
        <Icon
          sx={{ width: "29px", height: "29px", cursor: "pointer" }}
          onClick={handleClick}
        >
          <ActionMenu />
        </Icon>
      </Tooltip>
      <Menu
        id="trip-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "trip-button",
        }}
      >
        {options.map((option, i) => (
          <ConditionalWrapper
            key={i}
            condition={option.disabledHoverText !== undefined}
            wrapper={(children: any) => {
              return (
                <Tooltip
                  sx={{ marginTop: 0.5 }}
                  title={option.disabledHoverText}
                >
                  <div>{children}</div>
                </Tooltip>
              );
            }}
          >
            <MenuItem
              key={`${option.label}-${i}`}
              onClick={() => {
                if (option.isCopyUrl) {
                  setJustCopiedTripUrl(true);
                }
                option.onClick();
                handleClose();
              }}
              disabled={option.disabled}
              sx={{
                cursor: option.disabled ? "not-allowed" : "pointer",
                ":hover": {
                  background: colors.Violet[100],
                },
                ":focus": {
                  background: colors.Violet[100],
                },
              }}
            >
              {option.label}
            </MenuItem>
          </ConditionalWrapper>
        ))}
      </Menu>
    </>
  );
};

export default TripMenu;
