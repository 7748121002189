import { Site } from "../types/trip.type";
import { routes } from "./routes";

export const LOGIN_HEADER_MARGIN_TOP = 6;

export const SPLASH_ROUTES = [routes.loginRoutes.verify];

export const GATIK_PHONE_NUMBER = "+18334284524";

export const POLLING_DELAY_MS = 15000;
export const OPS_POLLING_DELAY_MS = 60000;

export const HELP_TEXT = "A person from the Gatik Dispatch team can assist you";

export const DRAWER_WIDTH_OPEN = 220;
export const DRAWER_WIDTH_CLOSED = 75;

export const EXPORT_MAX_DAYS_AMOUNT = 100;

// 200 is an arbitrary number, however if we start handling more than 200 sites or AVs, we will want to implement pagination in filters.
export const FILTER_ITEMS_TO_RETURN = 200;

export const EMPTY_SITE: Site = {
  id: "",
  type: "",
  geometry: { type: "", coordinates: [0, 0] },
  properties: {
    customer_id: "",
    name: "",
    location: "",
    geofence_radius_meters: 0,
  },
};
