import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination, {
  TablePaginationProps,
} from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Leaves } from "../../types/util.type";
import { resolvePath } from "../../util/util";
import * as colors from "../../util/colors";
import { CSSProperties } from "react";
import StyledTypography from "./StyledTypography";

export interface HeadCell<T> {
  id: Leaves<T>;
  numeric?: boolean;
  label: string | React.ReactElement;
  customRender?: (x: T) => React.ReactElement;
  minWidth?: number;
  width?: number;
  sortable?: boolean;
  cellStyles?: CSSProperties;
  onClick?: (x: T) => void;
}

export interface StyledTableProps<T> {
  data: Array<T>;
  columns: Array<HeadCell<T>>;
  containerProps?: TableContainerProps;
  onSort?: (
    x: React.MouseEvent<unknown, MouseEvent>,
    y: HeadCell<T>["id"]
  ) => void;
  order?: "asc" | "desc";
  orderBy?: string;
  tablePagination?: TablePaginationProps;
  isLoading?: boolean;
}

/**Example sort function
 * 
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
 * 
 */

const StyledTable = <T extends { id: string } & Record<string, any>>(
  props: StyledTableProps<T>
) => {
  const {
    data,
    columns,
    onSort,
    orderBy,
    order,
    tablePagination,
    containerProps,
    isLoading,
  } = props;
  const createSortHandler =
    (property: HeadCell<T>["id"]) => (event: React.MouseEvent<unknown>) => {
      if (onSort) onSort(event, property);
      else console.error("No sort function to call");
    };
  return (
    <TableContainer
      {...containerProps}
      sx={{
        backgroundColor: colors.WHITE,
        borderRadius: "8px",
        border: `1px solid ${colors.TrueGray[300]}`,
        maxWidth: "100%",
        ...containerProps?.sx,
      }}
    >
      <Table>
        <TableHead sx={{ backgroundColor: colors.TrueGray[100] }}>
          <TableRow>
            {columns.map(
              ({ id, label, numeric, minWidth, sortable, width }) => (
                <TableCell
                  key={id.toString()}
                  align={numeric ? "right" : "left"}
                  sortDirection={orderBy === id ? order : false}
                  sx={{ minWidth, width, fontWeight: 700 }}
                >
                  {onSort && sortable ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : "asc"}
                      onClick={createSortHandler(id)}
                      sx={{ cursor: "pointer" }}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? new Array(tablePagination?.rowsPerPage).fill("").map((row, i) => (
                <TableRow key={i} sx={{ height: 175 }}>
                  {columns.map((column, j) => (
                    <TableCell key={j}>
                      <StyledTypography isLoading />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : data.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:hover": { backgroundColor: colors.Violet[50] } }}
                >
                  {columns.map(({ id, customRender, cellStyles, onClick }) => (
                    <TableCell
                      key={id.toString()}
                      onClick={onClick ? () => onClick(item) : undefined}
                      sx={{
                        color: colors.TrueGray[700],
                        ...cellStyles,
                      }}
                    >
                      {customRender ? (
                        customRender(item)
                      ) : (
                        <StyledTypography>
                          {resolvePath(item, id, "")}
                        </StyledTypography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {tablePagination && (
        <TablePagination component="div" {...tablePagination} />
      )}
    </TableContainer>
  );
};

export default StyledTable;
