import {
  Alert,
  Avatar,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { addMinutes, format } from "date-fns";
import { Formik, useFormikContext } from "formik";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteTrip,
  getTripById,
  updateStopStatus,
  updateTrip,
} from "../../redux/thunks/trip.thunk";
import { Customer } from "../../types/customer.type";
import {
  CreatedStop,
  SequenceStatus,
  Site,
  Status,
  Trip,
  TripStatus,
} from "../../types/trip.type";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import {
  ConditionalWrapper,
  calculateDepartureTimes,
  calculateStartTimes,
  convertTimezone,
  formatHourlyTime,
  getTimezoneText,
  handleMove,
  useAsyncEffect,
} from "../../util/util";
import AvFilter from "../UI/AvFilter";
import StyledButton from "../UI/StyledButton";
import StyledDialog from "../UI/StyledDialog";
import StyledTypography from "../UI/StyledTypography";
import AVUnassignedNotification from "../notifications/AVUnassignedNotification";
import StopCreateCard from "./StopCreateCard";
import {
  CreateTripLabelContext,
  CreateTripLabelContextDefault,
  StopFormValues,
} from "./TripCreateDialog";
import { getNotifications } from "../../redux/slices/notifications.slice";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { updateTripStatus } from "../../api/trip.api";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import StyledDatePicker from "../UI/StyledDatePicker";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import { dayjs } from "../../util/date-util";

const initialValues: StopFormValues = {
  customer_id: "",
  av_id: "",
  scheduled_start: null,
  stops: [],
  name: "",
};

export type StopCommand = {
  idx: number;
  type: "status";
  status: Status;
  id: string;
};

export interface TripEditFormikProps {
  id: string;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  defaultEditAvValue?: boolean;
  defaultEditDateValue?: boolean;
  defaultEditStopsValue?: boolean;
}

export interface TripEditDialogProps {
  id: string;
  isLoading?: boolean;
  commands: StopCommand[];
  setCommands: Dispatch<SetStateAction<StopCommand[]>>;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onChangeDeletedStops: (deletedStops: string[]) => void;
  onSetInitialTrip: (trip: Trip) => void;
  defaultEditAvValue?: boolean;
  defaultEditDateValue?: boolean;
  defaultEditStopsValue?: boolean;
  isUpdatingStopStatuses: boolean;
}

export interface DeleteStopModalInfo {
  idx: number;
  stopId?: string;
  stopName?: string;
}

const FormikWrapper = (props: TripEditFormikProps) => {
  const [commands, setCommands] = useState<StopCommand[]>([]);
  const [deletedStops, setDeletedStops] = useState<string[]>([]);
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [initialTrip, setInitialTrip] = useState<Trip | undefined>(undefined);
  const [isUpdatingStopStatuses, setIsUpdatingStopStatuses] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // reset deleted stops on modal open or close
    setDeletedStops([]);
  }, []);

  useAsyncEffect(async () => {
    if (initialTrip) {
      setCustomer(
        (await dispatch(fetchCustomers({ ids: [initialTrip.customer_id] })))
          .payload.results[0]
      );
    }
  }, [initialTrip]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (val) => {
        const hasDateFieldBeenChanged =
          initialTrip?.scheduled_start &&
          val.scheduled_start &&
          new Date(val.scheduled_start).toString() !==
            new Date(initialTrip?.scheduled_start).toString();
        const stopsArray = val.stops;
        const filteredStopsArray = stopsArray.filter((stop) =>
          deletedStops.every(
            (stopId) => stop.internal_id && !stop.internal_id.includes(stopId)
          )
        );

        if (commands.length > 0) {
          // if there are lots of status updates, add a loading modal
          if (commands.length > 3) {
            setIsUpdatingStopStatuses(true);
          }
          await Promise.all(
            commands.map((command, i) =>
              setTimeout(() => {
                dispatch(
                  updateStopStatus({ id: command.id, status: command.status })
                );
                if (i + 1 === commands.length) {
                  setTimeout(() => {
                    setIsUpdatingStopStatuses(false);
                    dispatch(
                      openSnackbar({
                        severity: "success",
                        message: (
                          <>
                            Trip{" "}
                            <Link href={`?view_trip=${initialTrip?.id}`}>
                              {initialTrip?.itinerary?.name}
                            </Link>{" "}
                            successfully updated
                          </>
                        ),
                        position: { horizontal: "center", vertical: "bottom" },
                      })
                    );
                    props.onClose();
                  }, 300);
                }
              }, i * 700)
            )
          );
        } else {
          await Promise.all([
            dispatch(
              updateTrip({
                id: props.id,
                payload: {
                  ...val,
                  // Check that date has been changed and that the trip name follows our standard date appended format.
                  // This regex checks if the trip name ends with a hyphen (-) followed by exactly 6 numbers.
                  name:
                    hasDateFieldBeenChanged && /-\d{6}$/.test(val.name)
                      ? `${val.name.substr(0, val.name.lastIndexOf("-") + 1)}${
                          val.scheduled_start &&
                          format(
                            convertTimezone(
                              val.scheduled_start,
                              customer?.timezone ||
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                            ),
                            "MMddyy"
                          )
                        }`
                      : val.name,
                  // check if the date value has been updated. If not, don't send the value to the patch request.
                  // TODO: Update api call to not send any values that haven't been updated.
                  scheduled_start: hasDateFieldBeenChanged
                    ? val.scheduled_start
                    : undefined,

                  stops: filteredStopsArray.map((stop) => ({
                    travel_time: stop.travel_time,
                    service_time: stop.service_time,
                    site_id: stop.site_id,
                  })),
                },
              })
            ),
          ])
            .then((onrejected) => {
              if (onrejected[0].type === updateTripStatus.Fulfilled) {
                dispatch(
                  openSnackbar({
                    severity: "success",
                    message: (
                      <>
                        Trip{" "}
                        <Link href={`?view_trip=${initialTrip?.id}`}>
                          {initialTrip?.itinerary?.name}
                        </Link>{" "}
                        successfully updated
                      </>
                    ),
                    position: { horizontal: "center", vertical: "bottom" },
                  })
                );
                props.onClose();
              } else {
                dispatch(
                  openSnackbar({
                    severity: "error",
                    message:
                      onrejected[0].type === updateTripStatus.Rejected
                        ? `Failed to edit trip.`
                        : `Something went wrong.`,
                    position: { horizontal: "center", vertical: "bottom" },
                  })
                );
              }
              // setLoading(false);
              // resetForm();
              // onClose();
            })
            // TODO: Fix errors returning 400 but not catching. I think we'll need to rework our APIs to use axios interceptors: https://stackoverflow.com/questions/47679543/axios-400-error-request-call-then-instead-of-catch
            .catch(() => {
              // onClose();
            });
        }
      }}
    >
      {({ resetForm }) => (
        <TripEditDialog
          {...props}
          commands={commands}
          setCommands={setCommands}
          isOpen={props.isOpen}
          onClose={props.onClose}
          onChangeDeletedStops={(deletedStops) => setDeletedStops(deletedStops)}
          onSetInitialTrip={(trip) => setInitialTrip(trip)}
          isUpdatingStopStatuses={isUpdatingStopStatuses}
        />
      )}
    </Formik>
  );
};

const TripEditDialog = (props: TripEditDialogProps) => {
  const {
    id,
    commands,
    setCommands,
    isOpen,
    onClose,
    onChangeDeletedStops,
    onSetInitialTrip,
    defaultEditAvValue,
    defaultEditDateValue,
    defaultEditStopsValue,
    isUpdatingStopStatuses,
  } = props;
  const formik = useFormikContext<StopFormValues>();
  const dispatch = useAppDispatch();
  const { stops, customer_id, scheduled_start } = formik.values;
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [sites, setSites] = useState<Site[]>([]);
  const [avDisabled, setAvDisabled] = useState<boolean>(
    typeof defaultEditAvValue === "boolean" ? defaultEditAvValue : true
  );
  const [dateTimeDisabled, setDateTimeDisabled] = useState<boolean>(
    typeof defaultEditDateValue === "boolean" ? defaultEditDateValue : true
  );
  const [editMode, setEditMode] = useState<boolean>(
    typeof defaultEditStopsValue === "boolean" ? defaultEditStopsValue : false
  );
  const [trip, setTrip] = useState<Trip | null>(null);
  const [newStopIdx, setNewStopIdx] = useState(-1);
  const [hasNotification, setHasNotification] = useState(false);
  const [currentStopsBeingEdited, setCurrentStopsBeingEdited] = useState<
    string[]
  >([]);
  const [currentStopBeingDragHovered, setCurrentStopBeingDragHovered] =
    useState<number | undefined>();
  const [canSave, setCanSave] = useState<boolean>(true);
  const [cancelTripModalId, setCancelTripModalId] = useState<
    string | undefined
  >();
  const [deleteStopModalInfo, setDeleteStopModalInfo] = useState<
    DeleteStopModalInfo | undefined
  >();
  const [deletedStops, setDeletedStops] = useState<string[]>([]);
  const { notifications } = useAppSelector(getNotifications);

  const customerTimezone = customer?.timezone;

  const { loading: tripLoading } = useAsyncEffect(async () => {
    if (!id) {
      return;
    }
    const trip = (await dispatch(getTripById(id))).payload as Trip;
    const filteredNotification = notifications.filter(
      (n) => n.extra.trip_id === trip.id
    )[0];
    if (filteredNotification) {
      setHasNotification(true);
    }
    setTrip(trip);
    formik.setValues({
      customer_id: trip.customer_id,
      av_id: trip.av.id,
      scheduled_start: new Date(Date.parse(trip.scheduled_start)),
      stops: trip.itinerary.stops.map((stop) => ({
        internal_id: stop.id,
        service_time: stop.service_time,
        travel_time: stop.travel_time,
        site_id: stop.site.id,
      })),
      name: trip.itinerary.name,
    });
    onSetInitialTrip(trip);
  }, [id, isOpen]);

  const { loading: loadingSitesAndCustomers } = useAsyncEffect(async () => {
    if (customer_id) {
      setCustomer(
        (await dispatch(fetchCustomers({ ids: [customer_id] }))).payload
          .results[0]
      );
    }
    if (!customer_id) {
      setSites([]);
      return;
    }
    const newSites: Site[] =
      trip?.itinerary.stops.map((stop) => stop.site) || [];
    setSites(newSites);
  }, [customer_id, isOpen, trip]);

  const { endTime, hours, minutes } = useMemo(() => {
    const totalMinutes = stops.reduce(
      (acc, curr) => (curr.service_time || 0) + (curr.travel_time || 0) + acc,
      0
    );
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor((totalMinutes / 60 - hours) * 60);
    return {
      endTime: scheduled_start
        ? addMinutes(scheduled_start, totalMinutes)
        : new Date(),
      hours,
      minutes,
    };
  }, [stops, scheduled_start]);

  const stopStartTimes = useMemo(
    () => calculateStartTimes(formik.values),
    [formik.values]
  );

  const stopDepartureTimes = useMemo(
    () => calculateDepartureTimes(formik.values),
    [formik.values]
  );

  const hasMoved = useMemo(() => {
    const tripStopIds = trip?.itinerary.stops.map((stop) => stop.id);
    const stopIds = stops.map((stop) => stop.internal_id);

    return stopIds.reduce((acc, curr, idx) => {
      if (acc) {
        return true;
      } else if (!tripStopIds) {
        return false;
      } else return curr !== tripStopIds[idx];
    }, false);
  }, [stops, trip]);

  const CancelTripModal = () => {
    return (
      <StyledDialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px", // Set your width here
            },
          },
        }}
        open={cancelTripModalId !== undefined}
        primaryButtonText="Cancel trip"
        primaryButtonProps={{
          color: "errorAction",
          variant: "contained",
        }}
        isPrimaryButtonDisabled={!formik.isValid || !canSave}
        tertiaryButtonText="Go back"
        // tertiaryButtonProps={{
        //   color: "errorAction",
        //   variant: "outlined",
        //   disabled: trip?.status === TripStatus.COMPLETED,
        // }}
        onPrimaryClick={async () => {
          if (cancelTripModalId) {
            await Promise.all([dispatch(deleteTrip(cancelTripModalId))])
              .then(() => {
                dispatch(
                  openSnackbar({
                    severity: "success",
                    message: (
                      <>
                        Trip{" "}
                        <Link href={`?view_trip=${trip?.id}`}>
                          {trip?.itinerary?.name}
                        </Link>{" "}
                        successfully cancelled
                      </>
                    ),
                    position: { horizontal: "center", vertical: "bottom" },
                  })
                );

                setCancelTripModalId(undefined);
                onClose();
              })
              .catch(() => {
                setCancelTripModalId(undefined);
              });
          }
        }}
        onTertiaryClick={() => setCancelTripModalId(undefined)}
        headerText={"Cancel trip?"}
      >
        <StyledTypography sx={{ ...typeset.body, padding: "30px 0" }}>
          Are you sure you want to cancel trip <b>{trip?.itinerary.name}</b>?
        </StyledTypography>
      </StyledDialog>
    );
  };

  const UpdateStopStatusesModal = () => {
    return (
      <Dialog
        PaperProps={{
          // overflow visible is so that the autocomplete dropdown is not cut off
          style: { borderRadius: 20, overflow: "visible" },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px", // Set your width here
            },
          },
        }}
        open={isUpdatingStopStatuses}
      >
        <DialogTitle
          sx={{
            ...typeset.Header2,
            fontWeight: 700,
            backgroundColor: colors.BackgroundWhite,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          Updating stop statuses
        </DialogTitle>
        <div style={{ paddingTop: "32px", paddingBottom: "32px" }}>
          <LoadingSpinner />
        </div>
      </Dialog>
    );
  };
  const DeleteStopModal = () => {
    return (
      <StyledDialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px", // Set your width here
            },
          },
        }}
        open={deleteStopModalInfo?.stopId !== undefined}
        primaryButtonText="Yes, delete stop"
        tertiaryButtonText="Cancel"
        onPrimaryClick={async () => {
          // TODO: Mark the stop as cancelled
          if (deleteStopModalInfo && deleteStopModalInfo.stopId) {
            let deletedStopsArray = deletedStops;
            deletedStopsArray.push(deleteStopModalInfo?.stopId);
            setDeletedStops(deletedStopsArray);
            onChangeDeletedStops(deletedStops);
            setDeleteStopModalInfo(undefined);
          } else {
            console.error("No deleteStopModalInfo");
          }
        }}
        onTertiaryClick={() => setDeleteStopModalInfo(undefined)}
        headerText={"Deleting stop"}
      >
        <StyledTypography sx={{ ...typeset.body, paddingTop: "30px" }}>
          Are you sure you want to delete stop {deleteStopModalInfo?.idx}
          <b>
            {deleteStopModalInfo?.stopName &&
              ` ${deleteStopModalInfo?.stopName}`}
          </b>
          ?
        </StyledTypography>
        <StyledTypography sx={{ ...typeset.body, padding: "30px 0 10px" }}>
          Deleting a stop will change scheduled times of stops after it.
        </StyledTypography>
      </StyledDialog>
    );
  };

  const onCancelTripClick = (tripId: string) => {
    setCancelTripModalId(tripId);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveCard = useCallback(
    handleMove((x) => formik.setFieldValue("stops", x), stops),
    [stops]
  );

  const handleChangeStopStatus = (
    stop: CreatedStop,
    status: Status,
    idx: number
  ) => {
    if (!stop.internal_id) {
      return;
    }

    const existingCommands = commands.filter(
      (command) => command.id === stop.internal_id
    );

    // when changing a stop, undo status updates to all future stops
    let newCommands = [...commands];
    newCommands = commands.filter((command) => command.idx <= idx);

    // if there is already a command set for this stop
    if (existingCommands.length > 0) {
      // and the stop is already set to both arrive and depart, undo both.
      if (existingCommands.length > 1) {
        setCommands(
          newCommands.filter((command) => command.id !== stop.internal_id)
        );
      }
      // and the stop is set to to arrive, allow adding depart like normal.
      else if (
        existingCommands[0].status !== status &&
        status === Status.AV_DEPARTED
      ) {
        setCommands([
          ...newCommands,
          {
            idx: idx,
            type: "status",
            id: stop.internal_id,
            status,
          },
        ]);
        // and there's only one status change, undo it
      } else {
        setCommands(
          newCommands.filter((command) => command.id !== stop.internal_id)
        );
      }
      // if the stop has not been set to arrive or depart, and the user sets it to depart, add both statuses
    } else if (status === Status.AV_DEPARTED) {
      setCommands([
        ...newCommands,
        {
          idx: idx,
          type: "status",
          id: stop.internal_id,
          status: Status.AV_ARRIVED,
        },
        {
          idx: idx,
          type: "status",
          id: stop.internal_id,
          status: Status.AV_DEPARTED,
        },
      ]);
    } else {
      setCommands([
        ...newCommands,
        {
          idx: idx,
          type: "status",
          id: stop.internal_id,
          status,
        },
      ]);
    }
  };

  useEffect(() => {
    if (currentStopsBeingEdited.length === 0) {
      setCanSave(true);
    }
  }, [currentStopsBeingEdited]);

  useEffect(() => {
    setEditMode(false);
    setAvDisabled(
      typeof defaultEditAvValue === "boolean" ? defaultEditAvValue : true
    );
    setEditMode(false);
    setDateTimeDisabled(true);
    setNewStopIdx(-1);
    setCustomer(undefined);
    setSites([]);
    setCanSave(true);
    setDeletedStops([]);
    setCommands([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <CreateTripLabelContext.Provider
      value={{
        ...CreateTripLabelContextDefault,
        customer,
        sites,
        setSites,
        setCustomer,
      }}
    >
      <StyledDialog
        {...props}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
        open={isOpen}
        primaryButtonText="Save changes"
        isPrimaryButtonDisabled={!formik.isValid || !canSave}
        secondaryButtonText="Go back"
        onPrimaryClick={formik.submitForm}
        onSecondaryClick={onClose}
        headerText={"Edit trip"}
      >
        <Grid container direction="column">
          {trip && hasNotification ? (
            <AVUnassignedNotification
              index={1}
              scheduledStart={trip.scheduled_start}
              itineraryName={trip.itinerary?.name}
              tripId={trip.id}
              hideEditTripButton={true}
            />
          ) : null}
          <Grid
            container
            sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
            mb={2}
            mt={2}
            p={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              // full width minus space for "cancel trip" button
              sx={{ width: "calc(100% - 150px)" }}
            >
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={4}
              >
                <Grid item mb={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    CUSTOMER
                  </StyledTypography>
                </Grid>
                <Grid item>
                  {customer ? <Avatar src={customer?.avatar} /> : <></>}
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={4}
              >
                <Grid item mb={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    TRIP NAME
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography>{trip?.itinerary?.name}</StyledTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {!(
                trip?.status === TripStatus.CANCELLED ||
                trip?.status === TripStatus.COMPLETED
              ) && (
                <ConditionalWrapper
                  condition={trip?.status === TripStatus.IN_PROGRESS}
                  wrapper={(children: any) => {
                    return (
                      <Tooltip
                        sx={{ marginTop: 0.5 }}
                        title={"You cannot cancel a trip that is in progress."}
                      >
                        <div>{children}</div>
                      </Tooltip>
                    );
                  }}
                >
                  <StyledButton
                    variant="outlined"
                    size="small"
                    color="errorAction"
                    buttonStyle="rectangle"
                    disabled={trip?.status === TripStatus.IN_PROGRESS}
                    onClick={() => onCancelTripClick(id)}
                  >
                    Cancel trip
                  </StyledButton>
                </ConditionalWrapper>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
            mb={2}
            p={2}
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              sx={{ width: "fit-content" }}
              mr={4}
            >
              <Grid item mb={1} mr={1}>
                <StyledTypography
                  sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                >
                  ASSIGNED AV
                </StyledTypography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {!tripLoading && (
                  <>
                    <AvFilter
                      hideLabel
                      id="av_id"
                      isLoading={tripLoading}
                      customerId={customer_id}
                      isDisabled={avDisabled}
                      defaultValue={trip?.av.long_av_id}
                      onChangeSingle={(val) => {
                        formik.setFieldValue("av_id", val.data?.id);
                      }}
                      // variant="select"
                      // selectProps={{ disabled: avDisabled, size: "small" }}
                    />
                    <Tooltip
                      placement={"right"}
                      title={`You can only select AVs that are assigned to the customer for this trip. If you need to use AVs that are assigned to a different customer, even temporarily, update customer assignment on the AVs page and then retry.`}
                    >
                      <InfoIcon style={{ marginLeft: "8px" }} />
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <StyledButton
                size="small"
                variant="contained"
                color="secondaryAction"
                disabled={!avDisabled}
                onClick={() => setAvDisabled(false)}
              >
                Edit
              </StyledButton>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
            mb={2}
          >
            <Grid
              container
              justifyContent="space-between"
              flexWrap="nowrap"
              p={2}
            >
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={4}
              >
                <Grid item mb={1} mr={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    TRIP START DATE
                  </StyledTypography>
                </Grid>
                <Grid
                  item
                  sx={{ width: 400, display: "flex", flexDirection: "row" }}
                >
                  <ConditionalWrapper
                    condition={trip?.status === TripStatus.IN_PROGRESS}
                    wrapper={(children: any) => {
                      return (
                        <Tooltip
                          title={
                            "You cannot edit a trip's start date or time that is in progress."
                          }
                        >
                          <div>{children}</div>
                        </Tooltip>
                      );
                    }}
                  >
                    <StyledDatePicker
                      returnVanillaDate
                      timezone={customerTimezone}
                      variant="datetime"
                      name="scheduled_start"
                      slotProps={{ textField: { placeholder: "" } }}
                      sx={{ minWidth: "250px" }}
                      disabled={dateTimeDisabled}
                    />
                  </ConditionalWrapper>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    {getTimezoneText(customerTimezone)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="center"
              >
                <ConditionalWrapper
                  condition={trip?.status === TripStatus.IN_PROGRESS}
                  wrapper={(children: any) => {
                    return (
                      <Tooltip
                        sx={{ marginTop: 0.5 }}
                        title={
                          "Cannot edit a trip's start date or time once it is marked as in progress."
                        }
                      >
                        <div>{children}</div>
                      </Tooltip>
                    );
                  }}
                >
                  <StyledButton
                    size="small"
                    variant="contained"
                    color="secondaryAction"
                    disabled={
                      !dateTimeDisabled ||
                      trip?.status === TripStatus.IN_PROGRESS
                    }
                    onClick={() => setDateTimeDisabled(false)}
                  >
                    Edit
                  </StyledButton>
                </ConditionalWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            mt={2}
            sx={{ backgroundColor: colors.TrueGray[50], borderRadius: "8px" }}
            p={2}
          >
            <Grid
              container
              justifyContent="space-between"
              flexWrap="nowrap"
              mb={1}
            >
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={4}
              >
                <Grid item mb={1} mr={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    TRIP START TIME
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography isLoading={tripLoading}>
                    {scheduled_start
                      ? `${formatHourlyTime(
                          scheduled_start,
                          customerTimezone
                        )} ${getTimezoneText(customerTimezone)}`
                      : "No start time selected"}
                  </StyledTypography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={1}
              >
                <Grid item mb={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    TRIP END TIME
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography isLoading={tripLoading}>
                    {formatHourlyTime(endTime, customerTimezone)}{" "}
                    {getTimezoneText(customerTimezone)}
                  </StyledTypography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                sx={{ width: "fit-content" }}
                mr={1}
              >
                <Grid item mb={1}>
                  <StyledTypography
                    sx={{ ...typeset.Caption2, color: colors.TrueGray[500] }}
                  >
                    TRIP DURATION
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography isLoading={tripLoading}>{`${hours} ${
                    hours > 1 ? "hrs" : "hr"
                  } ${minutes} min`}</StyledTypography>
                </Grid>
              </Grid>
              <Grid item>
                <StyledButton
                  size="small"
                  variant={editMode ? "outlined" : "contained"}
                  color="secondaryAction"
                  onClick={() => setEditMode(!editMode)}
                  disabled={editMode}
                >
                  Edit
                </StyledButton>
              </Grid>
            </Grid>
            <Divider />
            {loadingSitesAndCustomers || tripLoading || sites.length === 0
              ? new Array(5)
                  .fill("")
                  .map((value, k) => <Skeleton key={k} height={75} />)
              : stops.map((stop, idx) => {
                  const isDestination = stops.length === idx + 1;
                  const arrivedAt = trip?.itinerary?.stops[idx]?.arrived_at;
                  const departedAt = trip?.itinerary?.stops[idx]?.departed_at;
                  const isBeingDeleted = stop.internal_id
                    ? deletedStops.includes(stop.internal_id)
                    : false;
                  let stopsBeingDeleted = deletedStops;
                  stopsBeingDeleted = stopsBeingDeleted.filter(
                    (s) => s !== stop.internal_id
                  );
                  const stopStatus = trip?.itinerary?.stops[idx]?.status;
                  const sequenceStatus =
                    trip?.itinerary?.stops[idx]?.sequence_status;

                  const isPreviousStopDeparted =
                    trip?.itinerary?.stops[idx - 1]?.status ===
                    Status.AV_DEPARTED;
                  const isPreviousStopSkipped =
                    trip?.itinerary?.stops[idx - 1]?.sequence_status ===
                    SequenceStatus.SKIPPED;
                  const isPreviousStopBeingChangedToDeparted = commands.filter(
                    (command) =>
                      command.idx === idx - 1 &&
                      command.status === Status.AV_DEPARTED
                  )[0];

                  const isEligibleForSettingArrived =
                    stopStatus === Status.AWAITING_AV &&
                    sequenceStatus !== SequenceStatus.SKIPPED &&
                    (isPreviousStopDeparted ||
                      isPreviousStopBeingChangedToDeparted);
                  const isEligibleForSettingDeparted =
                    !isDestination &&
                    (stopStatus === Status.AV_ARRIVED ||
                      stopStatus === Status.AWAITING_AV) &&
                    (isPreviousStopDeparted ||
                      isPreviousStopSkipped ||
                      isPreviousStopBeingChangedToDeparted);

                  const isStopSetToArrive =
                    commands.filter(
                      (command) =>
                        command.id === stop.internal_id &&
                        command.status === Status.AV_ARRIVED
                    ).length > 0;
                  const isStopSetToDepart =
                    commands.filter(
                      (command) =>
                        command.id === stop.internal_id &&
                        command.status === Status.AV_DEPARTED
                    ).length > 0;

                  // logic to allow ops to set origin as departed
                  const dayjsStartTime = dayjs(trip?.scheduled_start);
                  const dayjsEndTime = dayjs(trip?.scheduled_end);
                  const dayjsCurrentTime = dayjs(new Date());
                  // .diff gets a positive or negative number of minutes from the current time
                  // Math.abs gets the absolute value, so that minutes are always positive.
                  const diffMinutesBetweenStartTimeAndCurrent = Math.abs(
                    dayjsCurrentTime.diff(dayjsStartTime, "minutes")
                  );
                  const diffMinutesBetweenEndTimeAndCurrent = Math.abs(
                    dayjsCurrentTime.diff(dayjsEndTime, "minutes")
                  );

                  // if scheduled start is +/- 6 hours from current time, allow user to change the trip status.
                  const allowChangeOriginStopStatus =
                    idx === 0 &&
                    !trip?.av.av_id.toLowerCase().includes("unassigned") &&
                    (diffMinutesBetweenStartTimeAndCurrent < 360 ||
                      diffMinutesBetweenEndTimeAndCurrent < 360);

                  return (
                    <div key={idx}>
                      <Grid
                        item
                        m={1}
                        sx={!editMode ? {} : { backgroundColor: colors.WHITE }}
                      >
                        <StopCreateCard
                          stop={stop}
                          id={stop.internal_id}
                          idx={idx}
                          previousTime={stopStartTimes[idx]}
                          readOnly={!editMode}
                          defaultOpen={false}
                          disableMove={commands.length > 0}
                          moveCard={moveCard}
                          isBeingDeleted={isBeingDeleted}
                          isBeingMarkedArrived={isStopSetToArrive}
                          isBeingMarkedDeparted={isStopSetToDepart}
                          selectedCustomer={customer}
                          arrivedAt={
                            arrivedAt ? new Date(arrivedAt) : undefined
                          }
                          departedAt={
                            departedAt ? new Date(departedAt) : undefined
                          }
                          stopStatus={stopStatus}
                          sequenceStatus={sequenceStatus}
                          onChangeEditing={(isEditing) => {
                            if (isEditing) {
                              let stopsBeingEdited = currentStopsBeingEdited;
                              if (stop.internal_id) {
                                stopsBeingEdited.push(stop.internal_id);
                                setCurrentStopsBeingEdited(stopsBeingEdited);
                                setCanSave(false);
                              }
                            } else {
                              let stopsBeingEdited = currentStopsBeingEdited;
                              stopsBeingEdited = stopsBeingEdited.filter(
                                (s) => s !== stop.internal_id
                              );
                              setCurrentStopsBeingEdited(stopsBeingEdited);
                            }
                          }}
                          // this is updating a stop, not adding a new one
                          onSave={(stop) => {
                            const newStops = [...stops];
                            newStops.splice(idx, 1, stop);
                            formik.setFieldValue("stops", newStops);
                            setNewStopIdx(-1);
                          }}
                          menuItems={
                            editMode
                              ? ([
                                  !isDestination
                                    ? {
                                        label: "Insert stop after",
                                        onClick: () => setNewStopIdx(idx),
                                      }
                                    : null,
                                  !isBeingDeleted &&
                                  stopStatus === Status.AWAITING_AV &&
                                  idx !== 0
                                    ? {
                                        label: "Delete stop",
                                        onClick: () =>
                                          setDeleteStopModalInfo({
                                            idx: idx,
                                            stopName: "",
                                            stopId: stop.internal_id,
                                          }),
                                      }
                                    : null,
                                  isBeingDeleted
                                    ? {
                                        label: "Undo deletion",
                                        onClick: () => {
                                          setDeletedStops(stopsBeingDeleted);
                                          onChangeDeletedStops(deletedStops);
                                        },
                                      }
                                    : null,
                                  !isBeingDeleted &&
                                  !hasMoved &&
                                  trip?.status === TripStatus.IN_PROGRESS &&
                                  isEligibleForSettingArrived
                                    ? {
                                        label: `${
                                          isStopSetToArrive && isStopSetToDepart
                                            ? "Undo mark AV arrived/departed"
                                            : isStopSetToArrive
                                            ? "Undo mark AV arrived"
                                            : "Mark AV arrived"
                                        }`,
                                        onClick: () => {
                                          handleChangeStopStatus(
                                            stop,
                                            Status.AV_ARRIVED,
                                            idx
                                          );
                                        },
                                      }
                                    : null,
                                  (!isBeingDeleted &&
                                    !hasMoved &&
                                    trip?.status === TripStatus.IN_PROGRESS &&
                                    isEligibleForSettingDeparted &&
                                    (!isStopSetToDepart ||
                                      stopStatus === Status.AV_ARRIVED)) ||
                                  allowChangeOriginStopStatus
                                    ? {
                                        label: isStopSetToDepart
                                          ? "Undo mark AV departed"
                                          : "Mark AV departed",
                                        onClick: () => {
                                          handleChangeStopStatus(
                                            stop,
                                            Status.AV_DEPARTED,
                                            idx
                                          );
                                        },
                                      }
                                    : null,
                                ].filter((x) => x !== null) as {
                                  label: string;
                                  onClick: () => void;
                                }[])
                              : undefined
                          }
                          onDragCard={(cardIndex) => {
                            // This logic checks if we should allow the user to drag the stop to another stop. This logic checks the stop being hovered, not the stop being dragged.
                            const isHoveredCardOrigin = cardIndex === 0;
                            const isHoveredCardFinalDestination =
                              cardIndex ===
                              (trip?.itinerary.stops.length || 0) - 1;
                            const isHoveredCardArrived =
                              cardIndex &&
                              trip?.itinerary.stops[cardIndex].status ===
                                Status.AV_ARRIVED;
                            const isHoveredCardDeparted =
                              cardIndex &&
                              trip?.itinerary.stops[cardIndex].status ===
                                Status.AV_DEPARTED;

                            // Finally, we have the 4 restrictions to changing a stop.
                            const isStopEditable =
                              !isHoveredCardOrigin &&
                              !isHoveredCardFinalDestination &&
                              !isHoveredCardArrived &&
                              !isHoveredCardDeparted;
                            // If the stop being hovered meets the criteria, allow the user to drop the card.
                            if (isStopEditable) {
                              setCurrentStopBeingDragHovered(cardIndex);
                            }
                          }}
                          isDragHovered={currentStopBeingDragHovered === idx}
                        />
                      </Grid>
                      {/** Inserting new stop */}
                      {newStopIdx !== -1 && idx === newStopIdx && (
                        <Grid item m={1} sx={{ backgroundColor: colors.WHITE }}>
                          <StopCreateCard
                            previousTime={stopDepartureTimes[idx]}
                            onSave={(stop) => {
                              const newStops = [...stops];
                              newStops.splice(idx + 1, 0, stop);
                              formik.setFieldValue("stops", newStops);
                              setNewStopIdx(-1);
                            }}
                            onCancel={() => setNewStopIdx(-1)}
                            selectedCustomer={customer}
                            isNewInsertedStop
                          />
                        </Grid>
                      )}
                    </div>
                  );
                })}
          </Grid>
          {(commands.length > 0 || hasMoved) && (
            <Grid item mt={2} mx={1}>
              <Alert severity="info">
                {commands.length > 0
                  ? "You have marked a stop as completed or arrived and therefore must save before re-ordering stops"
                  : "You have re-ordered the stops and therefore must save before marking any as complete or arrived"}
              </Alert>
            </Grid>
          )}
        </Grid>
        {CancelTripModal()}
        {DeleteStopModal()}
        {UpdateStopStatusesModal()}
      </StyledDialog>
    </CreateTripLabelContext.Provider>
  );
};

export default FormikWrapper;
