import {
  SequenceStatus,
  Status,
  StopStatus,
  TripStatus,
} from "../../types/trip.type";
import StyledChip, { IStyledChipProps } from "../UI/StyledChip";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import { useTheme } from "@mui/material";
import { formatHourlyTime } from "../../util/util";

function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours} ${hours > 1 ? "hr" : "hr"} ${minutes}`;
}

export interface IStopChipProps {
  status: StopStatus | TripStatus | Status;
  sequenceStatus?: SequenceStatus;
  showBackground?: boolean;
  time?: string;
  fullWidth?: boolean;
  customerTimeZone?: string;
}

export default function StopChip(props: IStopChipProps) {
  const theme = useTheme();
  const {
    status,
    sequenceStatus,
    time,
    showBackground = false,
    fullWidth,
    customerTimeZone,
  } = props;
  const minutes = parseInt(time || "null");
  let chipProps: IStyledChipProps = {
    sx: {
      height: theme.spacing(3),
      "& .MuiChip-label": typeset.Caption2,
      width: fullWidth ? "100%" : undefined,
    },
  };
  switch (status) {
    case StopStatus.ON_TIME:
      chipProps = {
        ...chipProps,
        label: "On time",
        sx: showBackground
          ? { ...chipProps.sx, backgroundColor: colors.Emerald[200] }
          : {
              ...chipProps.sx,
              color: colors.Emerald[600],
              backgroundColor: "rgba(0,0,0,0.0)",
            },
      };
      break;
    case StopStatus.DELAYED:
      chipProps = {
        ...chipProps,
        label: "Late",
        sx: showBackground
          ? {
              ...chipProps.sx,
              backgroundColor: colors.Orange[500],
              color: "white",
            }
          : {
              ...chipProps.sx,
              color: colors.Orange[500],
              backgroundColor: "rgba(0,0,0,0.0)",
            },
      };
      break;
    case StopStatus.EARLY:
      chipProps = {
        ...chipProps,
        label: "Early",
        sx: showBackground
          ? {
              ...chipProps.sx,
              backgroundColor: colors.Emerald[600],
              color: "white",
            }
          : {
              ...chipProps.sx,
              color: colors.Emerald[600],
              backgroundColor: "rgba(0,0,0,0.0)",
            },
      };
      break;
    case TripStatus.CANCELLED:
      chipProps = {
        ...chipProps,
        label: "Cancelled",
        sx: showBackground
          ? {
              ...chipProps.sx,
              backgroundColor: colors.Rose[500],
              color: "white",
            }
          : {
              ...chipProps.sx,
              color: colors.Rose[500],
              backgroundColor: "rgba(0,0,0,0.0)",
            },
      };
      break;
    case TripStatus.TRACKING_TIMEOUT:
      chipProps = {
        ...chipProps,
        label: (
          <>
            Tracking Timeout
            {sequenceStatus === SequenceStatus.SKIPPED && (
              <span> (Skipped)</span>
            )}
            {sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE && (
              <span> (Out-of-sequence)</span>
            )}
          </>
        ),
        sx: {
          ...chipProps.sx,
        },
      };
      break;
    case TripStatus.SCHEDULED:
      chipProps = {
        ...chipProps,
        sx: { ...chipProps.sx, backgroundColor: colors.BlueGray[200] },
        label: "At origin",
      };
      break;
    case TripStatus.INTERRUPTED:
      chipProps = {
        ...chipProps,
        label: "Interrupted",
        sx: {
          ...chipProps.sx,
          width: "100%",
          backgroundColor: colors.Rose[500],
          color: "white",
        },
      };
      break;
    case TripStatus.COMPLETED:
      chipProps = {
        ...chipProps,
        label: time ? (
          <>
            Completed at{" "}
            <b>
              {formatHourlyTime(new Date(Date.parse(time)), customerTimeZone)}
            </b>
            {sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE && (
              <span> (Out-of-sequence)</span>
            )}
          </>
        ) : (
          <>
            Completed
            {sequenceStatus === SequenceStatus.SKIPPED && (
              <span> (Skipped)</span>
            )}
            {sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE && (
              <span> (Out-of-sequence)</span>
            )}
          </>
        ),
        sx: {
          ...chipProps.sx,
          width: "100%",
          backgroundColor: colors.Grey[700],
          color: "white",
        },
      };
      break;
    case TripStatus.IN_PROGRESS:
      chipProps = {
        ...chipProps,
        sx: { ...chipProps.sx, backgroundColor: colors.Violet[200] },
        label: (
          <>
            Run in progress
            {sequenceStatus === SequenceStatus.SKIPPED && (
              <span> (Likely skipped)</span>
            )}
          </>
        ),
      };
      break;
    case Status.AWAITING_AV:
      chipProps = {
        ...chipProps,
        sx: { ...chipProps.sx, backgroundColor: colors.Violet[300] },
        label:
          time !== "0" ? (
            <>
              Arriving in{" "}
              <b>{minutes < 60 ? minutes : toHoursAndMinutes(minutes)} min</b>
            </>
          ) : (
            <>Arriving now</>
          ),
      };
      break;
    case Status.AV_ARRIVED:
      chipProps = {
        ...chipProps,
        sx: {
          ...chipProps.sx,
          width: "100%",
          backgroundColor: colors.PURPLE,
          color: "white",
        },
        label: time ? (
          <>
            Arrived at{" "}
            <b>
              {formatHourlyTime(new Date(Date.parse(time)), customerTimeZone)}
            </b>
            {sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE && (
              <span> (Out-of-sequence)</span>
            )}
          </>
        ) : (
          <>
            Arrived
            {sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE && (
              <span> (Out-of-sequence)</span>
            )}
          </>
        ),
      };
      break;
    default:
      break;
  }
  return <StyledChip {...chipProps} />;
}
