import { Button, Grid, Icon, Paper, Tooltip, useTheme } from "@mui/material";
import { SequenceStatus, Status, Stop, Trip } from "../../types/trip.type";
import * as colors from "../../util/colors";
import StyledTypography from "../UI/StyledTypography";
import TripDetailItem from "./TripDetailItem";
import * as typeset from "../../util/typeset";
import { useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as TruckIcon } from "../../assets/truck_dark.svg";
import { ReactComponent as LaneIcon } from "../../assets/lane_id_dark.svg";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import {
  ConditionalWrapper,
  checkStaffUser,
  generateHighlight,
  getTimezoneText,
  useAsyncEffect,
} from "../../util/util";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getTripDetailsState } from "../../redux/slices/layout.slice";
import { format, formatDistanceToNowStrict } from "date-fns";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import { Customer } from "../../types/customer.type";
import { utcToZonedTime } from "date-fns-tz";
import { getUserInfo } from "../../redux/slices/auth.slice";
import { getCustomerSettings } from "../../api/config.api";
import { CustomSetting } from "../../features/trips/TrackingAdminTools";

export interface ITripDetailCardProps {
  trip: Trip;
  onClose?: () => void;
  search?: string;
  isOpsPortal?: boolean;
}

export default function TripDetailCard(props: ITripDetailCardProps) {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [allCustomers, setAllCustomers] = useState<Customer[] | undefined>();
  const { trip, onClose, search } = props;
  const panelStopId = useAppSelector(getTripDetailsState).stopId;
  const { stopId = panelStopId } = useParams<{ stopId: string }>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [justCopiedTripUrl, setJustCopiedTripUrl] = useState<boolean>(false);
  const [stopsToShowEta, setStopsToShowEta] = useState<number>(0);
  const [adjustedItinerary, setAdjustedItinerary] = useState<Stop[] | null>(
    null
  );

  const userInfo = useAppSelector(getUserInfo).userInfo;
  const isStaff = checkStaffUser(userInfo.admin_user);

  useEffect(() => {
    let newItinerary: Stop[] = structuredClone(trip.itinerary.stops);
    let allOutOfSequenceStops: Stop[] = [];

    newItinerary.forEach((stop, index) => {
      if (stop.sequence_status === SequenceStatus.OUT_OF_SEQUENCE) {
        // Add each OOS stop to list
        allOutOfSequenceStops.push(stop);
        // Change each OOS stop to skipped in its scheduled position
        newItinerary[index] = {
          ...stop,
          sequence_status: SequenceStatus.SKIPPED,
          status: Status.AWAITING_AV,
          arrived_at: null,
          departed_at: null,
          id: `${stop.id}-skipped_duplicate`,
        };
      }
    });

    // Sort out-of-sequence stops starting with lowest logged_order
    const oosStopsOrderd = allOutOfSequenceStops.sort((a, b) => {
      if (a.logged_order && b.logged_order) {
        return a.logged_order - b.logged_order;
      } else {
        return -1;
      }
    });
    oosStopsOrderd.forEach((stop) => {
      // Get position of previous logged stop in itinerary
      const previousStopIndex = newItinerary.findIndex((prevStop) => {
        if (stop.logged_order) {
          return (
            !prevStop.id.endsWith("duplicate") &&
            prevStop.logged_order === stop.logged_order - 1
          );
        } else return -1;
      });
      // Add duplicate stop object in it's logged position
      newItinerary.splice(previousStopIndex + 1, 0, stop);
    });

    // Set adjusted itinerary to be used in trip timeline
    setAdjustedItinerary(newItinerary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  useEffect(() => {
    // set number of future stop ETAs to display based on customer setting
    getCustomerSettings(trip.customer_id).then((resp) => {
      const estTimeStopsDisplaySetting = resp.results?.filter(
        (setting: CustomSetting) =>
          setting.name.toLowerCase() === "est_time_stops_display"
      );
      // check if the setting exists for the trip's customer
      if (estTimeStopsDisplaySetting[0]?.value) {
        const settingValue = Number(estTimeStopsDisplaySetting[0]?.value);
        // ensure the value is a number
        if (!isNaN(settingValue)) {
          setStopsToShowEta(settingValue);
        }
      }
    });
  }, [trip.customer_id]);

  const stop = useMemo(() => {
    if (!trip.itinerary.stops) {
      return;
    }
    return trip.itinerary.stops?.find((stop) => stop.id === stopId);
  }, [stopId, trip]);

  const { loading } = useAsyncEffect(async () => {
    if (isStaff) {
      setAllCustomers((await dispatch(fetchCustomers({}))).payload.results);
    }
  }, []);

  useEffect(() => {
    if (trip.av.customer || trip.av.customer_id) {
      const currentCustomer = allCustomers?.find(
        (customer) =>
          customer.id === trip.av.customer ||
          customer.id === trip.av.customer_id
      );
      setCustomer(currentCustomer);
    }
  }, [trip, allCustomers]);

  useEffect(() => {
    if (justCopiedTripUrl) {
      // "Trip URL copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedTripUrl(false);
      }, 1500);
    }
  }, [justCopiedTripUrl]);

  return (
    <Paper
      elevation={0}
      sx={{ borderRadius: theme.spacing(1), paddingBottom: theme.spacing(0.5) }}
    >
      <Grid
        container
        direction="column"
        sx={{
          backgroundColor: colors.Grey[700],
          padding: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="row">
              <StyledTypography
                sx={{
                  ...typeset.Headline,
                  color: "white",
                  lineHeight: "1.7em",
                }}
              >
                Trip {generateHighlight(trip.itinerary.name, search)}
              </StyledTypography>
              <Tooltip
                open={justCopiedTripUrl}
                sx={{ marginTop: 0.5 }}
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "rgba(0,0,0,1)",
                      fontSize: "12px",
                    },
                  },
                }}
                title={`Trip URL copied to clipboard!`}
              >
                <Button
                  sx={{
                    padding: "4px 5px",
                    marginLeft: "4px",
                    minWidth: "unset",
                    ":hover": {
                      backgroundColor: "rgba(200,200,255,0.3)",
                    },
                  }}
                  onClick={() => {
                    // The url to copy is based on whether user is on ops portal or customer portal
                    const copyUrl = `${window.location.protocol}//${
                      window.location.host
                    }${
                      props.isOpsPortal
                        ? "/ops/trips/manage?view_trip="
                        : "/trips/details/"
                    }${trip.id}${props.isOpsPortal ? "" : "/empty"}`;

                    navigator.clipboard.writeText(copyUrl);
                    setJustCopiedTripUrl(true);
                  }}
                >
                  <CopyIcon style={{ width: "1.2em", height: "1.2em" }} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {onClose && (
            <Grid item sx={{ cursor: "pointer", marginTop: "5px" }}>
              <CloseIcon onClick={onClose} />
            </Grid>
          )}
        </Grid>

        <Grid container sx={{ marginTop: 1.5, marginBottom: 1 }}>
          <Grid
            container
            direction="row"
            sx={{ width: "fit-content", marginRight: 4 }}
            alignItems="center"
          >
            <Icon sx={{ marginRight: 0.5 }}>
              <TruckIcon style={{ width: 24, height: 24 }} />
            </Icon>
            <StyledTypography
              sx={{
                ...typeset.HeadlineSmall,
                fontWeight: 500,
                marginTop: theme.spacing(0.5),
                color: "white",
              }}
            >
              AV# <b>{stop?.av_id || trip.av.av_id}</b>
            </StyledTypography>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{ width: "fit-content" }}
            alignItems="center"
          >
            <Icon sx={{ marginRight: 0.5 }}>
              <LaneIcon style={{ width: 24, height: 24 }} />
            </Icon>
            <StyledTypography
              sx={{
                ...typeset.HeadlineSmall,
                fontWeight: 500,
                marginTop: theme.spacing(0.5),
                color: "white",
              }}
            >
              Lane ID{" "}
              <b>
                {stop?.trip_template_external_id ||
                  trip.template?.external_id ||
                  "N/A"}
              </b>
            </StyledTypography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ marginTop: theme.spacing(1), cursor: "default" }}
        >
          <ConditionalWrapper
            condition={
              customer?.timezone !== undefined &&
              trip.av.current_point_timestamp !== undefined &&
              trip.av.current_point_timestamp !== null
            }
            wrapper={(children: any) => {
              return (
                <Tooltip
                  sx={{ marginTop: 0.5 }}
                  title={
                    trip.av.current_point_timestamp
                      ? `${format(
                          utcToZonedTime(
                            new Date(
                              Date.parse(trip.av.current_point_timestamp)
                            ),
                            customer?.timezone || ""
                          ),
                          "PPpp"
                        )} ${getTimezoneText(customer?.timezone)}`
                      : "--"
                  }
                >
                  <div>{children}</div>
                </Tooltip>
              );
            }}
          >
            {!trip.av.long_av_id.includes("UNASSIGNED") &&
              trip.av.status === "OPERATIONAL" && (
                <StyledTypography color="white" sx={typeset.Caption2}>
                  AV last tracked:{" "}
                  {trip.av.current_point_timestamp
                    ? `${formatDistanceToNowStrict(
                        new Date(Date.parse(trip.av.current_point_timestamp))
                      )} ago`
                    : "--"}
                </StyledTypography>
              )}
          </ConditionalWrapper>
        </Grid>
      </Grid>

      {adjustedItinerary &&
        adjustedItinerary.map((stop, idx) => {
          const shouldShowEta =
            idx === 0
              ? false
              : // don't show eta if stop was skipped
              stop.sequence_status === SequenceStatus.SKIPPED
              ? false
              : // if previous stop has arrived or departed
                adjustedItinerary[idx - 1].status !== Status.AWAITING_AV ||
                // or the stop is upcoming and within the number of future ETAs to display based on the customer's setting
                adjustedItinerary
                  .slice(Math.max(idx - stopsToShowEta, 0), idx)
                  .some((stop) => stop.status === Status.AV_DEPARTED);

          return (
            <TripDetailItem
              key={stop.id}
              stop={stop}
              idx={idx}
              isFocus={stop.id === stopId}
              isLast={idx === adjustedItinerary.length - 1}
              search={search}
              showEta={shouldShowEta}
              customerTimezone={customer?.timezone}
              isLoadingCustomer={loading}
            />
          );
        })}
    </Paper>
  );
}
