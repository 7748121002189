import { Card, CardProps, useTheme } from "@mui/material";

export interface IStyledCardProps extends CardProps {}

export default function StyledCard(props: IStyledCardProps) {
  const theme = useTheme();
  const { sx, ...otherProps } = props;
  return (
    <Card
      sx={{
        ...sx,
        borderRadius: "10px",
        padding: theme.spacing(2.5),
      }}
      elevation={0}
      {...otherProps}
    />
  );
}
