import { Grid, Icon, Switch, Tooltip } from "@mui/material";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import { useEffect, useMemo, useState } from "react";
import StyledTypography from "../../components/UI/StyledTypography";
import StyledTable, { HeadCell } from "../../components/UI/StyledTable";
import { Customer } from "../../types/customer.type";
import { CustomerSortMethods, fetchCustomers } from "../../api/customer.api";
import { ConditionalWrapper, useAsyncEffect, useMobile } from "../../util/util";
import TrackingAdminToolsDialog from "./ManualModeSettingDialog";
import { updateEtaBackup } from "../../api/trip.api";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { useAppDispatch } from "../../redux/hooks";
import {
  getCustomerSettings,
  updateSkippedStopsSetting,
} from "../../api/config.api";
import SkippedStopsSettingsDialog from "./SkippedStopsSettingsDialog";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";

export interface CustomSetting {
  level: string;
  name: string;
  value: string | number;
}
export interface TrackingAdminToolsProps {
  // fullWidth?: boolean;
}
export interface TrackingAdminToolsSetting {
  customer: Customer;
  manualModeToggle: boolean;
  isManualModeDisabled: boolean;
  manualBackupReference?: string;
  fixedStopOrderToggle: boolean;
  id: string;
}

const TrackingAdminTools = (props: TrackingAdminToolsProps) => {
  // const {  } = props;
  const dispatch = useAppDispatch();
  const [hasPageLoaded, setHasPageLoaded] = useState<boolean>(false);
  const [isLoadingCustomerSettings, setIsLoadingCustomerSettings] =
    useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >();
  const [currentlySettingManualBackupTo, setCurrentlySettingManualBackupTo] =
    useState<"on" | "off">("off");
  const [
    currentlySettingFixedStopOrderTo,
    setCurrentlySettingFixedStopOrderTo,
  ] = useState<0 | 1>(0);
  const [isTrackingAdminToolsDialogOpen, setIsTrackingAdminToolsDialogOpen] =
    useState<boolean>(false);
  const [isSkippedStopsSettingDialogOpen, setIsSkippedStopsSettingDialogOpen] =
    useState<boolean>(false);
  const [trackingAdminSettingsTableInfo, setTrackingAdminSettingsTableInfo] =
    useState<TrackingAdminToolsSetting[]>([]);

  useEffect(() => {
    // this makes sure customers call doesn't happen twice... TODO: better solution?
    setHasPageLoaded(true);
  }, []);

  const isMobile = useMobile();

  const { loading } = useAsyncEffect(async () => {
    if (hasPageLoaded) {
      const customersResp = await fetchCustomers(
        undefined,
        undefined,
        undefined,
        CustomerSortMethods.Name
      );
      setCustomers(customersResp.results);
    }
  }, [hasPageLoaded]);

  const loadCustomerSettings = () => {
    setIsLoadingCustomerSettings(true);
    const tempSettingsArray: TrackingAdminToolsSetting[] = [];

    customers.forEach((customer, i) => {
      getCustomerSettings(customer.id).then((resp) => {
        const manualBackupToggleSetting = resp.results?.filter(
          (setting: CustomSetting) =>
            setting.name.toLowerCase() === "manual_backup"
        );
        const manualBackupUrlSetting = resp.results?.filter(
          (setting: CustomSetting) =>
            setting.name.toLowerCase() === "manual_backup_url"
        );
        const skippedStopsToggleSetting = resp.results?.filter(
          (setting: CustomSetting) =>
            setting.name.toLowerCase() === "skipped_stops_detection_enabled"
        );

        tempSettingsArray.push({
          id: customer.id,
          customer: customer,
          manualModeToggle: manualBackupToggleSetting[0]?.value === "on",
          isManualModeDisabled:
            manualBackupToggleSetting.length === 0 ||
            manualBackupToggleSetting[0]?.value === "disabled",
          manualBackupReference: manualBackupUrlSetting[0]?.value,
          fixedStopOrderToggle: skippedStopsToggleSetting[0]?.value === 0,
        });

        // once we've gotten manual tracking settings for each customer, display them in the table
        if (customers.length === tempSettingsArray.length) {
          // resort alphabetically in case the options got misordered
          tempSettingsArray.sort(function (a, b) {
            var textA = a.customer.name.toUpperCase();
            var textB = b.customer.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          setIsLoadingCustomerSettings(true);
          setTrackingAdminSettingsTableInfo(tempSettingsArray);
        }
      });
    });
  };

  const setManualBackupSetting = async () => {
    if (selectedCustomer) {
      await Promise.all([
        updateEtaBackup(selectedCustomer.id, {
          // name: "MANUAL_BACKUP",
          value: currentlySettingManualBackupTo,
          // level: "customer",
        }),
      ])
        .then(() => {
          dispatch(
            openSnackbar({
              severity: "success",
              message: `Manual tracking mode successfully set to "${
                currentlySettingManualBackupTo === "on" ? "On" : "Off"
              }".`,
              position: { horizontal: "center", vertical: "bottom" },
            })
          );
          loadCustomerSettings();
        })
        .catch(() => {
          dispatch(
            openSnackbar({
              severity: "error",
              message: "Failed to set manual tracking mode.",
              position: { horizontal: "center", vertical: "bottom" },
            })
          );
          loadCustomerSettings();
        });
    }
    setIsTrackingAdminToolsDialogOpen(false);
  };
  const setFixedStopOrderSetting = async () => {
    if (selectedCustomer) {
      await Promise.all([
        updateSkippedStopsSetting(selectedCustomer.id, {
          // name: "SKIPPED_STOPS_DETECTION_ENABLED",
          value: currentlySettingFixedStopOrderTo,
          // level: "customer",
        }),
      ])
        .then(() => {
          dispatch(
            openSnackbar({
              severity: "success",
              message: `Fixed stop order successfully "${
                currentlySettingFixedStopOrderTo === 0 ? "enabled" : "disabled"
              }".`,
              position: { horizontal: "center", vertical: "bottom" },
            })
          );
          loadCustomerSettings();
        })
        .catch(() => {
          dispatch(
            openSnackbar({
              severity: "error",
              message: "Failed to change fixed stop order setting.",
              position: { horizontal: "center", vertical: "bottom" },
            })
          );
          loadCustomerSettings();
        });
    }
    setIsSkippedStopsSettingDialogOpen(false);
  };

  useEffect(() => {
    loadCustomerSettings();
    // eslint-disable-next-line
  }, [customers]);

  const columns = useMemo(() => {
    return [
      {
        id: "customer.name",
        label: "Customer",
        sortable: false,
        width: 200,
        minWidth: 100,
        customRender: (settingsInfo: TrackingAdminToolsSetting) => (
          <Grid container direction="column">
            <StyledTypography sx={{ color: colors.TrueGray[700] }}>
              {settingsInfo.customer.name}
            </StyledTypography>
          </Grid>
        ),
      },
      {
        id: "fixedStopOrder",
        label: (
          <Grid container sx={{ minWidth: "140px", maxWidth: 140 }}>
            <Grid item>
              {" "}
              <StyledTypography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: "24px",
                  marginRight: 1,
                  marginTop: 0.5,
                  fontWeight: 700,
                }}
              >
                Fixed stop order
              </StyledTypography>
            </Grid>
            <Grid item>
              {" "}
              <Tooltip
                sx={{ marginTop: 0.5 }}
                title={`Enabling "fixed stop order" disables skipped and out-of-sequence stop detection.`}
              >
                <Icon>
                  <InfoIcon />
                </Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ),
        sortable: false,
        width: 140,
        minWidth: 140,
        customRender: (settingsInfo: TrackingAdminToolsSetting) => (
          <Grid container direction="column">
            <Switch
              key={`fixedOrderSwitch-${settingsInfo.customer.id}`}
              id={`fixedOrderSwitch-${settingsInfo.customer.id}`}
              onClick={(e) => {
                setSelectedCustomer(settingsInfo.customer);
                setCurrentlySettingFixedStopOrderTo(
                  settingsInfo.fixedStopOrderToggle ? 1 : 0
                );
                setIsSkippedStopsSettingDialogOpen(true);
              }}
              // value={settingsInfo.manualModeToggle}
              checked={settingsInfo.fixedStopOrderToggle}
              // checked={checked}
              // onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        ),
      },
      {
        id: "customer.id",
        label: (
          <Grid container sx={{ minWidth: "120px", maxWidth: 120 }}>
            <Grid item>
              {" "}
              <StyledTypography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: "24px",
                  marginRight: 1,
                  marginTop: 0.5,
                  fontWeight: 700,
                }}
              >
                Manual Mode
              </StyledTypography>
            </Grid>
            <Grid item>
              {" "}
              <Tooltip
                sx={{ marginTop: 0.5 }}
                title={`Enabling "manual mode" displays a red banner linking to the customer's ETA backup sheet.`}
              >
                <Icon>
                  <InfoIcon />
                </Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ),
        sortable: false,
        width: 120,
        minWidth: 120,
        customRender: (settingsInfo: TrackingAdminToolsSetting) => (
          <Grid container direction="column">
            <ConditionalWrapper
              condition={settingsInfo.isManualModeDisabled}
              wrapper={(children: any) => {
                return (
                  <div style={{ width: "70px" }}>
                    <Tooltip sx={{ marginTop: 0.5 }} title={"Action disabled"}>
                      <div>{children}</div>
                    </Tooltip>
                  </div>
                );
              }}
            >
              <Switch
                key={`manualModeSwitch-${settingsInfo.customer.id}`}
                id={`manualModeSwitch-${settingsInfo.customer.id}`}
                sx={{
                  cursor: settingsInfo.isManualModeDisabled
                    ? "not-allowed"
                    : undefined,
                }}
                onClick={(e) => {
                  setSelectedCustomer(settingsInfo.customer);
                  setCurrentlySettingManualBackupTo(
                    settingsInfo.manualModeToggle ? "off" : "on"
                  );
                  setIsTrackingAdminToolsDialogOpen(true);
                }}
                // value={settingsInfo.manualModeToggle}
                checked={settingsInfo.manualModeToggle}
                disabled={settingsInfo.isManualModeDisabled}
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </ConditionalWrapper>
          </Grid>
        ),
      },
      {
        id: "id",
        label: "Manual Backup Reference",
        sortable: false,
        width: 200,
        minWidth: 100,
        customRender: (settingsInfo: TrackingAdminToolsSetting) =>
          settingsInfo.manualBackupReference ? (
            <a
              href={settingsInfo.manualBackupReference}
              style={{
                whiteSpace: "nowrap",
                color: "#0000ee",
                stroke: "#0000ee",
              }}
              target="_blank"
              rel="noreferrer"
            >
              Backup ETA sheet{" "}
              <svg
                style={{
                  transform: "rotate(135deg)",
                  verticalAlign: "middle",
                }}
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.16699 12H19.167"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.167 7L5.16699 12"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.167 17L5.16699 12"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          ) : (
            <div>Not configured</div>
          ),
      },
    ] as Array<HeadCell<TrackingAdminToolsSetting>>;
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        margin: 12,
        marginRight: 0,
        borderRadius: isMobile ? "20px 0 0 20px" : "40px 0 0 40px",
        boxSizing: "content-box",
      }}
    >
      <Grid
        container
        direction="column"
        sx={{ padding: isMobile ? 4 : 7.5, paddingTop: isMobile ? 2 : 4 }}
      >
        <StyledTypography sx={typeset.Header1} mb={2}>
          Tracking admin tools
        </StyledTypography>
        <StyledTypography
          sx={{ ...typeset.body, color: colors.Grey[500], mb: 2.5 }}
        >
          Update trip tracking settings for customers by enforcing fixed stop
          order or enabling manual mode.
        </StyledTypography>
        <hr />
        {loading ||
        isLoadingCustomerSettings ||
        trackingAdminSettingsTableInfo.length > 0 ? (
          <>
            <StyledTable
              data={trackingAdminSettingsTableInfo}
              columns={columns}
              isLoading={loading || trackingAdminSettingsTableInfo.length === 0}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <StyledTypography align="center" sx={typeset.Header2Small}>
              Something went wrong. Please try again.
            </StyledTypography>
          </div>
        )}
      </Grid>
      <TrackingAdminToolsDialog
        customer={selectedCustomer}
        setTo={currentlySettingManualBackupTo}
        isOpen={isTrackingAdminToolsDialogOpen}
        onSubmit={() => setManualBackupSetting()}
        onCancel={() => setIsTrackingAdminToolsDialogOpen(false)}
      />
      <SkippedStopsSettingsDialog
        customer={selectedCustomer}
        setTo={currentlySettingFixedStopOrderTo}
        isOpen={isSkippedStopsSettingDialogOpen}
        onSubmit={() => setFixedStopOrderSetting()}
        onCancel={() => setIsSkippedStopsSettingDialogOpen(false)}
      />
    </div>
  );
};

export default TrackingAdminTools;
