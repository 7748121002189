import { useMemo, useState } from "react";
import StyledFilter, {
  AutoCompleteProps,
} from "../../components/UI/StyledFilter";
import { ConditionalWrapper, useAsyncEffect } from "../../util/util";
import { fetchLanes } from "../../api/trip.api";
import { Tooltip } from "@mui/material";
import { FILTER_ITEMS_TO_RETURN } from "../../util/constants";

export interface Lane {
  external_id: string;
  customer_id: string;
}

export interface LaneSelectExtraProps {
  customerIds?: string[];
}

export type LaneSelectProps = Omit<
  AutoCompleteProps<string>,
  "options" | "label"
> &
  LaneSelectExtraProps;

const LaneFilter = (props: LaneSelectProps) => {
  const { customerIds, ...otherProps } = props;
  const [lanes, setLanes] = useState<Lane[]>([]);

  const { loading } = useAsyncEffect(async () => {
    const lanesResp = await fetchLanes(
      undefined,
      customerIds,
      undefined,
      FILTER_ITEMS_TO_RETURN
    );
    setLanes(lanesResp.results);
  }, [customerIds]);

  const options = useMemo(
    () =>
      lanes.map((laneId) => ({
        label: laneId.external_id,
        value: laneId.external_id,
      })),
    [lanes]
  );

  const isDisabled =
    !customerIds || customerIds.length === 0 || lanes.length === 0;
  const noCustomersError =
    "Please select a customer before filtering by Lane ID.";
  const noLanesError = `No routes returned for the selected customer${
    customerIds && customerIds.length > 1 ? "s" : ""
  }`;

  return (
    <ConditionalWrapper
      condition={isDisabled}
      wrapper={(children: any) => {
        return (
          <Tooltip
            sx={{ marginTop: 0.5 }}
            title={
              !customerIds || customerIds.length === 0
                ? noCustomersError
                : lanes.length === 0
                ? noLanesError
                : ""
            }
          >
            <div>{children}</div>
          </Tooltip>
        );
      }}
    >
      <StyledFilter
        {...otherProps}
        label="Lane ID"
        isLoading={loading}
        options={options}
        isDisabled={otherProps.isDisabled || lanes.length === 0}
        noResultsText={"Cannot find the specified lane ID"}
      />
    </ConditionalWrapper>
  );
};

export default LaneFilter;
