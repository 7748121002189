import fleetAxios from "./axios";
import { store } from "../redux/store";
import { openSnackbar } from "../redux/slices/snackbar.slice";

export const login = async (payload: { email: string; password: string }) => {
  try {
    const authData = await fleetAxios
      .post("/token/", payload)
      .then((resp) => resp.data);
    localStorage.setItem("access_token", authData.access);
    localStorage.setItem("refresh_token", authData.refresh);
    return authData;
  } catch (e) {
    store.dispatch(
      openSnackbar({
        message: "Email or password does not match our records.",
        severity: "error",
      })
    );
    console.error(e);
  }
};

export const refreshLogin = async (refreshToken: string) => {
  const authData = await fleetAxios
    //@ts-expect-error
    .post("/token/refresh/", { refresh: refreshToken }, { isAuth: true })
    .then((resp) => resp.data);
  localStorage.setItem("access_token", authData.access);
  return authData;
};

export const signUp = async (userData: {
  email: string;
  password: string;
  confirm_password: string;
  first_name: string;
  last_name: string;
  profile?: {
    job_category: string;
    job_name: string;
    job_seniority: string;
  };
}) => {
  await fleetAxios
    .post("/account/register/", userData)
    .then((resp) => resp.data);
};

export const activate = async (token: string) => {
  const data = await fleetAxios
    .post(`/account/activate/${token}/`, {})
    .then((resp) => resp.data);
  return data;
};

export const resetPassword = async (email: string) => {
  const data = await fleetAxios
    .post("/account/password-reset/", { email, force_send: true })
    .then((resp) => resp.data);
  return data;
};

export const resetPasswordConfirm = async (
  password: string,
  signature: string
) => {
  const data = await fleetAxios
    .post("/account/password-reset/confirm/", { password, signature })
    .then((resp) => resp.data);
  return data;
};

export const fetchUserInfo = async () => {
  const data = await fleetAxios.get("/users/me/").then((resp) => resp.data);
  return data;
};

export interface PartialUserInfo {
  avatar?: string;
  profile?: {
    job_category?: string;
    job_name?: string;
    job_seniority?: string;
  };
  timezone?: string;
  customer_id?: string;
  first_name?: string;
  last_name?: string;
  // CANNOT update email, customer_name, admin_user, or allowed_customers
}
export const updateUserInfo = async (userInfo: PartialUserInfo) => {
  return await fleetAxios.patch("/users/me/", userInfo);
};

export const fetchUserJobs = async () => {
  const data = await fleetAxios.get("/user-jobs/").then((resp) => resp.data);
  return data;
};
