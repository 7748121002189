const LOGIN_PREFIX = "/login";
const loginRoutes = {
  root: `${LOGIN_PREFIX}`,
  signUp: (page: number) =>
    page ? `${LOGIN_PREFIX}/sign-up/${page}` : `${LOGIN_PREFIX}/sign-up/:id`,
  resetPassword: `/account/password-reset`,
  verify: `${LOGIN_PREFIX}/verify`,
  success: `${LOGIN_PREFIX}/success`,
  inbox: `${LOGIN_PREFIX}/inbox`,
  landing: "/landing",
};
const TRIP_PREFIX = "/trips";
const tripRoutes = {
  root: `${TRIP_PREFIX}`,
  details: (id: string, stopId: string) =>
    id
      ? `${TRIP_PREFIX}/details/${id}/${stopId}`
      : `${TRIP_PREFIX}/details/:id/:stopId`,
};

export const routes = {
  loginRoutes,
  tripRoutes,
  redirectPath: loginRoutes.root,
};
