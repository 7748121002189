import { Grid, useTheme } from "@mui/material";
import { CombinedStop, SequenceStatus, Status } from "../../types/trip.type";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import TripChip from "./StopChip";
import * as colors from "../../util/colors";
import { ReactComponent as CompletedStartPoint } from "../../assets/completed_start_point.svg";
import { ReactComponent as CompletedMidPoint } from "../../assets/completed_mid_point.svg";
import { ReactComponent as FutureMidPoint } from "../../assets/future_end_point.svg";
import { ReactComponent as FutureEndPoint } from "../../assets/future_mid_point.svg";
import { ReactComponent as CurrentPoint } from "../../assets/current_point.svg";
import { ReactComponent as CurrentEndPoint } from "../../assets/current_end_point.svg";
import { ReactComponent as NavIcon } from "../../assets/nav_arrow.svg";
import { ReactComponent as OOSPoint } from "../../assets/warning_icon_black.svg";
import { ReactComponent as CompletedOOSPoint } from "../../assets/warning_icon_black_filled.svg";
import {
  formatHourlyTime,
  generateHighlight,
  generateMapLink,
} from "../../util/util";
import { Link } from "react-router-dom";

export interface ITripDetailItemProps {
  stop: CombinedStop;
  idx: number;
  isFocus?: boolean;
  isLast?: boolean;
  search?: string;
  showEta?: boolean;
  customerTimezone?: string;
  isLoadingCustomer?: boolean;
}

export default function TripDetailItem(props: ITripDetailItemProps) {
  const {
    stop,
    idx,
    isFocus,
    isLast,
    search,
    showEta,
    customerTimezone,
    isLoadingCustomer,
  } = props;
  const isFirst = idx === 0;
  const theme = useTheme();
  let icon = <></>;
  if (stop.sequence_status === SequenceStatus.OUT_OF_SEQUENCE) {
    icon =
      stop.status === Status.AV_DEPARTED ? <CompletedOOSPoint /> : <OOSPoint />;
  } else if (stop.status === Status.AV_ARRIVED && !isFirst && !isLast) {
    icon = <CurrentPoint />;
  } else if (isFirst) {
    icon =
      stop.status === Status.AV_DEPARTED ? (
        <CompletedStartPoint />
      ) : (
        <CurrentEndPoint />
      );
  } else if (isLast) {
    icon =
      stop.status === Status.AV_ARRIVED ||
      stop.status === Status.AV_DEPARTED ? (
        <CompletedStartPoint />
      ) : (
        <FutureEndPoint />
      );
  } else {
    icon =
      stop.status === Status.AV_DEPARTED ? (
        <CompletedMidPoint />
      ) : (
        <FutureMidPoint />
      );
  }

  const lineElement =
    stop.status === Status.AWAITING_AV || stop.status === Status.AV_ARRIVED ? (
      <div
        style={{
          width: 1,
          borderLeft: `dashed 2px ${colors.BLACK}`,
          flexGrow: 1,
          bottom: -25,
        }}
      />
    ) : (
      <div
        style={{
          width: 1,
          borderLeft: `2px solid ${colors.Violet[400]}`,
          flexGrow: 1,
          bottom: -25,
        }}
      />
    );

  return (
    <Grid
      container
      direction="row"
      sx={{
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: "unset",
        borderRadius: "8px",
        backgroundColor: isFocus ? colors.Violet[50] : undefined,
      }}
      flexWrap={"nowrap"}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ width: "fit-content", marginRight: theme.spacing(1) }}
      >
        <Grid item>{icon}</Grid>
        {!isLast && lineElement}
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <Grid
          container
          direction="row"
          flexWrap={"nowrap"}
          alignItems="flex-start"
        >
          <Grid
            item
            sx={{
              marginRight: theme.spacing(1),
            }}
          >
            <StyledTypography sx={typeset.Header3}>
              {generateHighlight(
                stop.site?.properties.name || stop.stop_info?.name || "",
                search
              )}{" "}
              {stop.sequence_status === SequenceStatus.SKIPPED && (
                <i>(Skipped)</i>
              )}
              {stop.sequence_status === SequenceStatus.OUT_OF_SEQUENCE && (
                <i>(Out-of-sequence)</i>
              )}
            </StyledTypography>
          </Grid>
          {stop.stop_info && (
            <Grid item sx={{ height: "24px" }}>
              <Link
                to={generateMapLink(
                  stop?.stop_info?.location.properties.address
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NavIcon />
              </Link>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: theme.spacing(1) }}
        >
          <Grid container direction="row" sx={{ width: "fit-content" }}>
            <Grid
              container
              direction="column"
              sx={{ width: theme.spacing(10), marginRight: theme.spacing(2) }}
            >
              {!isFirst && (
                <>
                  <Grid item>
                    <StyledTypography sx={typeset.BodySmall}>
                      {stop.status !== Status.AWAITING_AV
                        ? "Arrived at"
                        : "ETA"}
                    </StyledTypography>
                  </Grid>
                  <Grid item sx={{ marginBottom: theme.spacing(1) }}>
                    <StyledTypography sx={typeset.Headline}>
                      {isLoadingCustomer
                        ? "--"
                        : stop.arrived_at
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.arrived_at)),
                            customerTimezone
                          )
                        : stop.eta && (stop.arriving || showEta)
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.eta)),
                            customerTimezone
                          )
                        : "--"}
                    </StyledTypography>
                  </Grid>
                </>
              )}
              {!isLast && (isFirst || stop.status !== Status.AWAITING_AV) && (
                <>
                  <Grid item>
                    <StyledTypography sx={typeset.BodySmall}>
                      Departed at
                    </StyledTypography>
                  </Grid>
                  <Grid item sx={{ marginBottom: theme.spacing(1) }}>
                    <StyledTypography sx={typeset.Headline}>
                      {isLoadingCustomer
                        ? "--"
                        : stop.departed_at
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.departed_at)),
                            customerTimezone
                          )
                        : "--"}
                    </StyledTypography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container direction="column" sx={{ width: "fit-content" }}>
              {!isFirst && (
                <>
                  <Grid item>
                    <StyledTypography sx={typeset.BodySmall}>
                      Scheduled
                    </StyledTypography>
                  </Grid>
                  <Grid item sx={{ marginBottom: theme.spacing(1) }}>
                    <StyledTypography sx={typeset.Headline}>
                      {isLoadingCustomer
                        ? "--"
                        : stop.scheduled_arrival
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.scheduled_arrival)),
                            customerTimezone
                          )
                        : "--"}
                    </StyledTypography>
                  </Grid>
                </>
              )}
              {!isLast && (isFirst || stop.status !== Status.AWAITING_AV) && (
                <>
                  <Grid item>
                    <StyledTypography sx={typeset.BodySmall}>
                      Scheduled
                    </StyledTypography>
                  </Grid>
                  <Grid item sx={{ marginBottom: theme.spacing(1) }}>
                    <StyledTypography sx={typeset.Headline}>
                      {isLoadingCustomer
                        ? "--"
                        : stop.scheduled_departure
                        ? formatHourlyTime(
                            new Date(Date.parse(stop.scheduled_departure)),
                            customerTimezone
                          )
                        : "--"}
                    </StyledTypography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-end"
            sx={{ width: "fit-content" }}
          >
            {!isFirst &&
              stop.arrival_status &&
              stop.arriving &&
              stop.sequence_status !== SequenceStatus.SKIPPED && (
                <Grid item>
                  <TripChip status={stop.arrival_status} />
                </Grid>
              )}
            {!isLast &&
            stop.sequence_status !== SequenceStatus.SKIPPED &&
            stop.departure_status &&
            stop.departed_at ? (
              <Grid item>
                <TripChip status={stop.departure_status} />
              </Grid>
            ) : (
              <div style={{ height: 24 }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
