import fleetAxios from "./axios";

export enum AVSortMethods {
  Id = "long_av_id",
  ReverseId = "-long_av_id",
  Status = "status",
  ReverseStatus = "-status",
}

export enum AVStatuses {
  Operational = "operational",
  Decommissioned = "decommissioned",
  Maintenance = "maintenance",
}

export const fetchAvs = async (
  text?: string,
  customer_ids?: string[],
  status?: AVStatuses,
  page?: number,
  ordering?: AVSortMethods,
  size?: number
) => {
  return await fleetAxios
    .get(`/v2/avs`, {
      params: { text, customer_ids, status, page, ordering, size },
      paramsSerializer: { indexes: null },
    })
    .then((resp) => resp.data);
};

export const fetchAvById = async (avId: string) => {
  return fleetAxios.get(`/v2/av/${avId}`).then((resp) => resp.data);
};

export const updateAV = async (av_id: string, customer: string) => {
  return await fleetAxios.patch(`/v2/avs/${av_id}/`, { customer_id: customer });
};
