import { CSSProperties } from "react";
import { TripStatus } from "../../types/trip.type";
import StyledChip from "../UI/StyledChip";
import { ChipProps } from "@mui/material";
import * as typeset from "../../util/typeset";
import * as colors from "../../util/colors";

export interface TripChipProps {
  status: TripStatus;
  completionTime?: string;
}

const TripChip = (props: TripChipProps) => {
  const baseSx: CSSProperties = { ...typeset.Caption2 };
  let chipProps: ChipProps = {};
  switch (props.status) {
    case TripStatus.IN_PROGRESS:
      chipProps = {
        label: "IN PROGRESS",
        sx: {
          ...baseSx,
          backgroundColor: colors.Blue[100],
          color: colors.Blue[700],
        },
      };
      break;
    case TripStatus.SCHEDULED:
      chipProps = {
        label: "UPCOMING",
        sx: {
          ...baseSx,
          backgroundColor: colors.BlueGray[200],
          color: colors.TrueGray[800],
        },
      };
      break;
    case TripStatus.CANCELLED:
      chipProps = {
        label: "CANCELLED",
        sx: {
          ...baseSx,
          backgroundColor: colors.Red[50],
          color: colors.Red[700],
        },
      };
      break;
    case TripStatus.TRACKING_TIMEOUT:
      chipProps = {
        label: "TRACKING TIMEOUT",
        sx: {
          ...baseSx,
          backgroundColor: colors.Red[50],
          color: colors.Red[700],
        },
      };
      break;
    case TripStatus.INTERRUPTED:
      chipProps = {
        label: "INTERRUPTED",
        sx: {
          ...baseSx,
          backgroundColor: colors.Red[50],
          color: colors.Red[700],
        },
      };
      break;
    case TripStatus.COMPLETED:
      chipProps = {
        label: props.completionTime
          ? `COMPLETED AT ${props.completionTime}`
          : "COMPLETED",
        sx: {
          ...baseSx,
          backgroundColor: colors.Violet[900],
          color: colors.WHITE,
        },
      };
      break;
    default:
      break;
  }
  return <StyledChip {...chipProps} />;
};

export default TripChip;
