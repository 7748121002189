import {
  DatePicker,
  DateTimePicker,
  DateTimePickerProps,
  DateValidationError,
  PickerChangeHandlerContext,
  TimePicker,
} from "@mui/x-date-pickers";
import { FieldInputProps, useFormikContext } from "formik";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

export interface StyledDatePickerProps
  extends DateTimePickerProps<Dayjs | Date>,
    Partial<
      Omit<FieldInputProps<Dayjs | Date>, "value" | "onChange" | "onBlur">
    > {
  fullWidth?: boolean;
  disableFormikLabel?: boolean;
  variant?: "date" | "datetime" | "time";
  returnVanillaDate?: boolean;
}

const StyledDatePicker = <T extends Record<string, any>>(
  props: StyledDatePickerProps
) => {
  const formik = useFormikContext<T>();
  const {
    name = "",
    onChange,
    value,
    label,
    disableFormikLabel,
    variant = "date",
    onViewChange,
    returnVanillaDate,
    ...otherProps
  } = props;
  const isError =
    formik && formik.touched[name] && Boolean(formik.errors[name]);
  const dayValue = dayjs(value || formik?.values[name]);

  let component = <></>;
  switch (variant) {
    case "date":
      component = (
        //@ts-expect-error
        <DatePicker
          {...otherProps}
          onChange={(val, context) => {
            //@ts-expect-error
            const ret = returnVanillaDate ? val?.toDate() || null : val;
            if (onChange) onChange(ret, context);
            if (formik) {
              formik.setTouched({ ...formik.touched, [name]: true }, true);
              formik.setFieldValue(name, ret);
            }
          }}
          value={dayValue}
        />
      );
      break;
    case "datetime":
      component = (
        <DateTimePicker
          {...otherProps}
          onViewChange={onViewChange}
          onChange={(val, context) => {
            //@ts-expect-error
            const ret = returnVanillaDate ? val?.toDate() || null : val;
            if (onChange) onChange(ret, context);
            if (formik) {
              formik.setTouched({ ...formik.touched, [name]: true }, true);
              formik.setFieldValue(name, ret);
            }
          }}
          value={dayValue}
        />
      );
      break;
    case "time":
      component = (
        <TimePicker
          value={value || (name && formik?.values[name]) || null}
          onChange={(date, ctx) => {
            if (onChange)
              onChange(
                date,
                ctx as PickerChangeHandlerContext<DateValidationError>
              );
            if (formik && name) {
              formik.setTouched({ ...formik.touched, [name]: true }, true);
              formik.setFieldValue(name.toString(), date);
            }
          }}
        />
      );
      break;
    default:
      break;
  }
  return (
    <FormControl sx={props.fullWidth ? { width: "100%" } : undefined}>
      {!disableFormikLabel && (
        <FormLabel htmlFor={`${label}-field`}>{label}</FormLabel>
      )}
      {component}
      {formik && formik.touched[name] ? (
        <FormHelperText error={isError}>
          {formik.errors[name]?.toLocaleString()}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default StyledDatePicker;
