import { createAsyncThunk } from "@reduxjs/toolkit";
import { setManualBackup, setStopsDisplay } from "../slices/config.slice";
import { getManualBackup, getTimeStopsDisplay } from "../../api/config.api";

export const fetchTimeStopsDisplay = createAsyncThunk(
  "config/fetchTimeStopsDisplay",
  async (_, { dispatch }) => {
    const data = await getTimeStopsDisplay();
    dispatch(setStopsDisplay(data));
  },
);

export const fetchManualBackup = createAsyncThunk(
  "config/fetchManualBackup",
  async (_, { dispatch }) => {
    const data = await getManualBackup();
    dispatch(setManualBackup(data));
  },
);
