import { Grid, useTheme } from "@mui/material";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import StyledTextfield from "../UI/StyledTextfield";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { routes } from "../../util/routes";
import * as yup from "yup";
import StyledButton from "../UI/StyledButton";
import { useAppDispatch } from "../../redux/hooks";
import { resetPassword } from "../../redux/thunks/auth.thunk";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { setUserInfo } from "../../redux/slices/auth.slice";

const validationSchema = yup
  .object()
  .shape({ email: yup.string().required().label("Email Address") });

const LoginAccountCheck = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={async (values) => {
        const action = await dispatch(resetPassword(values.email));
        if (action.payload) {
          dispatch(
            setUserInfo({
              email: values.email,
              lastName: "",
              firstName: "",
              password: "",
              profile: {
                job_category: "",
                job_name: "",
                job_seniority: "",
              },
            }),
          );
          navigate({
            pathname: routes.loginRoutes.inbox,
            search: "?action=RESET",
          });
        } else {
          dispatch(
            openSnackbar({
              severity: "error",
              message: "We cannot find an existing account for this email",
            }),
          );
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isValid }) => (
        <Grid
          container
          direction="column"
          spacing={4}
          sx={{ marginTop: theme.spacing(8) }}
        >
          <Grid item>
            <StyledTypography align="center" sx={typeset.EmailText}>
              Please enter the email address associated with your account
            </StyledTypography>
          </Grid>
          <Grid item>
            <StyledTextfield fullWidth name="email" label="Email Address" />
          </Grid>
          <Grid item>
            <StyledButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              fullWidth
              disabled={!isValid}
            >
              Recover Account
            </StyledButton>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default LoginAccountCheck;
