import { GATIK_PHONE_NUMBER } from "../../util/constants";
import StyledButton from "./StyledButton";
import { simulateCall } from "../../util/util";
import { useNavigate } from "react-router";
import { ReactComponent as HelpIcon } from "../../assets/help.svg";

export interface CallGatikButtonProps {
  variant: "small" | "large" | "icon";
  isFullWidth?: boolean;
}

const CallGatikButton = (props: CallGatikButtonProps) => {
  const { variant, isFullWidth } = props;

  const navigate = useNavigate();

  return (
    <StyledButton
      variant="contained"
      size={variant}
      startIcon={
        <HelpIcon style={{ marginRight: variant === "icon" ? "-8px" : 0 }} />
      }
      sx={{ maxWidth: isFullWidth ? "100%" : 200 }}
      fullWidth={isFullWidth}
      onClick={() => {
        // This checks if the device used is a mobile device
        if (typeof window.screen.orientation === "undefined") {
          simulateCall(GATIK_PHONE_NUMBER);
        }
        navigate("/help");
      }}
    >
      {variant !== "icon" && "Call Gatik Dispatch"}
    </StyledButton>
  );
};

export default CallGatikButton;
