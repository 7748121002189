import { Trip } from "../../types/trip.type";
import { CardContent, Grid } from "@mui/material";
import StyledCard from "../UI/StyledCard";
import StyledTypography from "../UI/StyledTypography";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import { generateHighlight, scrollToTop, useMobile } from "../../util/util";
import { ReactComponent as PurpleTruck } from "../../assets/purple_truck.svg";
import { ReactComponent as PurpleLane } from "../../assets/purple_lane.svg";
import { ReactComponent as TripArrow } from "../../assets/trip_arrow.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getTripDetailsState,
  openTripDetails,
} from "../../redux/slices/layout.slice";
import { useNavigate } from "react-router";
import { routes } from "../../util/routes";
import StyledButton from "../UI/StyledButton";
import TripProgess from "./TripProgress";
import { Customer } from "../../types/customer.type";
export interface ITripCardProps {
  trip: Trip;
  search: string;
  customer?: Customer;
}

export default function TripCard(props: ITripCardProps) {
  const { trip, search, customer } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tripDetails = useAppSelector(getTripDetailsState);
  const isMobile = useMobile();
  const isSelected = tripDetails.id === trip.id;
  return (
    <StyledCard
      onClick={() => {
        if (!isMobile) {
          scrollToTop();
          dispatch(
            openTripDetails({
              id: trip.id,
            })
          );
        }
      }}
      sx={{
        border: isSelected
          ? `1px solid ${colors.Violet[700]}`
          : `1px solid ${colors.Violet[200]}`,
        ":hover": isMobile
          ? {}
          : {
              border: `1px solid ${colors.Violet[700]}`,
              marginBottom: "-2px",
            },
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid
            container
            direction="column"
            sx={{
              borderRadius: "10px",
              backgroundColor: colors.Violet[50],
              padding: 1,
            }}
          >
            <Grid item sx={{ marginBottom: 2 }}>
              <StyledTypography sx={typeset.Header3}>
                <b>
                  Trip {generateHighlight(`${trip.itinerary.name}`, search)}
                </b>
              </StyledTypography>
            </Grid>
            <Grid container sx={{ gap: 1 }} alignItems="center">
              <Grid
                container
                alignItems="center"
                sx={{ width: "fit-content", flexWrap: "nowrap" }}
              >
                <PurpleTruck style={{ marginRight: 8 }} />
                <StyledTypography
                  sx={{ ...typeset.HeadlineSmall, marginRight: 3 }}
                >
                  <span style={typeset.Caption3}>AV#</span>{" "}
                  {generateHighlight(`${trip.av.av_id}`, search)}
                </StyledTypography>
              </Grid>
              <Grid
                container
                alignItems="center"
                sx={{ width: "fit-content", flexWrap: "nowrap" }}
              >
                <PurpleLane style={{ marginRight: 8 }} />{" "}
                <StyledTypography sx={{ ...typeset.HeadlineSmall }}>
                  <span style={typeset.Caption3}>Lane ID</span>{" "}
                  {generateHighlight(
                    `${trip?.template?.external_id || "N/A"}`,
                    search
                  )}
                </StyledTypography>
              </Grid>
            </Grid>
          </Grid>
          <TripProgess trip={trip} customerTimeZone={customer?.timezone} />
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          sx={{ marginTop: 2, marginBottom: -1 }}
        >
          <Grid item>
            {isMobile && (
              <StyledButton
                variant="contained"
                color="secondary"
                size="x-small"
                onClick={() =>
                  navigate(routes.tripRoutes.details(trip.id, "empty"))
                }
                endIcon={
                  <>
                    <TripArrow />
                  </>
                }
              >
                View full trip
              </StyledButton>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}
