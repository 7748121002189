import { saveAs } from "file-saver";
import { StopFormValues } from "../components/trips/TripCreateDialog";
import { TripState } from "../redux/slices/trip.slice";
import { Status } from "../types/trip.type";
import fleetAxios from "./axios";
import { dayjs } from "../util/date-util";

export interface EtaBackupValues {
  name?: string;
  value: string;
  level?: string;
}

export enum TripSortMethods {
  Name = "name",
  ReverseName = "-name",
  Status = "status",
  ReverseStatus = "-status",
  StartTime = "start_time",
  ReverseStartTime = "-start_time",
  CustomerName = "customer_name",
  ReverseCustomerName = "-customer_name",
  AVId = "long_av_id",
  ReverseAVId = "-long_av_id",
  ExternalId = "external_id",
  ReverseExternalId = "-external_id",
}

export enum LaneSortMethods {
  ExternalId = "external_id",
  ReverseExternalId = "-external_id",
}

export const fetchStops = async (filter: TripState["stopsFilter"]) => {
  return await fleetAxios
    .get("/stops/", { params: filter })
    .then((resp) => resp.data);
};

export const fetchLanes = async (
  text?: string,
  customer_ids?: string[],
  ordering?: LaneSortMethods,
  size?: number,
) => {
  return await fleetAxios
    .get("/v1/lanes/", { params: { text, customer_ids, ordering, size } })
    .then((resp) => resp.data);
};

export const fetchTrip = async (id: string) => {
  return await fleetAxios.get(`/trips/${id}/`).then((resp) => resp.data);
};

export const fetchTrips = async (filter: TripState["tripsFilter"]) => {
  return await fleetAxios
    .get("/trips/", { params: filter })
    .then((resp) => resp.data);
};

export const fetchTripsV2 = async (
  text?: string,
  customer_ids?: string[],
  av_ids?: string[],
  external_ids?: string[],
  page?: number,
  statuses?: string[],
  start_date?: string,
  end_date?: string,
  ordering?: TripSortMethods,
  size?: number
) => {
  return await fleetAxios
    .get(`/v2/trips`, {
      params: {
        text,
        customer_ids,
        av_ids,
        external_ids,
        page,
        statuses,
        start_date,
        end_date,
        ordering,
        size,
      },
      paramsSerializer: { indexes: null },
    })
    .then((resp) => resp.data);
};

export const exportTripData = async (payload: {
  customer_id: string;
  // customer_name is only used to put the name in the file name, not used to generate data
  customer_name: string;
  start_date: string;
  end_date: string;
}) => {
  const end_date_minus_one = dayjs(payload.end_date).subtract(1, "day").format("YYYY-MM-DD")
  return await fleetAxios
    .get("/v1/trips-export/", {
      params: payload,
      headers: {
        Accept: "text/csv",
      },
      responseType: "blob",
    })
    .then((resp) => {
      saveAs(
        resp.data,
        `trip_export_${payload.customer_name}_${payload.start_date}_${end_date_minus_one}.csv`
      );
    });
};

const createTripPayload = (payload: StopFormValues) => ({
  scheduled_start: payload.scheduled_start,
  av_id: payload.av_id,
  customer_id: payload.customer_id,
  itinerary: { stops: payload.stops, name: payload.name },
});

export const createTrip = async (payload: StopFormValues) => {
  return await fleetAxios.post("/v2/trips/", createTripPayload(payload));
};

export const fetchTripV2 = async (id: string) => {
  return await fleetAxios.get(`/v2/trips/${id}/`).then((resp) => resp.data);
};

export enum updateTripStatus {
  Fulfilled = "trips/updateTrip/fulfilled",
  Rejected = "trips/updateTrip/rejected",
}

export const updateTrip = async (id: string, payload: StopFormValues) => {
  const updatePayload = createTripPayload(payload);
  return await fleetAxios.patch(`/v2/trips/${id}/`, {
    av_id: updatePayload.av_id,
    itinerary: updatePayload.itinerary,
    scheduled_start: updatePayload.scheduled_start,
  });
};

export const deleteTrip = async (id: string) => {
  return await fleetAxios.delete(`/v2/trips/${id}/`);
};

export const getEtaBackup = async (customer_id: string) => {
  return await fleetAxios.get(`/eta-backup/${customer_id}`);
};

export const updateEtaBackup = async (
  customer_id: string,
  payload: EtaBackupValues
) => {
  return await fleetAxios.put(`/eta-backup/${customer_id}`, payload);
};

export const deleteEtaBackup = async (customer_id: string) => {
  return await fleetAxios.delete(`/eta-backup/${customer_id}`);
};

export const updateStopStatus = async (id: string, status: Status) => {
  return await fleetAxios.put(`/v1/stop-status/${id}/`, {
    status: status.toLowerCase(),
  });
};
