import { CSSProperties } from "react";
import * as colors from "./colors";

export const Header1Small: CSSProperties = {
  fontFamily: "DM Sans",
  fontWeight: 700,
  fontSize: "30px",
  lineHeight: "32px",
};

export const Header1: CSSProperties = {
  fontSize: "36px",
  fontWeight: 700,
};

export const body: CSSProperties = {
  fontFamily: "DM Sans",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "19px",
};

export const Sort: CSSProperties = {
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "20px",
};

export const Header2Small: CSSProperties = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "26px",
};

export const EmailText: CSSProperties = {
  fontSize: "20px",
  lineHeight: "24px",
  color: colors.Grey[500],
};

export const Headline: CSSProperties = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: colors.Grey[800],
};

export const HeadlineSmall: CSSProperties = {
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "16px",
  color: colors.Grey[800],
};

export const BodySmall: CSSProperties = {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  color: colors.Grey[500],
};

export const Header3: CSSProperties = {
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "22px",
};

export const Caption: CSSProperties = {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
};

export const Caption1: CSSProperties = {
  fontSize: "14px",
  fontWeight: "bold",
  lineHeight: "1.29",
};

export const Caption2: CSSProperties = {
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "18px",
};
export const Caption3: CSSProperties = {
  fontSize: "12px",
  fontWeight: "normal",
};
export const Caption2Bold: CSSProperties = {
  fontSize: "12px",
  fontWeight: "bold",
  lineHeight: "18px",
};

export const Header2: CSSProperties = {
  fontSize: "28px",
  fontWeight: 500,
};
