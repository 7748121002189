import { Grid, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../util/auth";
import { routes } from "../../util/routes";
import LoginFooter from "../../components/authentication/LoginFooter";
import LoginHeader from "../../components/authentication/LoginHeader";
import { SPLASH_ROUTES } from "../../util/constants";
import { useEffect } from "react";
import { useMobile } from "../../util/util";

export interface IAuthenticationPortalProps {}

export default function AuthenticationPortal(
  props: IAuthenticationPortalProps,
) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isAuthenticated } = useAuth();

  const isMobile = useMobile();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.tripRoutes.root);
    }
  }, [isAuthenticated, navigate]);

  if (SPLASH_ROUTES.includes(location.pathname)) {
    return <Outlet />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        maxWidth: "500px",
        padding: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: isMobile ? "20px" : "40px",
      }}
    >
      <LoginHeader />
      <Outlet />
      <LoginFooter />
    </Grid>
  );
}
