import { AV } from "./av.type";
import { Customer } from "./customer.type";

export interface CreatedStop {
  internal_id?: string;
  site_id: string;
  travel_time?: number;
  service_time?: number;
}

export interface Stop {
  id: string;
  trip_id: string;
  trip_status: TripStatus;
  av_id: string;
  current_location: {
    name: string;
    address: string;
    point: Point;
  };
  status: Status;
  stop_info?: {
    name: string;
    dock: null;
    description: null;
    location: {
      type: string;
      geometry: {
        type: string;
        coordinates: Point;
      };
      properties: {
        name: string;
        address: string;
      };
    };
  };
  eta: string | null;
  ett: number;
  order: number;
  logged_order: number;
  scheduled_arrival: string | null;
  scheduled_departure: string | null;
  arrived_at: string | null;
  departed_at: string | null;
  arrival_status: StopStatus;
  departure_status: StopStatus;
  previous_stop_name: string | null;

  external_id?: string;
  trip_template_external_id?: string;

  // V2 Additions
  site: Site;
  customer_id?: string;
  travel_time?: number;
  service_time?: number;
  sequence_status?: SequenceStatus;
}

export interface Site {
  id: string;
  type: string;
  geometry: {
    type: string;
    coordinates: Point;
  };
  properties: {
    customer_id: string;
    name: string;
    dock?: string;
    description?: string;
    location: string;
    geofence_radius_meters: number;
  };
}

export interface CombinedStop extends Stop {
  arriving?: boolean;
}

export interface Trip {
  itinerary: { id: string; name: string; stops: Array<Stop> };
  id: string;
  customer_id: string;
  av: AV;
  scheduled_end: string;
  scheduled_start: string;
  scheduled_date?: string;
  status: TripStatus;
  type: string;
  external_id: string;
  trip_template_external_id?: string;
  template?: { id: string; external_id: string };
}
export interface TripWithCustomer extends Trip {
  customer?: Customer;
}

export type Point = [number, number];

export enum TripStatus {
  SCHEDULED = "SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  INTERRUPTED = "INTERRUPTED",
  TRACKING_TIMEOUT = "TRACKING_TIMEOUT",
}

export enum SequenceStatus {
  SKIPPED = "skipped",
  IN_SEQUENCE = "in_sequence",
  OUT_OF_SEQUENCE = "out_of_sequence",
  UNPLANNED = "unplanned",
}

export enum Status {
  AWAITING_AV = "AWAITING_AV",
  AV_ARRIVED = "AV_ARRIVED",
  AV_DEPARTED = "AV_DEPARTED",
}

export enum StopStatus {
  ON_TIME = "ON_TIME",
  EARLY = "EARLY",
  DELAYED = "DELAYED",
  Canceled = "Canceled",
}
