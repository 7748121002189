import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import StyledBackButton from "./StyledBackButton";

export interface IStyledSmallHeaderProps {
  icon?: React.ReactElement;
  onClick: () => void;
}

export default function StyledSmallHeader(props: IStyledSmallHeaderProps) {
  const { icon, onClick } = props;
  const [iconDimensions, setIconDimensions] = useState({ width: 0, height: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setIconDimensions({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    });
  }, [ref]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item ref={ref}>
        <StyledBackButton onClick={onClick} />
      </Grid>
      <Grid item>{icon}</Grid>
      <Grid item sx={iconDimensions}></Grid>
    </Grid>
  );
}
