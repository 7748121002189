import { Grid, Theme, useTheme } from "@mui/material";
import * as React from "react";
import StyledTypography from "../UI/StyledTypography";
import LoginSmallHeader from "./LoginSmallHeader";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import { GATIK_PHONE_NUMBER } from "../../util/constants";
import { simulateCall } from "../../util/util";

const itemPadding = (theme: Theme) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
});

export interface ILoginSplashProps {
  logo?: React.ReactElement;
  title?: string;
  subTitle?: string;
}

export default function LoginSplash(props: ILoginSplashProps) {
  const { logo, title, subTitle } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      sx={{ padding: theme.spacing(2) }}
    >
      <LoginSmallHeader />
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={itemPadding(theme)}
      >
        <Grid item>{logo}</Grid>
      </Grid>
      <Grid item sx={itemPadding(theme)}>
        <StyledTypography align="center" sx={typeset.Header1Small}>
          {title}
        </StyledTypography>
        <StyledTypography align="center" sx={typeset.body} p={theme.spacing(2)}>
          {subTitle}
        </StyledTypography>
      </Grid>
      <Grid
        item
        sx={{ ...itemPadding(theme), paddingBottom: theme.spacing(2) }}
      >
        <StyledTypography sx={{ fontSize: 22 }} align="center">
          Please dial:{" "}
          <span
            style={{ color: colors.PURPLE }}
            onClick={() => {
              simulateCall(GATIK_PHONE_NUMBER);
            }}
          >
            {"833-428-4524"}
          </span>
        </StyledTypography>
      </Grid>
      <Grid item>
        <StyledTypography align="center" sx={typeset.Headline}>
          Hours of operation 1 am - 9 pm CT
        </StyledTypography>
      </Grid>
    </Grid>
  );
}
