import {
  DialogProps,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonProps,
} from "@mui/material";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import StyledButton from "./StyledButton";
import { ReactComponent as CloseIcon } from "../../assets/close_icon_black.svg";

export interface StyledDialogProps extends DialogProps {
  headerText: React.ReactNode | string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  onPrimaryClick?: () => void;
  primaryButtonText?: string | React.ReactNode;
  primaryButtonProps?: ButtonProps;
  isPrimaryButtonDisabled?: boolean;
  onSecondaryClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonProps?: ButtonProps;
  onTertiaryClick?: () => void;
  tertiaryButtonText?: string;
  tertiaryButtonProps?: ButtonProps;
  onClickClose?: () => void;
}

const StyledDialog = (props: StyledDialogProps) => {
  const {
    headerText,
    children,
    footer,
    onPrimaryClick,
    primaryButtonText,
    isPrimaryButtonDisabled,
    primaryButtonProps,
    onSecondaryClick,
    secondaryButtonText,
    secondaryButtonProps,
    onTertiaryClick,
    tertiaryButtonText,
    tertiaryButtonProps,
    onClickClose,
    ...other
  } = props;
  return (
    <Dialog
      {...other}
      PaperProps={{
        // overflow visible is so that the autocomplete dropdown is not cut off
        style: { borderRadius: 20, overflow: "visible" },
      }}
    >
      <DialogTitle
        sx={{
          ...typeset.Header2,
          fontWeight: 700,
          backgroundColor: colors.BackgroundWhite,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{
            ...typeset.Header2,
            fontWeight: 700,
            backgroundColor: colors.BackgroundWhite,
          }}
        >
          <Grid item>{headerText}</Grid>
          {onClickClose && (
            <Grid item sx={{ cursor: "pointer", color: colors.WHITE }}>
              <StyledButton size="icon" onClick={onClickClose}>
                <CloseIcon />
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          backgroundColor: colors.BackgroundWhite,
          padding: 2,
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        {footer ? (
          footer
        ) : (
          <Grid container justifyContent="space-between">
            <Grid item>
              {onSecondaryClick && (
                <StyledButton
                  onClick={() => onSecondaryClick()}
                  size="small"
                  variant="text"
                  buttonStyle="rectangle"
                  sx={{
                    fontWeight: 700,
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color: colors.TrueGray[700],
                    ":hover": { backgroundColor: colors.TrueGray[100] },
                  }}
                >
                  {secondaryButtonText ? secondaryButtonText : "Cancel"}
                </StyledButton>
              )}
            </Grid>

            <Grid item>
              {onTertiaryClick && (
                <>
                  <StyledButton
                    {...tertiaryButtonProps}
                    onClick={() => onTertiaryClick()}
                    size="small"
                    buttonStyle="rectangle"
                    sx={{ ...tertiaryButtonProps?.sx, mr: 2 }}
                  >
                    {tertiaryButtonText ? tertiaryButtonText : "Go back"}
                  </StyledButton>
                </>
              )}
              {(onPrimaryClick || primaryButtonText) && (
                <>
                  <StyledButton
                    {...primaryButtonProps}
                    onClick={() => onPrimaryClick && onPrimaryClick()}
                    variant="contained"
                    size="small"
                    buttonStyle="rectangle"
                    sx={{
                      fontWeight: 700,
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    disabled={isPrimaryButtonDisabled}
                  >
                    {primaryButtonText ? primaryButtonText : "Confirm"}
                  </StyledButton>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default StyledDialog;
