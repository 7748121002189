import StyledTable, { HeadCell } from "../../components/UI/StyledTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getTrips, getTripsCount } from "../../redux/slices/trip.slice";
import { Grid, Tooltip, Icon } from "@mui/material";
import { Trip, TripStatus, TripWithCustomer } from "../../types/trip.type";
import {
  formatHourlyTime,
  getTimezone,
  getTimezoneString,
  getTimezoneText,
  useAsyncEffect,
  useDesktop,
  useMobile,
  useTablet,
} from "../../util/util";
import { fetchTripsV2, getTripById } from "../../redux/thunks/trip.thunk";
import TripCell from "../../components/trips/TripCell";
import { useState, useMemo, useEffect } from "react";
import { Sort } from "../../types/util.type";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import {
  closeTripDetails,
  openTripDetails,
} from "../../redux/slices/layout.slice";
import StyledTypography from "../../components/UI/StyledTypography";
import TripMenu from "../../components/trips/TripMenu";
import TripDrawer from "../../components/trips/TripDrawer";
import StyledSearchField from "../../components/UI/StyledSearchField";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import TripTimeCell from "../../components/trips/TripTimeCell";
import TripAddButton from "../../components/trips/TripAddButton";
import TripCreateDialog from "../../components/trips/TripCreateDialog";
import StyledTabs from "../../components/UI/StyledTabs";
import RefreshButton from "../../components/UI/RefreshButton";
import TripReportDialog from "../../components/trips/TripReportDialog";
import TripEditDialog from "../../components/trips/TripEditDialog";
import { useDebouncedCallback } from "use-debounce";
import { format } from "date-fns";
import { TripSortMethods } from "../../api/trip.api";
import { Customer } from "../../types/customer.type";
import { fetchCustomers } from "../../redux/thunks/customer.thunk";
import TripStatusFilter from "../../components/trips/TripStatusFilter";
import CustomerFilter from "../../components/UI/CustomerFilter";
import AvFilter from "../../components/UI/AvFilter";
import DateRangePickerDialog from "../../components/UI/DateRangePickerDialog";
import LaneFilter from "../../components/UI/LaneFilter";
import { fetchNotifications } from "../../redux/thunks/notifications.thunk";
import NotificationsBanner from "../../components/UI/NotificationsBanner";
import { useSearchParams } from "react-router-dom";
import { getUserInfo } from "../../redux/slices/auth.slice";
import { dayjs } from "../../util/date-util";
import { OPS_POLLING_DELAY_MS } from "../../util/constants";

type TripKey = HeadCell<TripWithCustomer>["id"];

let fetchInterval = setInterval(() => {}, 10000);

const TripsManagement = () => {
  const dispatch = useAppDispatch();
  const [sort, setSort] = useState<TripSortMethods>(TripSortMethods.Name);
  const trips = useAppSelector(getTrips);
  const tripsCount = useAppSelector(getTripsCount);
  const [order, setOrder] = useState<Sort>("asc");
  const [orderBy, setOrderBy] = useState<TripKey>("itinerary.name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [editId, setEditId] = useState("");
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);
  const [skipLoadingAnimation, setSkipLoadingAnimation] = useState(false);
  const [isTripReportOpen, setTripReportOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDateFilterLoaded, setIsDateFilterLoaded] = useState(false);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [tripSearchValue, setTripSearchValue] = useState<string | undefined>();
  const [statusFilterValues, setStatusFilterValues] = useState<string[]>([]);
  const [customerFilterValues, setCustomerFilterValues] = useState<string[]>(
    []
  );
  const [laneFilterValues, setLaneFilterValues] = useState<string[]>([]);
  const [avFilterValues, setAVFilterValues] = useState<string[]>([]);
  const [startDateFilter, setStartDateFilter] = useState<string | undefined>();
  const [endDateFilter, setEndDateFilter] = useState<string | undefined>();
  const [plainTextStartDate, setPlainTextStartDate] = useState<
    string | undefined
  >();
  const [plainTextEndDate, setPlainTextEndDate] = useState<
    string | undefined
  >();
  const [searchedDatePlainText, setSearchedDatePlainText] = useState<
    string | undefined
  >();
  const [customerMap, setCustomerMap] = useState(new Map());
  const [reloadNumber, setReloadNumber] = useState<number>(0);
  const [createTripCounter, setCreateTripCounter] = useState<number>(0);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [queryParameters] = useSearchParams();
  const [viewTripId, setViewTripId] = useState<string | null>(null);
  const [viewedTrip, setViewedTrip] = useState<Trip | null>(null);
  const [editTripId, setEditTripId] = useState<string | null>(null);
  const [hasClosedTripDrawer, setHasClosedTripDrawer] =
    useState<boolean>(false);
  const [defaultEditAvValue, setDefaultEditAvValue] = useState<boolean>(true);
  const [hasSwitchedToDateSort, setHasSwitchedToDateSort] =
    useState<boolean>(false);

  const userInfo = useAppSelector(getUserInfo).userInfo;

  const isMobile = useMobile();

  // must be called immediately in case user has trip details modal open in customer view and then swtiches to trips management.
  if (!queryParameters.get("view_trip") && !hasClosedTripDrawer) {
    dispatch(closeTripDetails());
    setHasClosedTripDrawer(true);
  }

  // if there is a view or edit trip url parameter, get the trip info/trip id and set it to state
  useAsyncEffect(async () => {
    if (viewTripId) {
      const trip = (await dispatch(getTripById(viewTripId))).payload as Trip;
      setViewedTrip(trip);
    }
    if (editTripId) {
      setEditId(editTripId);
      setIsEditOpen(true);
    }
  }, [viewTripId, editTripId]);

  // once the "viewtrip" trip data has been saved to state, open the trip details drawer with the trip
  useAsyncEffect(async () => {
    if (viewedTrip) {
      dispatch(openTripDetails({ trip: viewedTrip }));
    }
  }, [viewedTrip]);

  // Set the page to 1 when switching any filter.
  useEffect(() => {
    setPage(0);
  }, [
    tab,
    order,
    tripSearchValue,
    avFilterValues,
    statusFilterValues,
    customerFilterValues,
    laneFilterValues,
  ]);

  useAsyncEffect(async () => {
    clearInterval(fetchInterval);
    dispatch(fetchNotifications());
    fetchInterval = setInterval(() => {
      dispatch(fetchNotifications());
    }, 60000 * 5);
  }, [isEditOpen]);

  const timeZoneText = useMemo(
    () => getTimezoneText(userInfo.timezone),
    [userInfo]
  );

  useEffect(() => {
    // reset page number to 1
    setPage(0);
    // reset start & end plain text from date range filter
    setPlainTextStartDate(undefined);
    setPlainTextEndDate(undefined);

    const RealTimeDateTimeBasedOnCustomerTz = dayjs(
      new Date(
        new Intl.DateTimeFormat("en-US", {
          timeZone: userInfo.timezone,
          timeZoneName: "short",
        })
          .format()
          .split(",", 1)[0]
      )
    ).toString();

    const yesterday = dayjs(RealTimeDateTimeBasedOnCustomerTz).subtract(
      1,
      "day"
    );

    const today = dayjs(RealTimeDateTimeBasedOnCustomerTz);

    const tomorrow = dayjs(RealTimeDateTimeBasedOnCustomerTz).add(1, "day");

    const dayAfterTomorrow = dayjs(RealTimeDateTimeBasedOnCustomerTz).add(
      2,
      "day"
    );

    if (tab !== 3) {
      // don't all sort by date on any tab other than the "All" tab
      setHasSwitchedToDateSort(false);
      if (orderBy === "scheduled_date") {
        setOrderBy("itinerary.name");
      }
    }
    // "Today" tab
    if (tab === 0) {
      setStartDateFilter(today.format());
      setEndDateFilter(tomorrow.format());
    }
    // "Tomorrow" tab
    else if (tab === 1) {
      setStartDateFilter(tomorrow.format());
      setEndDateFilter(dayAfterTomorrow.format());
    }
    // "Yesterday" tab
    else if (tab === 2) {
      setStartDateFilter(yesterday.format());
      setEndDateFilter(today.format());
    }
    // "All" tab, set dates to undefined
    else if (tab === 3 && !hasSwitchedToDateSort) {
      setHasSwitchedToDateSort(true);
      setOrderBy("scheduled_date");
      setOrder("desc");
      setStartDateFilter(undefined);
      setEndDateFilter(undefined);
    }

    setIsDateFilterLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, orderBy]);

  const resetAllDateFilter = () => {
    setPlainTextStartDate(undefined);
    setPlainTextEndDate(undefined);

    setStartDateFilter(undefined);
    setEndDateFilter(undefined);
  };

  useEffect(() => {
    if (tab !== 3) {
      setSearchedDatePlainText(
        startDateFilter && format(new Date(startDateFilter), "PP")
      );
    }
  }, [startDateFilter, tab]);

  useEffect(() => {
    // reset lane ID filter when customer filter changes
    if (laneFilterValues.length !== 0) {
      setLaneFilterValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFilterValues]);

  useEffect(() => {
    if (orderBy === "customer.name") {
      if (order === "asc") {
        setSort(TripSortMethods.CustomerName);
      }
      if (order === "desc") {
        setSort(TripSortMethods.ReverseCustomerName);
      }
    }
    if (orderBy === "itinerary.name") {
      if (order === "asc") {
        setSort(TripSortMethods.Name);
      }
      if (order === "desc") {
        setSort(TripSortMethods.ReverseName);
      }
    }
    if (orderBy === "av.long_av_id") {
      if (order === "asc") {
        setSort(TripSortMethods.AVId);
      }
      if (order === "desc") {
        setSort(TripSortMethods.ReverseAVId);
      }
    }
    if (orderBy === "template.external_id") {
      if (order === "asc") {
        setSort(TripSortMethods.ExternalId);
      }
      if (order === "desc") {
        setSort(TripSortMethods.ReverseExternalId);
      }
    }
    if (orderBy === "scheduled_date") {
      if (order === "asc") {
        setSort(TripSortMethods.StartTime);
      }
      if (order === "desc") {
        setSort(TripSortMethods.ReverseStartTime);
      }
    }
  }, [order, orderBy]);

  const isTablet = useTablet();
  const isDesktop = useDesktop();

  const closeCreateModal = () => {
    setCreateOpen(false);
  };

  const columns = useMemo(() => {
    let unfilteredColumns = [
      {
        id: "customer.name",
        label: "Customer",
        sortable: true,
        width: 200,
        maxWidth: 200,
        customRender: (trip) => (
          <Grid container direction="column" sx={{ width: "100px" }}>
            <Grid item>
              <img
                style={{
                  height: "40px",
                  width: "36px",
                  borderRadius: "12px",
                  backgroundColor: "#EEEEEE",
                  border: `1px solid ${colors.TrueGray[500]}`,
                }}
                src={trip?.customer?.avatar}
                alt=""
              />
            </Grid>
            <Grid item>
              <StyledTypography sx={{ color: colors.TrueGray[700] }}>
                {trip?.customer?.name}
              </StyledTypography>
            </Grid>
          </Grid>
        ),
      },
      {
        id: "itinerary.name",
        label: "Trip",
        width: 250,
        sortable: true,
        customRender: (trip) => (
          <span
            style={{
              fontSize: "16px",
              color: colors.Violet[700],
              textDecoration: "underline",
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={() => dispatch(openTripDetails({ id: trip.id }))}
          >
            {trip.itinerary.name}
          </span>
        ),
      },
      {
        id: "av.long_av_id",
        label: "AV #",
        sortable: true,
        width: 250,
        customRender: (trip) => (
          <StyledTypography sx={{ color: colors.TrueGray[700] }}>
            <b>
              {trip.av.long_av_id.substring(0, trip.av.long_av_id.indexOf("-"))}
            </b>
            {trip.av.long_av_id.substring(
              trip.av.long_av_id.indexOf("-"),
              trip.av.long_av_id.length
            )}
          </StyledTypography>
        ),
      },
      {
        id: "template.external_id",
        label: "Lane ID",
        sortable: true,
        width: 100,
        minWidth: 110,
        customRender: (trip) => (
          <StyledTypography>
            {trip.template?.external_id || "N/A"}
          </StyledTypography>
        ),
      },
      {
        id: "itinerary.id",
        label: "Trip status",
        customRender: (trip) => (
          <TripCell customerTimeZone={trip?.customer?.timezone} trip={trip} />
        ),
        minWidth: isTablet ? 200 : 350,
        width: isTablet ? 200 : 400,
        maxWidth: 400,
      },
      {
        id: "scheduled_date",
        label: "Trip date",
        sortable: true,
        width: 50,
        customRender: (trip) => (
          <StyledTypography>
            {dayjs(trip.scheduled_start)
              .tz(trip.customer?.timezone || "CST6CDT")
              .format("MM/DD/YYYY")}
          </StyledTypography>
        ),
      },
      {
        id: "scheduled_start",
        width: 200,
        label: (
          <Grid container sx={{ minWidth: "100px", maxWidth: 125 }}>
            <Grid item>
              {" "}
              <StyledTypography
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: "24px",
                  marginRight: 1,
                  marginTop: 0.5,
                  fontWeight: 700,
                }}
              >
                Start time
              </StyledTypography>
            </Grid>
            <Grid item>
              {" "}
              <Tooltip
                sx={{ marginTop: 0.5 }}
                title={`Trip start times are displayed in the timezone set for each customer and in your browser’s timezone.`}
              >
                <Icon>
                  <InfoIcon />
                </Icon>
              </Tooltip>
            </Grid>
          </Grid>
        ),
        customRender: (trip) => (
          <TripTimeCell
            customerTimeZone={trip?.customer?.timezone}
            trip={trip}
          />
        ),
      },
      {
        id: "id",
        label: "Actions",
        width: 50,
        customRender: (trip) => (
          <TripMenu
            key={trip.id}
            options={[
              {
                label: "View full trip",
                onClick: () => {
                  dispatch(openTripDetails({ id: trip.id }));
                },
              },
              {
                label: `Edit ${trip.customer?.name} trip`,
                onClick: () => {
                  setDefaultEditAvValue(true);
                  setEditId(trip.id);
                  setIsEditOpen(true);
                },
                disabled:
                  trip.status === TripStatus.COMPLETED ||
                  trip.status === TripStatus.CANCELLED ||
                  trip.status === TripStatus.TRACKING_TIMEOUT,
                disabledHoverText:
                  trip.status === TripStatus.COMPLETED
                    ? "You cannot edit a trip that has been completed."
                    : trip.status === TripStatus.CANCELLED
                    ? "You cannot edit a trip that has been cancelled."
                    : trip.status === TripStatus.TRACKING_TIMEOUT
                    ? "You cannot edit a trip that has timed out."
                    : undefined,
              },
              {
                label: "Copy trip URL",
                isCopyUrl: true,
                onClick: () => {
                  const copyUrl = `${window.location.protocol}//${
                    window.location.host
                  }${"/ops/trips/manage?view_trip="}${trip.id}`;
                  navigator.clipboard.writeText(copyUrl);
                },
              },
            ]}
          />
        ),
      },
    ] as Array<HeadCell<TripWithCustomer>>;

    // Only show the "Trip date" column when filtering by "All" trips.
    if (tab !== 3) {
      unfilteredColumns = unfilteredColumns.filter(
        (column) => column.id !== "scheduled_date"
      );
    }

    // // Removes the "Start time" column when on tablet.
    return isDesktop
      ? unfilteredColumns.filter((column) => column.id !== "scheduled_start")
      : unfilteredColumns;
  }, [dispatch, tab, isTablet, isDesktop]);

  // This is the manual refresh call. Still shows loading animation.
  const handleRefresh = () => {
    setIsRefreshing(true);
    setReloadNumber(reloadNumber + 1);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 200);
  };

  useEffect(() => {
    // check if there are view or edit trip url parameters
    setViewTripId(queryParameters.get("view_trip"));
    setEditTripId(queryParameters.get("edit_trip"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This auto-refreshes the trips every 60 seconds.
    const interval = setInterval(() => {
      setIsAutoRefreshing(true);
      setReloadNumber(reloadNumber + 1);
      setTimeout(() => {
        setIsAutoRefreshing(false);
      }, 1000);
    }, OPS_POLLING_DELAY_MS);

    //Clearing the interval to prevent memory leaks
    return () => clearInterval(interval);
  }, [reloadNumber]);

  const handleSort = (_: any, sortKey: TripKey) => {
    setOrderBy(sortKey);
    setOrder(order === "asc" || sortKey !== orderBy ? "desc" : "asc");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { loading } = useAsyncEffect(async () => {
    if (isAutoRefreshing) {
      setSkipLoadingAnimation(true);
    }
    // this ensures the API is not called until the correct date range is set
    if (!isDateFilterLoaded) {
      return;
    }
    // only reload trips when edit open is changed to false
    if (isEditOpen) {
      return;
    }
    const statuses = statusFilterValues.map((status) => status.toLowerCase());

    await Promise.all([
      dispatch(
        fetchTripsV2({
          text: tripSearchValue,
          statuses: statuses,
          page: page + 1,
          ordering: sort,
          size: rowsPerPage,
          customer_ids: customerFilterValues,
          av_ids: avFilterValues,
          external_ids: laneFilterValues,
          // if there's no DateFilter set, default to "All" tab
          start_date: startDateFilter
            ? startDateFilter?.replace(/"/g, "")
            : // TODO: Remove setHours and let backend handle setting the timezone
              JSON.stringify(
                // Default "All" tab to start Jan 1st, 2022
                new Date(2022, 0, 1)
              ).replace(/"/g, ""),
          end_date: endDateFilter
            ? endDateFilter.replace(/"/g, "")
            : JSON.stringify(
                // Default "All" tab to end Jan 1st, 2050
                new Date(2050, 0, 1)
              ).replace(/"/g, ""),
        })
      ),
    ]);

    // Get all unique customer IDs from the current list of AVs
    // const allAVCustomerIds = trips.map((trip) => trip.av.customer_id);

    // Get full customer object from each unique customer id
    const customers: Customer[] =
      // TODO: Filter customer search using allAVCustomerIds above to not load unneeded data.
      (await dispatch(fetchCustomers({}))).payload.results;

    // Map each customer object to the customer ID
    const newCustomerMap = new Map();
    customers.forEach((customer) => newCustomerMap.set(customer.id, customer));
    setCustomerMap(newCustomerMap);
    setSkipLoadingAnimation(false);
  }, [
    startDateFilter,
    endDateFilter,
    page,
    sort,
    tripSearchValue,
    statusFilterValues,
    isEditOpen,
    customerFilterValues,
    avFilterValues,
    laneFilterValues,
    reloadNumber,
    rowsPerPage,
  ]);

  useEffect(() => {
    setIsDebouncing(false);
  }, [
    tripSearchValue,
    statusFilterValues,
    customerFilterValues,
    avFilterValues,
    laneFilterValues,
  ]);

  const debounceSetValue = useDebouncedCallback((func, val) => func(val), 500);

  const fullTripArray: TripWithCustomer[] = useMemo(() => {
    // Returns AVs with customer names added
    return trips.map((trip) => ({
      ...trip,
      customer: customerMap.get(trip.av.customer_id) || "Unassigned",
    }));
  }, [trips, customerMap]);

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        margin: 12,
        marginRight: 0,
        borderRadius: isMobile ? "20px 0 0 20px" : "40px 0 0 40px",
        boxSizing: "content-box",
      }}
    >
      <TripEditDialog
        id={editId}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onCancel={() => setIsEditOpen(false)}
        defaultEditAvValue={defaultEditAvValue}
      />
      <TripCreateDialog
        // TODO: Remove this key. This is being used to manually reset the modal's form values on cancel/close. Ideally, we use formik.resetForm() from a parent level to reset the modal.
        key={createTripCounter}
        isOpen={isCreateOpen}
        onClose={() => {
          closeCreateModal();
          setCreateTripCounter(createTripCounter + 1);
        }}
      />
      <TripReportDialog
        open={isTripReportOpen}
        onClose={() => setTripReportOpen(false)}
      />
      <DateRangePickerDialog
        open={isDateRangePickerOpen}
        onClose={() => setIsDateRangePickerOpen(false)}
        handleDateValueChange={(startDate, endDate) => {
          const timezoneHourOffset = dayjs(startDate)
            .tz(userInfo.timezone)
            .hour();

          if (startDate) {
            // Subtract the timezone offset to show all trips occuring on the specified day in the set customer's timezone
            const dayjsStartDate = dayjs(startDate).subtract(
              timezoneHourOffset,
              "hours"
            );
            setStartDateFilter(dayjsStartDate.toJSON());
            setPlainTextStartDate(
              format(
                new Date(JSON.stringify(startDate)?.replace(/"/g, "")),
                "P"
              )
            );
          }
          if (endDate) {
            setPlainTextEndDate(
              format(new Date(JSON.stringify(endDate)?.replace(/"/g, "")), "P")
            );
            // Add one day (to get end of day) and subtract the timezone offset
            const dayjsStartDate = dayjs(endDate)
              .add(1, "day")
              .subtract(timezoneHourOffset, "hours");
            setEndDateFilter(dayjsStartDate.toJSON());
          }
        }}
      />
      <TripDrawer isOpsPortal />
      <Grid
        container
        direction="column"
        sx={{ padding: isMobile ? 4 : 7.5, paddingTop: isMobile ? 2 : 4 }}
      >
        <NotificationsBanner
          setEditId={setEditId}
          setEditOpen={setIsEditOpen}
          setDefaultEditAvValue={(value) => setDefaultEditAvValue(value)}
        />
        <StyledTypography sx={typeset.Header1} mb={2}>
          Manage Trips
        </StyledTypography>
        <StyledTypography
          sx={{ ...typeset.body, color: colors.Grey[500], mb: 2.5 }}
        >
          View, create, and edit trips across customers
        </StyledTypography>
        <Grid
          container
          mb={2}
          sx={{
            maxWidth: "100%",
            overflowX: "auto",
            "::-webkit-scrollbar": {
              WebkitAppearance: "none",
              height: "2px",
            },
          }}
        >
          <Grid
            container
            sx={{
              minWidth: "550px",
              borderBottom: `1px solid ${colors.TrueGray[500]}`,
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <StyledTabs
                labels={["Today", "Tomorrow", "Yesterday", "All"]}
                selectedTab={tab}
                selectedNewTab={(newTabNumber) => setTab(newTabNumber)}
              />
            </Grid>
            <Grid
              container
              sx={{ width: "fit-content" }}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item mr={1}>
                <Tooltip
                  sx={{ marginTop: 0.5 }}
                  title={`Last updated ${formatHourlyTime(
                    new Date(),
                    getTimezone()
                  )}
            ${getTimezoneString()}`}
                >
                  <div>
                    <RefreshButton
                      onClick={() => handleRefresh()}
                      isRefreshing={isRefreshing}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item>
                <TripAddButton
                  onCreate={() => setCreateOpen(true)}
                  onTripReport={() => setTripReportOpen(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledSearchField
          onChange={(e) => {
            setIsDebouncing(true);
            debounceSetValue(setTripSearchValue, e.target.value);
          }}
          onClearInput={() => {
            setTripSearchValue("");
          }}
          sx={{ maxWidth: 450 }}
          placeholder="Search by Customer, Trip, Stop, AV#, Lane ID"
        />
        <Grid container alignItems="center">
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              marginTop: 2,
              marginBottom: 2,
              flexWrap: "wrap",
            }}
          >
            <AvFilter
              isMulti={true}
              label={"AV Number"}
              onChangeMulti={(val) => {
                setIsDebouncing(true);
                setAVFilterValues(val.map((item) => item.value));
              }}
              borderRadius="20px"
            />
            <CustomerFilter
              isMulti={true}
              onChangeMulti={(val) => {
                setIsDebouncing(true);
                setCustomerFilterValues(val.map((item) => item.value));
              }}
              borderRadius="20px"
            />
            <LaneFilter
              isMulti={true}
              onChangeMulti={(val) => {
                setIsDebouncing(true);
                setLaneFilterValues(val.map((item) => item.value));
              }}
              isDisabled={customerFilterValues.length === 0}
              customerIds={customerFilterValues}
              borderRadius="20px"
            />
            <TripStatusFilter
              isMulti={true}
              onChangeMulti={(val) => {
                setIsDebouncing(true);
                setStatusFilterValues(val.map((item) => item.value));
              }}
              borderRadius="20px"
            />
            {tab === 3 && (
              <Grid
                sx={{
                  borderRadius: "20px",
                  border: `1px solid ${colors.BlueGray[500]}`,
                  backgroundColor: colors.BackgroundWhite,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "auto",
                }}
              >
                <Grid
                  sx={{
                    padding: "12.5px 14px",
                    display: "inline-block",
                    cursor: "pointer",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: colors.Grey[800],
                  }}
                  onClick={() => setIsDateRangePickerOpen(true)}
                >
                  {plainTextStartDate || plainTextEndDate
                    ? `${plainTextStartDate} - ${plainTextEndDate}`
                    : "Select date range"}
                </Grid>
                {startDateFilter && endDateFilter && (
                  <Grid
                    style={{
                      padding: "8.5px 14px 8.5px 0px",
                      cursor: "pointer",
                    }}
                    onClick={resetAllDateFilter}
                  >
                    <svg
                      style={{
                        width: "0.75rem",
                        height: "0.75rem",
                        cursor: "pointer",
                      }}
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 17L17 1"
                        stroke={colors.BLACK}
                        strokeWidth="1.4"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17 17L1 1"
                        stroke={colors.BLACK}
                        strokeWidth="1.4"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>

        {(loading && !skipLoadingAnimation) ||
        isDebouncing ||
        fullTripArray.length > 0 ? (
          <>
            {tab !== 3 && (
              <StyledTypography
                sx={{ ...typeset.BodySmall, color: colors.TrueGray[800] }}
                style={{ marginBottom: "5px" }}
              >
                {`This tab shows trips that start on ${searchedDatePlainText} in ${timeZoneText} timezone.`}
              </StyledTypography>
            )}
            <StyledTable
              onSort={handleSort}
              order={order}
              orderBy={orderBy}
              data={
                (loading && !skipLoadingAnimation) || isDebouncing
                  ? []
                  : fullTripArray
              }
              columns={columns}
              tablePagination={{
                count: tripsCount,
                rowsPerPageOptions: [10, 25, 50, 100],
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
                sx: { overflow: "hidden", minWidth: "300px" },
                labelRowsPerPage: isMobile ? "Rows" : "Rows per page",
              }}
              isLoading={(loading && !skipLoadingAnimation) || isDebouncing}
              containerProps={{
                sx: {
                  maxWidth: "1600px",
                  "::-webkit-scrollbar": {
                    WebkitAppearance: "none",
                    height: "2px",
                  },
                },
              }}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <StyledTypography align="center" sx={typeset.Header2Small}>
              There are no matches found for filter applied. Please try again.
            </StyledTypography>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default TripsManagement;
