import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./slices/auth.slice";
import TripReducer from "./slices/trip.slice";
import avReducer from "./slices/av.slice";
import snackbarReducer from "./slices/snackbar.slice";
import layoutReducer from "./slices/layout.slice";
import configReducer from "./slices/config.slice";
import customerReducer from "./slices/customers.slice";
import notificationsReducer from "./slices/notifications.slice";
import LogRocket from "logrocket";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    trips: TripReducer,
    avs: avReducer,
    snackbar: snackbarReducer,
    layout: layoutReducer,
    config: configReducer,
    customers: customerReducer,
    notifications: notificationsReducer,
  },
  middleware: (getMiddleware) =>
    getMiddleware().concat(LogRocket.reduxMiddleware()),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
