import { useAsyncEffect } from "../../util/util";
import { useAppDispatch } from "../../redux/hooks";
import { activate } from "../../redux/thunks/auth.thunk";
import { useNavigate, useParams } from "react-router";
import { openSnackbar } from "../../redux/slices/snackbar.slice";
import { routes } from "../../util/routes";
import { CircularProgress } from "@mui/material";

export interface ILoginVerifyProps {}

export default function LoginVerify(props: ILoginVerifyProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  useAsyncEffect(async () => {
    if (token) {
      const success = await dispatch(activate(token));
      const account_is_active = success.type === "auth/activate/rejected";
      if (success.payload && !account_is_active) {
        window.location.replace("/login/success?action=CREATED");
      } else if (account_is_active) {
        navigate(routes.loginRoutes.root);
      } else {
        dispatch(
          openSnackbar({
            severity: "error",
            message:
              "Account activation failed because the link has expired. Please re-create your account now to try again",
          }),
        );
        navigate(routes.loginRoutes.signUp(1));
      }
    }
  }, [token]);

  return <CircularProgress color="primary" />;
}
