import { addMinutes, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useEffect, useMemo, useState } from "react";
import {
  CombinedStop,
  Status,
  Stop,
  Trip,
  TripStatus,
} from "../types/trip.type";
import { useLocation } from "react-router";
import { useMediaQuery, useTheme } from "@mui/material";
import { store } from "../redux/store";
import { AxiosError } from "axios";
import { StopFormValues } from "../components/trips/TripCreateDialog";

export const useAsyncEffect = (func: () => Promise<any>, deps: Array<any>) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    setLoading(true);
    func()
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { loading, data };
};

export const formatHourlyTime = (date: Date, timezone?: string) => {
  const tz =
    timezone ||
    store.getState().auth.userInfo.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(utcToZonedTime(date, tz), "h:mm aaaaa'm'");
};

export const convertTimezone = (date: Date, timezone: string) =>
  utcToZonedTime(date, timezone);

export const formatDate = (date: Date) => {
  return format(date, "MM/dd/yyyy");
};

export const getTimezoneText = (timezone?: string) => {
  if (!timezone) {
    return "";
  } else {
    return new Date()
      .toLocaleTimeString("en-us", {
        timeZone: timezone,
        timeZoneName: "short",
      })
      .split(" ")[2];
  }
};

export const generateMapLink = (address: string) =>
  `https://www.google.com/maps/search/?api=1&query=${address}`;

export const createCombinedStops = (stops: Array<Stop>, trips: Array<Trip>) => {
  const newStops: Array<CombinedStop> = [];
  const EST_TIME_STOPS_DISPLAY = store.getState().config.EST_TIME_STOPS_DISPLAY;

  stops.forEach((stop) => {
    const trip = trips.find((trip) => trip.id === stop.trip_id);
    const stopIdx = trip?.itinerary.stops.findIndex(
      (tripStop) => tripStop.id === stop.id
    );
    if (!stopIdx) {
      newStops.push(stop);
      return;
    }
    if (
      trip?.status === TripStatus.SCHEDULED ||
      trip?.itinerary.stops[0].status !== Status.AV_DEPARTED
    ) {
      newStops.push({ ...stop, trip_status: TripStatus.SCHEDULED });
    } else if (stopIdx <= EST_TIME_STOPS_DISPLAY) {
      newStops.push({ ...stop, arriving: true });
    } else {
      if (
        // if previous stop has arrived or departed
        trip?.itinerary.stops[stopIdx - 1].status !== Status.AWAITING_AV ||
        // or the stop is upcoming and within the number of future ETAs to display based on the customer's setting
        trip.itinerary.stops
          .slice(Math.max(stopIdx - EST_TIME_STOPS_DISPLAY, 0), stopIdx)
          .some((stop) => stop.status === Status.AV_DEPARTED)
      ) {
        newStops.push({ ...stop, arriving: true });
      } else {
        newStops.push({ ...stop });
      }
    }
  });
  return newStops;
};

const sortByScheduledDate = (a: Stop, b: Stop) =>
  new Date(Date.parse(a.scheduled_arrival || "")).getTime() >
  new Date(Date.parse(b.scheduled_arrival || "")).getTime()
    ? 1
    : -1;
const sortByDepartureTime = (a: Stop, b: Stop) =>
  new Date(Date.parse(a.departed_at || "")).getTime() >
  new Date(Date.parse(b.departed_at || "")).getTime()
    ? 1
    : -1;

const sortByScheduledStart = (a: Trip, b: Trip) =>
  new Date(Date.parse(a.scheduled_start || "")).getTime() >
  new Date(Date.parse(b.scheduled_start || "")).getTime()
    ? 1
    : -1;

export const sortStops = (stops: Array<CombinedStop>) => {
  let newStops: Array<CombinedStop> = [];
  newStops = newStops.concat(
    stops.filter(
      (stop) =>
        stop.status === Status.AV_ARRIVED &&
        stop.trip_status !== TripStatus.COMPLETED
    )
  );
  newStops = newStops.concat(
    stops
      .filter(
        (stop) =>
          stop.status === Status.AWAITING_AV &&
          stop.trip_status !== TripStatus.SCHEDULED
      )
      .sort((a, b) => (a.ett > b.ett ? 1 : -1))
  );

  newStops = newStops.concat(
    stops
      .filter((stop) => stop.trip_status === TripStatus.SCHEDULED)
      .sort(sortByScheduledDate)
  );

  newStops = newStops.concat(
    stops
      .filter(
        (stop) =>
          stop.status === Status.AV_DEPARTED ||
          (stop.status === Status.AV_ARRIVED &&
            stop.trip_status === TripStatus.COMPLETED)
      )
      .sort(sortByDepartureTime)
  );
  newStops = newStops.concat(
    stops
      .filter(
        (stop) =>
          stop.status === Status.AWAITING_AV &&
          stop.trip_status !== TripStatus.SCHEDULED &&
          !Boolean(stop.eta)
      )
      .sort(sortByScheduledDate)
  );
  return newStops.filter((stop) => Boolean(stop.trip_id));
};

export const calculatePercentCompletion = (trip: Trip) => {
  return (
    trip.itinerary.stops
      .slice(1, -1)
      .filter(
        (stop) =>
          stop.status === Status.AV_ARRIVED ||
          stop.status === Status.AV_DEPARTED
      ).length / trip.itinerary.stops.slice(1, -1).length
  );
};

export const sortTrips = (trips: Trip[]) => {
  let newTripsArray: Trip[] = [];
  newTripsArray = newTripsArray.concat(
    trips
      .filter((trip) => trip.status === TripStatus.IN_PROGRESS)
      .sort((a, b) => {
        return calculatePercentCompletion(b) - calculatePercentCompletion(a);
      })
  );
  newTripsArray = newTripsArray.concat(
    trips
      .filter((trip) => trip.status === TripStatus.SCHEDULED)
      .sort(sortByScheduledStart)
  );
  newTripsArray = newTripsArray.concat(
    trips.filter((trip) => trip.status === TripStatus.COMPLETED)
  );
  newTripsArray = newTripsArray.concat(
    trips.filter(
      (trip) =>
        trip.status !== TripStatus.IN_PROGRESS &&
        trip.status !== TripStatus.SCHEDULED &&
        trip.status !== TripStatus.COMPLETED
    )
  );
  return newTripsArray;
};

export const simulateCall = (phoneNumber: string) =>
  window.open(`tel:${phoneNumber}`, "_self");

export const generateHighlight = (text: string, highlight?: string) => {
  if (!highlight || !text.toLowerCase().includes(highlight.toLowerCase())) {
    return <>{text}</>;
  }
  const highlightIndex = text.toLowerCase().indexOf(highlight.toLowerCase());
  const preString = text.substring(0, highlightIndex);
  const highlightString = text.substring(
    highlightIndex,
    highlightIndex + highlight.length
  );
  const postString = text.substring(highlightIndex + highlight.length);
  return (
    <>
      {preString}
      <span style={{ backgroundColor: "#f1e740" }}>{highlightString}</span>
      {postString}
    </>
  );
};

export const convertSet = (set: Set<string>, type: string) =>
  Array.from(set).map((item) => ({ value: item, type }));

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const usePortraitMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xs"));
};

export const useMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

export const usePortraitTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};

export const useTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("lg"));
};

export const useDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xl"));
};

export const parseErrorResponse = (e: AxiosError<any, any>) => {
  let errorMessage = "";
  Object.keys(e.response?.data).forEach((key: string) =>
    //@ts-expect-error
    e.response.data[key].forEach((msg: string) => {
      errorMessage += msg + "\n";
    })
  );
  return errorMessage;
};

export const resolvePath = (object: any, path: string, defaultValue: any) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

export const getTimezoneString = () =>
  new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formatTimezone = (tz: string) =>
  utcToZonedTime(new Date(), tz)
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

// TODO: This does not work! It will only return local time's timezone abbreviation.
export const LongTimezoneToShortTimezone = (longTz: string) => {
  const shortTz = new Intl.DateTimeFormat("en-US", {
    timeZone: longTz,
    timeZoneName: "short",
  })
    .formatToParts(new Date())
    .find((part) => part.type === "timeZoneName");
  if (shortTz) {
    return shortTz.value;
  }
};

export const scrollToTop = (topOffset?: number) => {
  window.scrollTo({
    top: topOffset || 0,
    left: 0,
    behavior: "smooth",
  });
};

export const onArrayChange = (
  val: any,
  stateFunc: React.Dispatch<React.SetStateAction<any>>
) =>
  stateFunc((state: any) =>
    state.includes(val)
      ? state.slice().filter((item: any) => item !== val)
      : state.concat(val)
  );

export const replaceArrayObj = <T extends Record<string, any>>(
  key: keyof T,
  arr: T[],
  obj: T
) => {
  const newArray = [...arr];
  const objIdx = arr.findIndex((item) => item[key] === obj[key]);
  newArray[objIdx] = obj;
  return newArray;
};

export const replaceObj = <T extends Record<string, any>>(
  idx: number,
  arr: T[],
  obj: T
) => {
  const newArray = [...arr];
  newArray[idx] = obj;
  return newArray;
};

export const calculateStartTimes = ({
  scheduled_start,
  stops,
}: StopFormValues) => {
  if (!scheduled_start) {
    return [];
  }
  const times: Date[] = new Array(stops.length).fill(scheduled_start);
  for (let i = 0; i < stops.length; i++) {
    if (i === 0) {
      times[i] = scheduled_start;
    } else {
      times[i] = addMinutes(
        times[i - 1],
        (stops[i - 1].service_time || 0) + (stops[i].travel_time || 0)
      );
    }
  }
  return times;
};

export const calculateDepartureTimes = ({
  scheduled_start,
  stops,
}: StopFormValues) => {
  if (!scheduled_start) {
    return [];
  }
  const times: Date[] = new Array(stops.length).fill(scheduled_start);
  for (let i = 0; i < stops.length; i++) {
    if (i === 0) {
      times[i] = scheduled_start;
    } else {
      times[i] = addMinutes(
        times[i - 1],
        (stops[i - 1].service_time || 0) +
          (stops[i].travel_time || 0) +
          (stops[i].service_time || 0)
      );
    }
  }
  return times;
};

export const checkStaffUser = (role: string) =>
  role === "superuser" || role === "staff";

const swapElements = (arr: Array<any>, pos1: number, pos2: number) => {
  const temp = arr[pos1];

  arr[pos1] = arr[pos2];

  arr[pos2] = temp;

  return arr;
};

export const handleMove =
  <T extends Record<string, any>>(
    setFunc: (x: Array<T>) => void,
    items: Array<T>
  ) =>
  (dragIndex: number, hoverIndex: number) => {
    if (!dragIndex || !hoverIndex) {
      return;
    }
    setFunc(swapElements([...items], dragIndex, hoverIndex));
  };

interface ConditionalWrapperProps {
  condition?: boolean;
  wrapper: any;
  children: React.ReactNode;
}

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): JSX.Element => {
  return condition ? wrapper(children) : children;
};
