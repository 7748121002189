import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  EST_TIME_STOPS_DISPLAY: 2,
  MANUAL_BACKUP: "off",
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setStopsDisplay: (state, action: PayloadAction<number>) => {
      state.EST_TIME_STOPS_DISPLAY = action.payload;
    },
    setManualBackup: (state, action: PayloadAction<string>) => {
      state.MANUAL_BACKUP = action.payload;
    },
  },
});

export const { setStopsDisplay, setManualBackup } = configSlice.actions;

export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
