export enum NotificationType {
    AV_UNASSIGNED = "AV_UNASSIGNED",
  }

export interface Notification {
    type: NotificationType;
    extra: {
        trip_id: string,
        customer_id: string,
        itinerary_name: string,
        scheduled_start: string,
    }
  }