import fleetAxios from "./axios";

export const fetchSites = async (customer_id: string, size?: number) => {
  return fleetAxios
    .get("/v1/sites", { params: { customer_id, size } })
    .then((resp) => resp.data.results);
};

export const fetchSite = async (siteId: string) => {
  return fleetAxios.get(`/v1/sites/${siteId}`).then((resp) => resp.data);
};
