import { useState, useMemo } from "react";
import {
  Menu,
  MenuItem,
  Icon,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import StyledButton from "../UI/StyledButton";
import { ReactComponent as WhitePlus } from "../../assets/white_plus.svg";
import { ReactComponent as CreateTrip } from "../../assets/create_trip.svg";
import { ReactComponent as CreateTripReport } from "../../assets/create_trip_report.svg";

export interface TripAddButtonProps {
  onCreate: () => void;
  onTripReport: () => void;
}

const TripAddButton = ({ onCreate, onTripReport }: TripAddButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = useMemo(() => {
    return [
      {
        label: "Create trip",
        onClick: onCreate,
        icon: (
          <Icon>
            <CreateTrip />
          </Icon>
        ),
      },
      {
        label: "Create trip report",
        onClick: onTripReport,
        icon: (
          <Icon>
            <CreateTripReport />
          </Icon>
        ),
      },
    ];
  }, [onCreate, onTripReport]);

  const open = Boolean(anchorEl);

  return (
    <>
      <StyledButton variant="contained" size="icon" onClick={handleClick}>
        <WhitePlus />
      </StyledButton>
      <Menu
        id="trip-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "trip-button",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            <ListItemIcon> {option.icon}</ListItemIcon>
            <ListItemText> {option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TripAddButton;
