export const BLACK = "#0C081A";
export const WHITE = "#FFFFFF";
export const Black = { "900": "#111827", "500": "#6B7280" };
export const TrueGray = {
  "50": "#FAFAFA",
  "100": "#F5F5F5",
  "200": "#E5E5E5",
  "300": "#D4D4D4",
  "500": "#64748B",
  "700": "#404040",
  "800": "#262626",
  "900": "#171717",
};
export const Grey = {
  "100": "#f5f5f4",
  "200": "#E2E8F0",
  "400": "#94a3b8",
  "500": "#71717A",
  "700": "#334155",
  "800": "#292524",
};
export const Green = { "400": "#4ADE80" };
export const Neutral = { "500": "#6B7280" };
export const Emerald = { "600": "#059669", "400": "#34d399", "200": "#a7f3d0" };
export const Violet = {
  "50": "#F5F3FF",
  "100": "#EEE9FB",
  "200": "#DED3F8",
  "300": "#CDBCF4",
  "500": "#9b7ae9",
  "600": "#7a4de2",
  "400": "#BDA6F1",
  "700": "#5921db",
  "900": "#351483",
};
export const Indigo = { "600": "#4f46e5" };
export const Orange = { "500": "#F97316" };
export const Rose = { "500": "#be063f" };
export const Red = {
  "50": "#fef3f3",
  "100": "#fee4e2",
  "200": "#fecdca",
  "500": "#EF4444",
  "700": "#B42318",
};
export const PURPLE = "#5921DB";
export const BlueGray = {
  "100": "#F8FAFC",
  "200": "#E2E8F0",
  "300": "#CBD5E1",
  "400": "#94A3B8",
  "500": "#64748B",
  "600": "#475569",
  "700": "#334155",
  "800": "#1E293B",
  "900": "#0F172A",
};
export const Blue = { "100": "#DBEAFE", "700": "#1D4ED8", "500": "#3B82F6" };
export const BackgroundWhite = "#FAFAFA";
