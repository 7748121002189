import LoginButtonSplash from "../../components/authentication/LoginButtonSplash";
import { ReactComponent as InboxIcon } from "../../assets/inbox.svg";
import { useQuery } from "../../util/util";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUserInfo } from "../../redux/slices/auth.slice";
import { resetPassword, signUp } from "../../redux/thunks/auth.thunk";

const LoginAccountCheck = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const values = useAppSelector(getUserInfo);
  return (
    <LoginButtonSplash
      buttonText="Resend the email"
      buttonVariant="outlined"
      onClick={async () => {
        if (query.get("action") === "RESET") {
          await dispatch(resetPassword(values.email));
        } else {
          await dispatch(
            signUp({
              email: values.email,
              password: values.password,
              confirm_password: values.password,
              first_name: values.firstName,
              last_name: values.lastName,
              profile: values.profile,
            }),
          );
        }
      }}
      icon={<InboxIcon />}
      title="Check your inbox"
      subTitle={
        query.get("action") === "RESET"
          ? "If there is an account registered to your email address, we are sending you an email with a link to reset your password."
          : "We’ve sent you an email. Please follow the instructions to access your account."
      }
    />
  );
};
export default LoginAccountCheck;
