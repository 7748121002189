import { Button, ButtonProps } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import { useFormikContext } from "formik";

export type IStyledButtonProps = Omit<ButtonProps, "size"> & {
  size: "large" | "medium" | "small" | "x-small" | "icon";
  buttonStyle?: "rounded" | "rectangle";
};

export default function StyledButton(props: IStyledButtonProps) {
  const formik = useFormikContext();
  const {
    disabled,
    size = "large",
    onClick,
    sx,
    buttonStyle = "rounded",
    ...otherProps
  } = props;

  let styles: CSSProperties = {};

  switch (size) {
    case "large":
      styles = {
        borderRadius: 25,
        height: 61,

        fontSize: "22px",
      };
      break;
    case "medium":
      styles = { borderRadius: 25, height: 56 };
      break;
    case "small":
      styles = { borderRadius: 25, height: 44 };
      break;
    case "x-small":
      styles = { borderRadius: 25, height: 40 };
      break;
    case "icon":
      styles = { borderRadius: 25, height: 40 };
      break;
    default:
      break;
  }
  if (buttonStyle === "rectangle") {
    styles = Object.assign(styles, { borderRadius: "8px" });
  }
  return (
    <Button
      {...otherProps}
      onClick={
        props.type === "submit"
          ? (e) => {
              if (formik) formik.submitForm();
              if (onClick) onClick(e);
            }
          : onClick
      }
      sx={Object.assign(styles, sx)}
      disabled={
        disabled !== undefined && props.type === "submit"
          ? !formik?.isValid || disabled
          : disabled
      }
    />
  );
}
