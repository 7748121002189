import { Button, ButtonProps } from "@mui/material";
import { ReactComponent as BackIcon } from "../../assets/back_arrow.svg";
export interface IStyledBackButtonProps extends ButtonProps {}

export default function StyledBackButton(props: IStyledBackButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        width: 32,
        height: 32,
        minWidth: 0,
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
        },
      }}
    >
      <BackIcon style={{ scale: "1.5" }} />
    </Button>
  );
}
