import { Grid } from "@mui/material";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import { Trip } from "../../types/trip.type";
import {
  LongTimezoneToShortTimezone,
  formatHourlyTime,
  formatTimezone,
  getTimezone,
} from "../../util/util";
import { useMemo } from "react";

export interface TripTimeCellProps {
  trip: Trip;
  customerTimeZone?: string;
}

const TripTimeCell = ({ trip, customerTimeZone }: TripTimeCellProps) => {
  const scheduled_departure = trip.itinerary.stops[0].scheduled_departure;
  const departed_at = trip.itinerary.stops[0].departed_at;
  const startDate = useMemo(
    () =>
      scheduled_departure ? new Date(Date.parse(scheduled_departure)) : null,
    [scheduled_departure]
  );
  const actualStartDate = useMemo(
    () => (departed_at ? new Date(Date.parse(departed_at)) : null),
    [departed_at]
  );
  return (
    <Grid container direction="column">
      <Grid item>
        <StyledTypography sx={typeset.BodySmall}>Scheduled</StyledTypography>
      </Grid>
      <Grid item>
        <StyledTypography>
          {startDate
            ? `${formatHourlyTime(startDate, customerTimeZone)} ${
                customerTimeZone &&
                LongTimezoneToShortTimezone(customerTimeZone)
              }`
            : "--"}
        </StyledTypography>
      </Grid>
      {startDate && (
        <Grid item sx={{ mb: 1 }}>
          <StyledTypography sx={typeset.BodySmall}>
            {formatHourlyTime(startDate, getTimezone())}{" "}
            {customerTimeZone && formatTimezone("")}
          </StyledTypography>
        </Grid>
      )}
      <Grid item>
        <StyledTypography sx={typeset.BodySmall}>Actual</StyledTypography>
      </Grid>
      <Grid item>
        <StyledTypography>
          {actualStartDate
            ? `${formatHourlyTime(actualStartDate, customerTimeZone)} ${
                customerTimeZone &&
                LongTimezoneToShortTimezone(customerTimeZone)
              }`
            : "--"}
        </StyledTypography>
      </Grid>
      {actualStartDate && (
        <Grid item sx={{ mb: 1 }}>
          <StyledTypography sx={typeset.BodySmall}>
            {formatHourlyTime(actualStartDate, getTimezone())}{" "}
            {customerTimeZone && formatTimezone("")}
          </StyledTypography>
        </Grid>
      )}
    </Grid>
  );
};

export default TripTimeCell;
