import { useMemo, useState } from "react";
import StyledFilter, { AutoCompleteProps } from "./StyledFilter";
import { useAsyncEffect } from "../../util/util";
import { useAppDispatch } from "../../redux/hooks";
import { fetchAvs } from "../../redux/thunks/av.thunk";
import { AV } from "../../types/av.type";
import { FILTER_ITEMS_TO_RETURN } from "../../util/constants";

export interface AvFilterProps
  extends Omit<AutoCompleteProps<string, AV>, "options"> {
  customerId?: string;
  isDisabled?: boolean;
}

const AvFilter = (props: AvFilterProps) => {
  const { customerId, isDisabled, ...otherProps } = props;
  const dispatch = useAppDispatch();
  const [avs, setAvs] = useState<any[]>([]);

  const { loading } = useAsyncEffect(async () => {
    const newAvs = (
      await dispatch(
        fetchAvs(
          customerId
            ? { customer_ids: [customerId], size: FILTER_ITEMS_TO_RETURN }
            : { size: FILTER_ITEMS_TO_RETURN }
        )
      )
    ).payload?.results;
    setAvs(newAvs);
  }, [customerId]);

  const options = useMemo(
    () =>
      avs.map((av) => ({
        label: av.long_av_id,
        value: av.id,
        data: av,
      })),
    [avs]
  );
  return (
    <StyledFilter
      {...otherProps}
      label={"AV #"}
      options={options}
      isLoading={loading}
      isDisabled={isDisabled}
      noResultsText={"Cannot find the specified AV"}
    />
  );
};

export default AvFilter;
