import * as React from "react";
import Stack from "@mui/material/Stack";
import { Link } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { getNotifications } from "../../redux/slices/notifications.slice";
import { NotificationType } from "../../types/notifications";
import AVUnassignedNotification from "../notifications/AVUnassignedNotification";

export interface INotificationsBannerProps {
  setEditId: React.Dispatch<React.SetStateAction<string>>;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultEditAvValue?: (value: boolean) => void;
}

export default function NotificationsBanner(props: INotificationsBannerProps) {
  const MAX_NOTIFICATIONS_DEFAULT_DISPLAY = 1;
  const [maxNotifications, setMaxNotifications] = React.useState(
    MAX_NOTIFICATIONS_DEFAULT_DISPLAY
  );
  const [showMoreClicked, setShowMoreClicked] = React.useState(false);
  const { notifications, count } = useAppSelector(getNotifications);

  const handleClick = () => {
    if (showMoreClicked) {
      setMaxNotifications(MAX_NOTIFICATIONS_DEFAULT_DISPLAY);
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
      setMaxNotifications(count);
    }
  };

  const remainingNotifications = count - MAX_NOTIFICATIONS_DEFAULT_DISPLAY;

  return (
    <Stack sx={{ width: "100%" }}>
      {notifications
        .slice(0, maxNotifications)
        .map((notification, index) =>
          notification.type === NotificationType.AV_UNASSIGNED ? (
            <AVUnassignedNotification
              index={index}
              scheduledStart={notification.extra.scheduled_start}
              itineraryName={notification.extra.itinerary_name}
              tripId={notification.extra.trip_id}
              setEditId={props.setEditId}
              setEditOpen={props.setEditOpen}
              key={index}
              setDefaultEditAvValue={props.setDefaultEditAvValue}
              shouldLinkTripName
            />
          ) : null
        )}
      {MAX_NOTIFICATIONS_DEFAULT_DISPLAY < count ? (
        <Link
          sx={{ fontWeight: 700, marginTop: "-10px", marginBottom: "6px" }}
          color={"#DC2626"}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          {!showMoreClicked
            ? `Show ${remainingNotifications} more ${
                remainingNotifications === 1 ? "notification" : "notifications"
              }`
            : "Show less"}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            style={{
              transform: `rotate(${
                !showMoreClicked ? "90deg" : "-90deg"
              }) translate(${!showMoreClicked ? "10px" : "-10px"})`,
              width: "30px",
              height: "30px",
            }}
          >
            <path
              style={{ stroke: "currentColor" }}
              d="M10 16.2402L14 12.2402L10 8.24023"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Link>
      ) : null}
    </Stack>
  );
}
