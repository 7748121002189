import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PagingResults } from "../../types/util.type";
import { Customer } from "../../types/customer.type";
import { RootState } from "../store";

export type CustomersState = {
  customers: Array<Customer>;
};

const initialState: CustomersState = {
  customers: [],
};

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<PagingResults<Customer>>) => {
      state.customers = action.payload.results;
    },
  },
});

export const { setCustomers } = customerSlice.actions;
export const getCustomers = (state: RootState) => state.customers.customers;
export default customerSlice.reducer;
