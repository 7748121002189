import { createAsyncThunk } from "@reduxjs/toolkit";
import * as avApi from "../../api/av.api";
import { setAVs, setLatestRequestId } from "../slices/av.slice";
import { AVSortMethods } from "../../api/av.api";
import { RootState } from "../store";

export const fetchAvs = createAsyncThunk(
  "avs/fetchAvs",
  async (
    params: {
      text?: string;
      customer_ids?: string[];
      status?: avApi.AVStatuses;
      page?: number;
      ordering?: AVSortMethods;
      size?: number;
    },
    { dispatch, getState, requestId }
  ) => {
    dispatch(setLatestRequestId(requestId));
    const avs = await avApi.fetchAvs(
      params.text,
      params.customer_ids,
      params.status,
      params.page,
      params.ordering,
      params.size
    );
    let latestRequestId = (getState() as RootState).avs.requestId;
    if (requestId === latestRequestId) {
      dispatch(setAVs(avs));
    }
    return avs;
  }
);
