import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getAuthTokens } from "../redux/slices/auth.slice";
import { refreshLogin } from "../redux/thunks/auth.thunk";
import { Navigate, Outlet } from "react-router-dom";
import { routes } from "./routes";
import { useAsyncEffect } from "./util";

export const useAuth = () => {
  const authData = useAppSelector(getAuthTokens);
  return authData;
};

//Build

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAsyncEffect(async () => {
    const refresh_token = localStorage.getItem("refresh_token");
    if (refresh_token) {
      await dispatch(refreshLogin(refresh_token));
    }
  }, [dispatch]);
  return loading;
};

export interface IProtectedRouteProps {
  scopes?: Array<string>;
  redirectPath?: string;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
  const { isAuthenticated } = useAuth();
  const { redirectPath } = props;

  if (!isAuthenticated) {
    return <Navigate to={redirectPath || routes.redirectPath} replace />;
  }

  return <Outlet />;
};
