import { useAsyncEffect, useMobile } from "../../util/util";
import { useAppSelector } from "../../redux/hooks";
import { Alert, Button, Grid, useTheme } from "@mui/material";
import { getCustomerSettings } from "../../api/config.api";
import { useState } from "react";
import { CustomSetting } from "./TrackingAdminTools";
import { getTripFilter } from "../../redux/slices/trip.slice";

let fetchInterval = setInterval(() => {}, 10000);

export interface ManualTrackingAlertProps {
  isOpsPortal?: boolean;
}

const ManualTrackingAlert = (props: ManualTrackingAlertProps) => {
  const theme = useTheme();
  const tripFilter = useAppSelector(getTripFilter);

  const [isManualBackupEnabled, setIsManualBackupEnabled] = useState<
    undefined | "on" | "off" | "disabled"
  >(undefined);
  const [manualBackupUrl, setManualBackupUrl] = useState<string | undefined>(
    undefined
  );
  const isMobile = useMobile();

  const getAndSetManualBackupSetting = (customerId: string | undefined) => {
    getCustomerSettings(customerId).then((resp) => {
      const manualBackupToggleSetting = resp.results?.filter(
        (setting: CustomSetting) =>
          setting.name.toLowerCase() === "manual_backup"
      )[0]?.value;
      setIsManualBackupEnabled(manualBackupToggleSetting);

      const manualBackupUrlSetting = resp.results?.filter(
        (setting: CustomSetting) =>
          setting.name.toLowerCase() === "manual_backup_url"
      )[0]?.value;
      setManualBackupUrl(manualBackupUrlSetting);
    });
  };
  useAsyncEffect(async () => {
    clearInterval(fetchInterval);
    getAndSetManualBackupSetting(tripFilter?.customer_id);
    fetchInterval = setInterval(() => {
      getAndSetManualBackupSetting(tripFilter?.customer_id);
    }, 60000 * 1);
  }, [tripFilter]);

  return (
    <>
      {isManualBackupEnabled === "on" &&
        !(props.isOpsPortal && !tripFilter?.customer_id) && (
          <Alert
            severity="error"
            sx={{
              width: "100%",
              borderRadius: "10px",
              boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.40)",
              marginBottom: "48px",
              justifyContent: "space-between",
              "& .MuiAlert-message": { width: "calc(100% - 65px)" },
            }}
            icon={
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5 3.875V3.875C21.9209 3.875 27.125 9.07912 27.125 15.5V15.5C27.125 21.9209 21.9209 27.125 15.5 27.125V27.125C9.07912 27.125 3.875 21.9209 3.875 15.5V15.5C3.875 9.07912 9.07912 3.875 15.5 3.875Z"
                  stroke="white"
                  strokeWidth="1.9375"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 16.1458V9.6875"
                  stroke="white"
                  strokeWidth="1.9375"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4987 20.6667C15.3204 20.6667 15.1758 20.8113 15.177 20.9896C15.177 21.1678 15.3217 21.3125 15.5 21.3125C15.6782 21.3125 15.8229 21.1678 15.8229 20.9896C15.8229 20.8113 15.6782 20.6667 15.4987 20.6667"
                  stroke="white"
                  strokeWidth="1.9375"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <Grid
              container
              direction={isMobile ? "column" : "row"}
              flexWrap="nowrap"
              justifyContent="space-between"
              alignItems={isMobile ? "flex-end" : "center"}
              sx={{ minHeight: "30px" }}
            >
              <Grid item>
                <b>ALERT:</b> ETAs and arrival/departure timestamps may be
                inaccurate right now.{" "}
                {manualBackupUrl &&
                  "Please check the backup timetable for accurate times."}
              </Grid>
              {manualBackupUrl && (
                <Grid item>
                  <Button
                    onClick={() => {
                      window.open(`${manualBackupUrl}`, "_newtab");
                    }}
                    size="medium"
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: theme.palette.error.main,
                      "&:hover": {
                        backgroundColor: "white",
                      },
                      marginLeft: isMobile ? undefined : "16px",
                      marginTop: isMobile ? "8px" : undefined,
                      whiteSpace: "nowrap",
                    }}
                  >
                    View backup timetable
                  </Button>
                </Grid>
              )}
            </Grid>
          </Alert>
        )}
    </>
  );
};

export default ManualTrackingAlert;
