import StyledFilter, { AutoCompleteProps } from "../UI/StyledFilter";
import { TripStatus } from "../../types/trip.type";

export type TripTableFiltersProps = Omit<
  AutoCompleteProps<string>,
  "options" | "label"
>;

const options = [
  { label: "Upcoming", value: TripStatus.SCHEDULED },
  { label: "In Progress", value: TripStatus.IN_PROGRESS },
  { label: "Completed", value: TripStatus.COMPLETED },
  { label: "Cancelled", value: TripStatus.CANCELLED },
  { label: "Timed Out", value: TripStatus.TRACKING_TIMEOUT },
];

const TripStatusFilter = (props: TripTableFiltersProps) => {
  const { ...otherProps } = props;
  return <StyledFilter {...otherProps} label="Trip status" options={options} />;
};

export default TripStatusFilter;
