import { Grid, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { ReactComponent as LoginHeaderIcon } from "../../assets/gatik_omni.svg";
import StyledButton from "../../components/UI/StyledButton";
import StyledTypography from "../../components/UI/StyledTypography";
import * as typeset from "../../util/typeset";
import { routes } from "../../util/routes";
import LoginFooter from "../../components/authentication/LoginFooter";
import LandingLogo from "../../assets/landing_logo.png";
import { useMobile } from "../../util/util";

export interface ILoginLandingProps {}

export default function LoginLanding(props: ILoginLandingProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMobile();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      px={isMobile ? theme.spacing(2) : theme.spacing(4)}
      marginTop={isMobile ? "20px" : "30px"}
    >
      <Grid item my={2}>
        <LoginHeaderIcon style={{ width: "100%", maxWidth: "300px", height: "auto" }} />
      </Grid>
      <Grid item textAlign="center" my={2}>
        <img
          src={LandingLogo}
          style={{ width: "80%", maxWidth: isMobile ? "150px" : "300px", height: "auto", }}
          alt="Gatik logo"
        />
      </Grid>
      <Grid item my={2}>
        <StyledTypography
          align="center"
          sx={{...typeset.Header1Small, marginBottom: "1rem"}}
        >
          Welcome!
        </StyledTypography>
        <StyledTypography align="center" sx={typeset.body}>
          Let's get started by creating your account
        </StyledTypography>
      </Grid>
      <Grid
        item
      >
        <StyledButton
          color="primary"
          variant="contained"
          size={isMobile ? "small" : "large"}
          fullWidth
          onClick={() => navigate(routes.loginRoutes.signUp(1))}
        >
          Get Started
        </StyledButton>
      </Grid>
      <LoginFooter />
    </Grid>
  );
}
