import { formatHourlyTime } from "../../util/util";
import StyledTypography from "../UI/StyledTypography";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import {
  Grid,
  Divider,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import {
  SequenceStatus,
  Status,
  Trip,
  TripStatus,
} from "../../types/trip.type";
import { useMemo } from "react";

export interface TripProgressProps {
  trip: Trip;
  customerTimeZone?: string;
}
const TripProgess = (props: TripProgressProps) => {
  const { trip, customerTimeZone } = props;
  const totalStops = useMemo(
    () => trip.itinerary.stops.length - 2,
    [trip.itinerary.stops]
  );

  const stopOne = () => {
    // if using V2 API
    if (nextStop && nextStop.status === Status.AV_ARRIVED && nextStop?.site) {
      return nextStop.site.properties.name;
    } else if (currentStop && currentStop?.site) {
      return currentStop.site.properties.name;
    }
    // if using V1 API
    else if (
      nextStop &&
      nextStop.status === Status.AV_ARRIVED &&
      nextStop?.stop_info
    ) {
      return nextStop.stop_info.name;
    } else if (currentStop?.stop_info) {
      return currentStop.stop_info.name;
    }
    return null;
  };

  const stopTwo = () => {
    // if using V2 API
    if (
      nextNextStop?.site &&
      (nextNextStop.status === Status.AV_ARRIVED ||
        (nextStop && nextStop.status === Status.AV_ARRIVED))
    ) {
      return nextNextStop.site.properties.name;
    } else if (nextStop && nextStop.site) {
      return nextStop.site.properties.name;
    }
    // if using V1 API
    else if (
      nextNextStop?.stop_info &&
      ((nextNextStop.status === Status.AV_ARRIVED && nextNextStop) ||
        nextStop?.status === Status.AV_ARRIVED)
    ) {
      return nextNextStop.stop_info.name;
    } else if (nextStop?.stop_info) {
      return nextStop.stop_info.name;
    }
    return null;
  };

  const {
    currentStop,
    nextStop,
    nextNextStop,
    isOrigin,
    isFinalDestination,
    stopIdx,
  } = useMemo(() => {
    let currentStop = undefined;
    let nextStop = undefined;
    let isOrigin = false;
    let isFinalDestination = false;
    let nextStopIdx = trip.itinerary.stops.findIndex(
      (stop) =>
        stop.status === Status.AWAITING_AV &&
        stop.sequence_status !== SequenceStatus.SKIPPED
    );
    if (nextStopIdx !== 0 && nextStopIdx !== -1) {
      const hasDeparted =
        trip.itinerary.stops[nextStopIdx - 1].status === Status.AV_DEPARTED;
      if (!hasDeparted) {
        nextStopIdx = nextStopIdx - 1;
      }
    }
    if (nextStopIdx === -1) {
      currentStop = trip.itinerary.stops[trip.itinerary.stops.length - 1];
      isFinalDestination = true;
    } else if (nextStopIdx === 0 || nextStopIdx === 1) {
      currentStop = trip.itinerary.stops[0];
      if (trip.itinerary.stops[0].status === Status.AV_DEPARTED) {
        nextStop = trip.itinerary.stops[1];
        isOrigin = false;
      } else {
        nextStop = trip.itinerary.stops[1];
        isOrigin = true;
      }
    } else {
      currentStop = trip.itinerary.stops[nextStopIdx - 1];
      nextStop = trip.itinerary.stops[nextStopIdx];
    }

    return {
      currentStop,
      nextStop,
      nextNextStop:
        nextStop?.status === Status.AV_ARRIVED &&
        nextStopIdx + 1 !== trip.itinerary.stops.length
          ? trip.itinerary.stops[nextStopIdx + 1]
          : undefined,
      isOrigin,
      isFinalDestination,
      stopIdx: isOrigin
        ? 0
        : isFinalDestination
        ? totalStops
        : (nextStopIdx > totalStops ? totalStops : nextStopIdx) -
          1 +
          (trip.itinerary.stops.length > 2 &&
          trip.itinerary.stops[trip.itinerary.stops.length - 2].status ===
            Status.AV_DEPARTED &&
          trip.itinerary.stops[trip.itinerary.stops.length - 1].status ===
            Status.AWAITING_AV
            ? 0.75 //This is not ideal and just a hack to bump the progress up for second to last stop
            : 0) +
          (trip.itinerary.stops[0].status === Status.AV_DEPARTED &&
          (trip.itinerary.stops[1].status === Status.AWAITING_AV ||
            trip.itinerary.stops[1].status === Status.AV_ARRIVED)
            ? 0.25
            : 0),
    };
  }, [totalStops, trip.itinerary.stops]);
  const completedStops = useMemo(() => {
    return trip.itinerary.stops
      .slice(1, -1)
      .filter((stop) => stop.status === Status.AV_DEPARTED).length;
  }, [trip]);

  return (
    <>
      <Grid item sx={{ marginTop: 2.5, marginBottom: 1.5 }}>
        <LinearProgress
          variant="determinate"
          value={Math.floor((stopIdx / totalStops) * 100)}
          sx={{
            height: 8,
            borderRadius: 8,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor:
                trip.status === TripStatus.CANCELLED
                  ? colors.BlueGray[100]
                  : colors.BlueGray[200],
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 8,
            },
          }}
        />
      </Grid>
      <Grid item sx={{ marginBottom: 3 }}>
        <StyledTypography
          sx={{ ...typeset.Caption1, color: colors.BlueGray[600] }}
        >
          {trip.status === TripStatus.TRACKING_TIMEOUT
            ? "Trip has timed out"
            : trip.status === TripStatus.CANCELLED
            ? "Trip cancelled"
            : isOrigin
            ? "Trip not yet started"
            : `${completedStops} of ${totalStops} stops completed`}
        </StyledTypography>
      </Grid>
      <div style={{ opacity: isOrigin ? 0.5 : 1 }}>
        <Grid container alignItems="center" sx={{ flexWrap: "nowrap" }}>
          <Grid
            item
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: colors.Violet[700],
              marginRight: 1.5,
            }}
          />
          <Grid
            item
            sx={{
              minWidth: isOrigin
                ? "100px"
                : isFinalDestination
                ? "150px"
                : "115px",
            }}
          >
            <StyledTypography>
              {isOrigin
                ? "At origin"
                : isFinalDestination
                ? "At final destination"
                : nextStop?.status === Status.AV_ARRIVED
                ? "Arrived at"
                : "Departed from"}
            </StyledTypography>
          </Grid>
          <Grid item>
            <StyledTypography>
              <b>{stopOne()}</b>
            </StyledTypography>
          </Grid>
        </Grid>
        {nextStop && trip.status !== TripStatus.CANCELLED && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <Grid container alignItems="flex-start" flexWrap={"nowrap"}>
              <Grid
                item
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: colors.Emerald[600],
                  marginRight: 1.5,
                  marginTop: 1,
                }}
              />
              <Grid item>
                <StyledTypography sx={{ whiteSpace: "nowrap" }}>
                  Next stop is{" "}
                </StyledTypography>
              </Grid>
              <Grid item>
                <StyledTypography>
                  <span style={{ display: "block", marginLeft: 12 }}>
                    <b>{stopTwo()}</b>
                  </span>
                </StyledTypography>
              </Grid>
            </Grid>
            {currentStop.status === Status.AV_DEPARTED &&
              nextStop.eta &&
              nextStop.status !== Status.AV_ARRIVED && (
                <Grid container wrap="nowrap" alignItems="center" ml={2.5}>
                  <Grid item>
                    <StyledTypography
                      sx={{ fontWeight: 400, color: colors.Grey[400] }}
                      mr={0.5}
                    >
                      ETA
                    </StyledTypography>
                  </Grid>
                  <Grid item mr={0.5}>
                    <StyledTypography
                      sx={{ fontWeight: 700, color: colors.BlueGray[600] }}
                    >
                      <span style={{ marginRight: "4px" }}>
                        {formatHourlyTime(
                          new Date(Date.parse(nextStop.eta)),
                          customerTimeZone
                        )}
                      </span>
                      <span>‧</span>
                      <span style={{ marginLeft: "4px" }}>
                        {nextStop.ett} min
                      </span>
                    </StyledTypography>
                  </Grid>
                  <Grid item>
                    <StyledTypography
                      sx={{ fontWeight: 400, color: colors.Grey[400] }}
                    >
                      until arrival
                    </StyledTypography>
                  </Grid>
                </Grid>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default TripProgess;
