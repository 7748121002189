import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  closeSnackbar,
  getSnackbarState,
} from "../../redux/slices/snackbar.slice";

const StyledSnackbar = () => {
  const { open, message, severity, position } =
    useAppSelector(getSnackbarState);
  const dispatch = useAppDispatch();
  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      anchorOrigin={position || { horizontal: "center", vertical: "top" }}
      onClose={() => dispatch(closeSnackbar())}
    >
      <Alert
        onClose={() => dispatch(closeSnackbar())}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default StyledSnackbar;
