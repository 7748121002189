import { Grid, useTheme } from "@mui/material";
import {
  LOGIN_HEADER_MARGIN_TOP,
} from "../../util/constants";
import StyledBackButton from "../UI/StyledBackButton";
import { routes } from "../../util/routes";
import CallGatikButton from "../UI/CallGatikButton";
import { useNavigate } from "react-router";

export interface ITripDetailHeaderProps {}

export default function TripDetailHeader(props: ITripDetailHeaderProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginTop: theme.spacing(LOGIN_HEADER_MARGIN_TOP) }}
    >
      <Grid item>
        <StyledBackButton onClick={() => navigate(routes.tripRoutes.root)} />
      </Grid>
      <Grid item>
        <CallGatikButton variant={"small"} />
      </Grid>
    </Grid>
  );
}
