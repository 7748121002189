import { Grid, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router";
import { routes } from "../../util/routes";
import StyledButton from "../UI/StyledButton";
import StyledTextfield from "../UI/StyledTextfield";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import { resetPasswordConfirm } from "../../redux/thunks/auth.thunk";
import { useAppDispatch } from "../../redux/hooks";
import { passwordSchema } from "./SignUpForm";
import * as yup from "yup";

const passwordValidation = yup.object().shape(passwordSchema);

export interface ILoginResetFormProps {}

export default function LoginResetForm(props: ILoginResetFormProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signature } = useParams();
  if (!signature) {
    return null;
  }
  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={async ({ password }) => {
        const action = await dispatch(
          resetPasswordConfirm({ password, signature }),
        );
        if (action.type === "auth/resetPasswordConfirm/rejected") {
          navigate({
            pathname: routes.loginRoutes.resetPassword,
            search: "?action=RESET",
          });
        } else if (action.payload) {
          navigate({
            pathname: routes.loginRoutes.success,
            search: "?action=RESET",
          });
        }
      }}
      validationSchema={passwordValidation}
      validateOnChange={true}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          flexGrow: 1,
        }}
      >
        <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
          <StyledTypography sx={typeset.Header1Small} align="center">
            Choose new password
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
          <StyledTextfield
            type="password"
            name="password"
            fullWidth
            color="primary"
            label="Password"
          />
        </Grid>
        <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
          <StyledTextfield
            type="password"
            name="confirmPassword"
            fullWidth
            color="primary"
            label="Re-enter Password"
          />
        </Grid>
        <Grid
          item
          sx={{
            marginTop: theme.spacing(4),
            width: "318px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <StyledButton
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            fullWidth
            disabled={false}
          >
            Change Password
          </StyledButton>
        </Grid>
      </Grid>
    </Formik>
  );
}
