import { Drawer, Grid, Icon } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  closeTripDetails,
  getTripDetailsState,
} from "../../redux/slices/layout.slice";
import { getTrips } from "../../redux/slices/trip.slice";
import { useCallback, useMemo } from "react";
import StyledTypography from "../UI/StyledTypography";
import * as typeset from "../../util/typeset";
import * as colors from "../../util/colors";
import { ReactComponent as CloseIcon } from "../../assets/dark_close_icon.svg";
import TripDetailCard from "./TripDetailCard";
import { useMobile } from "../../util/util";

export interface TripDrawerProps {
  isOpsPortal?: boolean;
}
const TripDrawer = (props: TripDrawerProps) => {
  const dispatch = useAppDispatch();
  const tripDetails = useAppSelector(getTripDetailsState);
  const trips = useAppSelector(getTrips);
  const isMobile = useMobile();

  // use passed-in trip if it exists, otherwise use state
  const trip = useMemo(() => {
    return tripDetails.trip || trips.find((trip) => trip.id === tripDetails.id);
  }, [tripDetails.id, tripDetails.trip, trips]);

  const closeDrawer = useCallback(
    () => dispatch(closeTripDetails()),
    [dispatch]
  );

  return (
    <Drawer
      anchor="right"
      open={tripDetails.isOpen}
      onClose={closeDrawer}
      sx={{
        maxWidth: isMobile ? "100%" : undefined,
      }}
    >
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        sx={{
          padding: 3,
          overflow: "auto",
          backgroundColor: "#f5f5f5",
          height: "100%",
          minWidth: isMobile ? undefined : 500,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <Grid item>
            <StyledTypography
              sx={{ ...typeset.Header2, color: colors.BlueGray[900] }}
            >
              Trip timeline view
            </StyledTypography>
          </Grid>
          <Grid item>
            <Icon
              onClick={closeDrawer}
              sx={{
                cursor: "pointer",
                width: "28px",
                height: "28px",
              }}
            >
              <CloseIcon />
            </Icon>
          </Grid>
        </Grid>
        <Grid item>
          {trip ? (
            <TripDetailCard trip={trip} isOpsPortal={props.isOpsPortal} />
          ) : (
            <StyledTypography>
              An error has occured fetching information related to this trip.
            </StyledTypography>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};
export default TripDrawer;
