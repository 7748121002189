import { Grid } from "@mui/material";
import * as colors from "../../util/colors";
import * as typeset from "../../util/typeset";
import StyledDialog from "../../components/UI/StyledDialog";
import StyledTypography from "../../components/UI/StyledTypography";
import { Formik } from "formik";
import * as yup from "yup";
import { useRef, useState } from "react";
import StyledDatePicker from "./StyledDatePicker";
import StyledButton from "./StyledButton";

export interface DateRangePickerDialogProps {
  open: boolean;
  headerText?: string;
  onClose: () => void;
  handleDateValueChange: (
    startDate: Date | null | undefined,
    endDate: Date | null | undefined
  ) => void;
}

const validationSchema = yup.object().shape({
  startDate: yup.string().required().label("Start date"),
  endDate: yup.string().required().label("End date"),
});

const DateRangePickerDialog = (props: DateRangePickerDialogProps) => {
  const { open, headerText, onClose, handleDateValueChange } = props;

  const [isDateError, setIsDateError] = useState<boolean>(false);

  const formRef = useRef<any>(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ startDate: null, endDate: null }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // set hours to beginning of the day
        const adjustedStartDate = new Date(values.startDate || "");
        adjustedStartDate.setHours(0, 0, 0, 0);
        const adjustedEndDate = new Date(values.endDate || "");
        adjustedEndDate.setHours(0, 0, 0, 0);
        // adjustedStartDate.setDate(values.startDate.getDate() + 1);
        handleDateValueChange(adjustedStartDate, adjustedEndDate);
        onClose();
      }}
    >
      {({ isValid, values, dirty, resetForm }) => (
        <StyledDialog
          onClose={onClose}
          onPrimaryClick={handleSubmit}
          onClickClose={onClose}
          primaryButtonText={"Save changes"}
          isPrimaryButtonDisabled={!dirty || !isValid || isDateError}
          onSecondaryClick={onClose}
          headerText={headerText || "Select date range"}
          open={open}
          maxWidth={false}
        >
          <Grid
            container
            direction="column"
            sx={{
              // padding + margin of default modal styles = 128px
              width: "calc(100vw - 128px)",
              maxWidth: "592px",
              padding: "20px",
              paddingTop: 4,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <StyledDatePicker
                  label={"Start date"}
                  maxDate={values.endDate ? values.endDate : undefined}
                  name={"startDate"}
                  sx={{
                    ".Mui-error .MuiOutlinedInput-notchedOutline": {
                      // disabled red error state when no date has been selected
                      borderColor:
                        values.startDate === null ? "inherit !important" : null,
                    },
                    ".MuiInputBase-input": {
                      color:
                        values.startDate === undefined
                          ? colors.Grey[500]
                          : undefined,
                    },
                  }}
                />
              </Grid>
              <Grid item display="flex" alignItems="center">
                <StyledTypography
                  sx={typeset.Headline}
                  style={{ height: "1rem", paddingTop: "0.5rem" }}
                >
                  -
                </StyledTypography>
              </Grid>
              <Grid item>
                <StyledDatePicker
                  label={"End date"}
                  name={"endDate"}
                  disableHighlightToday={true}
                  minDate={values.startDate ? values.startDate : undefined}
                  disabled={!values.startDate}
                  sx={{
                    ".Mui-error .MuiOutlinedInput-notchedOutline": {
                      // disabled red error state when no date has been selected
                      borderColor:
                        values.endDate === null ? "inherit !important" : null,
                    },
                    ".MuiInputBase-input": {
                      color:
                        values.endDate === undefined
                          ? colors.Grey[400]
                          : undefined,
                      // MUI uses this css attribute instead of color on disabled states for some reason, which overwrites the color attribute
                      WebkitTextFillColor: !values.startDate
                        ? colors.Grey[200]
                        : undefined,
                    },
                  }}
                  onError={(newError) => setIsDateError(newError !== null)}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ marginTop: 2 }}>
              <StyledButton
                variant="outlined"
                size="small"
                color="secondary"
                buttonStyle="rectangle"
                onClick={() => resetForm()}
              >
                Clear dates
              </StyledButton>
            </Grid>
          </Grid>
        </StyledDialog>
      )}
    </Formik>
  );
};

export default DateRangePickerDialog;
