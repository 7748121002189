import { useNavigate } from "react-router";
import { ReactComponent as LoginHeaderIcon } from "../../assets/gatik_omni.svg";
import StyledSmallHeader from "../UI/StyledSmallHeader";

const LoginSmallHeader = () => {
  const navigate = useNavigate();
  return (
    <StyledSmallHeader
      icon={<LoginHeaderIcon style={{ width: "100%", maxWidth: "300px", height: "auto" }} />}
      onClick={() => {
        navigate(-1);
      }}
    />
  );
};

export default LoginSmallHeader;
