import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status, Stop, Trip, TripStatus } from "../../types/trip.type";
import { PagingResults } from "../../types/util.type";
import { RootState } from "../store";
import { convertSet } from "../../util/util";

export interface TripState {
  requestId?: string;
  count: number;
  trips: Array<Trip>;
  stops: Array<Stop>;
  tripsFilter: {
    date: string;
    text: string;
    trip_status?: TripStatus;
    customer_id?: string;
  } | null;
  stopsFilter: {
    date: string;
    text: string;
    stop_status?: Status;
    customer_id?: string;
  } | null;
  currentTrip?: Trip;
  av_ids: Array<string>;
  lane_ids: Array<string>;
  stop_names: Array<string>;
  trip_names: Array<string>;
  searchOptions: Array<{ value: string; type: string }>;
}

const initialState: TripState = {
  requestId: "",
  count: 0,
  trips: [],
  stops: [],
  av_ids: [],
  lane_ids: [],
  stop_names: [],
  trip_names: [],
  searchOptions: [],
  stopsFilter: null,
  tripsFilter: null,
};

export const tripSlice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    setStops: (state, action: PayloadAction<PagingResults<Stop>>) => {
      state.stops = action.payload.results;
    },
    setCurrentTrip: (state, action: PayloadAction<Trip>) => {
      state.currentTrip = action.payload;
    },
    setTrips: (state, action: PayloadAction<PagingResults<Trip>>) => {
      state.trips = action.payload.results;
      state.count = action.payload.count;
    },
    setLatestRequestId: (
      state,
      action: PayloadAction<TripState["requestId"]>
    ) => {
      state.requestId = action.payload;
    },
    setTripFilter: (state, action: PayloadAction<TripState["tripsFilter"]>) => {
      state.tripsFilter = action.payload;
    },
    setStopFilter: (state, action: PayloadAction<TripState["stopsFilter"]>) => {
      state.stopsFilter = action.payload;
    },
    calculateSets: (state) => {
      const newAVSet = new Set<string>();
      const newLaneIdSet = new Set<string>();
      const newStopNameSet = new Set<string>();
      const newTripNameSet = new Set<string>();
      state.stops.forEach((stop) => {
        newAVSet.add(stop.av_id);
        if (stop.trip_template_external_id)
          newLaneIdSet.add(stop.trip_template_external_id);
        newStopNameSet.add(
          stop.site?.properties.name || stop.stop_info?.name || ""
        );
      });
      state.trips.forEach((trip) => {
        newAVSet.add(trip.av.av_id);
        if (trip.trip_template_external_id)
          newLaneIdSet.add(trip.trip_template_external_id);
        newTripNameSet.add(trip.itinerary.name);
      });
      state.av_ids = Array.from(newAVSet);
      state.lane_ids = Array.from(newLaneIdSet);
      state.stop_names = Array.from(newStopNameSet);
      state.trip_names = Array.from(newTripNameSet);
      state.searchOptions = ([] as Array<{ value: string; type: string }>)
        .concat(convertSet(newAVSet, "AV#"))
        .concat(convertSet(newLaneIdSet, "Lane ID"))
        .concat(convertSet(newStopNameSet, "Stop"))
        .concat(convertSet(newTripNameSet, "Trip"));
    },
  },
});

export const {
  setStops,
  setTrips,
  setLatestRequestId,
  setCurrentTrip,
  calculateSets,
  setStopFilter,
  setTripFilter,
} = tripSlice.actions;
export const getStops = (state: RootState) => state.trips.stops;
export const getTrips = (state: RootState) => state.trips.trips;
export const getLatestTripRequestId = (state: RootState) =>
  state.trips.requestId;
export const getTripsCount = (state: RootState) => state.trips.count;
export const getCurrentTrip = (state: RootState) => state.trips.currentTrip;
export const getSearchOptions = (state: RootState) => state.trips.searchOptions;
export const getTripFilter = (state: RootState) => state.trips.tripsFilter;
export default tripSlice.reducer;
