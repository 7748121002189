import { Grid, Icon } from "@mui/material";
import { addMinutes } from "date-fns";
import type { Identifier, XYCoord } from "dnd-core";
import { Formik, useFormikContext } from "formik";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Link } from "react-router-dom";
// import * as yup from "yup";
import { ReactComponent as EditPen } from "../../assets/edit_pen.svg";
import { ReactComponent as HandleIcon } from "../../assets/hamburger_handle.svg";
import { ReactComponent as NavIcon } from "../../assets/nav_arrow.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon.svg";
import {
  CreatedStop,
  SequenceStatus,
  Site,
  Status,
} from "../../types/trip.type";
import * as colors from "../../util/colors";
import { EMPTY_SITE } from "../../util/constants";
import * as typeset from "../../util/typeset";
import {
  formatHourlyTime,
  generateMapLink,
  getTimezoneText,
  useMobile,
  usePortraitTablet,
} from "../../util/util";
import SiteFilter from "../UI/SiteFilter";
import StyledButton from "../UI/StyledButton";
import StyledTextfield from "../UI/StyledTextfield";
import StyledTypography from "../UI/StyledTypography";
import { CreateTripLabelContext, StopFormValues } from "./TripCreateDialog";
import TripMenu from "./TripMenu";
import { Customer } from "../../types/customer.type";
import * as yup from "yup";

const SITE_FILTER_WIDTH = 233;

const CreatedStopSchema = yup.object().shape({
  travel_time: yup
    .number()
    .positive("Travel time must be a postive number")
    .required("You must enter a travel time"),
});

// const CreatedStopOriginSchema = yup.object().shape({
//   site_id: yup
//     .string()
//     .min(1, "You must select a site")
//     .required("You must select a site"),
// });

const initialValues: CreatedStop = {
  site_id: "",
  service_time: 0,
  travel_time: 0,
};

const CARD_TYPE = "TripCard";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export interface StopCreateCardProps {
  onCancel?: () => void;
  onSave?: (x: CreatedStop) => void;
  idx?: number;
  id?: string;
  onDelete?: () => void;
  stop?: CreatedStop;
  previousTime?: Date | null;
  defaultOpen?: boolean;
  readOnly?: boolean;
  menuItems?: Array<{ label: string; onClick: () => void }>;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  arrivedAt?: Date | null;
  departedAt?: Date | null;
  disableMove?: boolean;
  onChangeEditing?: (isEditing: boolean) => void;
  isBeingDeleted?: boolean;
  selectedCustomer?: Customer;
  onDragCard?: (cardIndex: number | undefined) => void;
  isDragHovered?: boolean;
  stopStatus?: Status;
  sequenceStatus?: SequenceStatus;
  isBeingMarkedArrived?: boolean;
  isBeingMarkedDeparted?: boolean;
  isNewInsertedStop?: boolean;
}

const DepartureTime = ({
  baseTime,
  departedAt,
  stop,
  stopStatus,
}: {
  baseTime: Date;
  departedAt?: Date | null;
  stop?: CreatedStop;
  stopStatus?: Status;
}) => {
  const { customer } = useContext(CreateTripLabelContext);
  const customerTimezone = customer?.timezone;
  const timeZoneText = useMemo(
    () => getTimezoneText(customerTimezone),
    [customerTimezone]
  );
  const serviceTime =
    useFormikContext<CreatedStop>().values.service_time || stop?.service_time;
  const travelTime =
    useFormikContext<CreatedStop>().values.travel_time || stop?.travel_time;

  const departureTime = useMemo(
    () =>
      addMinutes(
        baseTime,
        (serviceTime || 0) +
          ((travelTime || stop?.travel_time || 0) - (stop?.travel_time || 0))
      ),
    [baseTime, serviceTime, stop?.travel_time, travelTime]
  );
  return (
    <span>
      <b>
        {stopStatus === Status.AV_DEPARTED && !departedAt
          ? "--"
          : formatHourlyTime(
              departedAt || departureTime,
              customerTimezone
            )}{" "}
      </b>
      <span
        style={{
          color: colors.TrueGray[500],
        }}
      >
        {stopStatus !== Status.AV_DEPARTED && timeZoneText}
      </span>
    </span>
  );
};

const ArrivalTime = ({
  baseTime,
  stop,
  arrivedAt,
  stopStatus,
}: {
  baseTime: Date;
  arrivedAt?: Date | null;
  stop?: CreatedStop;
  stopStatus?: Status;
}) => {
  const { customer } = useContext(CreateTripLabelContext);
  const customerTimezone = customer?.timezone;
  const timeZoneText = useMemo(
    () => getTimezoneText(customerTimezone),
    [customerTimezone]
  );

  const travelTime = useFormikContext<CreatedStop>().values.travel_time;
  const arrivalTime = useMemo(
    () =>
      addMinutes(
        baseTime,
        (travelTime || stop?.travel_time || 0) - (stop?.travel_time || 0)
      ),
    [baseTime, stop?.travel_time, travelTime]
  );
  return (
    <span>
      <b>
        {(stopStatus === Status.AV_ARRIVED ||
          stopStatus === Status.AV_DEPARTED) &&
        !arrivedAt
          ? "--"
          : formatHourlyTime(arrivedAt || arrivalTime, customerTimezone)}{" "}
      </b>
      <span
        style={{
          color: colors.TrueGray[500],
        }}
      >
        {(stopStatus === Status.AV_ARRIVED ||
          stopStatus === Status.AV_DEPARTED) &&
        !arrivedAt
          ? ""
          : timeZoneText}
      </span>
    </span>
  );
};

const StopCreateCard = ({
  onCancel,
  onSave,
  onDelete,
  idx,
  id,
  stop,
  previousTime,
  defaultOpen = true,
  readOnly = false,
  menuItems,
  moveCard,
  arrivedAt,
  departedAt,
  disableMove,
  onChangeEditing,
  onDragCard,
  isBeingDeleted,
  selectedCustomer,
  isDragHovered,
  stopStatus,
  sequenceStatus,
  isBeingMarkedArrived,
  isBeingMarkedDeparted,
  isNewInsertedStop,
}: StopCreateCardProps) => {
  const siteId = stop?.site_id;
  const formik = useFormikContext<StopFormValues>();
  const { sites, setSites } = useContext(CreateTripLabelContext);
  const [open, setOpen] = useState(defaultOpen);
  const [site, setSite] = useState(
    sites.find((site) => site.id === siteId) || EMPTY_SITE
  );
  const [newSite, setNewSite] = useState<Site | undefined>();
  const [currentDragHoverIndex, setCurrentDragHoverIndex] = useState<
    number | undefined
  >();
  const isOrigin = idx === 0;
  const isDestination =
    (idx === formik.values.stops.length - 1 ||
      formik.values.stops.length === 1) &&
    !isOrigin;

  const isMobile = useMobile();
  const isPortraitTablet = usePortraitTablet();

  //Change site when stop changes
  useEffect(() => {
    setSite(sites.find((site) => site.id === siteId) || EMPTY_SITE);
  }, [siteId, sites]);

  useEffect(() => {
    if (onChangeEditing) {
      onChangeEditing(open);
    }

    setNewSite(site);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDelete = () => {
    if (setOpen) setOpen(false);
    if (onDelete) onDelete();
  };

  //Dragging logic
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: CARD_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current || !idx || !moveCard) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = idx;

      // set the current number card that you are drag hovering over
      setCurrentDragHoverIndex(dragIndex);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // These values represent the point in which the hovered card will move to the next place.
      const hoverTopY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 - 20;
      const hoverBottomY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 + 20;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is 20px before 50% (see: hoverTopY)
      // When dragging upwards, only move when the cursor is 20px before 50% (see: hoverBottomY)

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverTopY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverBottomY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: CARD_TYPE,
    item: () => {
      return { id, idx };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isCardArrived =
    stopStatus === Status.AV_ARRIVED || stopStatus === Status.AV_DEPARTED;
  const isCardDeparted = stopStatus === Status.AV_DEPARTED;
  const isCardSkipped = sequenceStatus === SequenceStatus.SKIPPED;

  // This logic is the same logic used in TripEditDialog and TripCreateDialog to determine whether or not we should allow a stop to be edited.
  const isStopEditable =
    !isOrigin &&
    !isDestination &&
    !isCardArrived &&
    !isCardDeparted &&
    !isCardSkipped;

  // If this stop meets the criteria, allow the user to drop the stop card.
  if (isStopEditable) {
    drag(dragRef);
    drop(preview(previewRef));
  }

  useEffect(() => {
    if (onDragCard) {
      onDragCard(currentDragHoverIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDragHoverIndex]);

  useEffect(() => {
    // once the user stops dragging a card, reset on dragcard to undefined to remove the opacity change.
    if (!isDragging && onDragCard) {
      onDragCard(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const siteName = site.properties.name;
  const siteLocation =
    newSite?.properties.description || site.properties.description;

  const stopLabel = idx !== undefined ? `STOP ${idx}` : "NEW STOP";

  if (!open || readOnly)
    return (
      <Grid
        data-handler-id={handlerId}
        ref={previewRef}
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        sx={
          readOnly
            ? { backgroundColor: "transparent" }
            : {
                border: `1px solid ${colors.TrueGray[500]}`,
                borderRadius: "8px",
                paddingRight: 2,
                opacity: isDragHovered ? 0 : 1,
              }
        }
      >
        <Grid
          container
          direction={isPortraitTablet ? "column" : "row"}
          alignItems={isPortraitTablet ? "flex-start" : "center"}
          justifyContent="space-between"
          sx={{
            width: "fit-content",
            minWidth: "calc(100% - 100px)",
            paddingLeft: "46px",
          }}
        >
          <Grid
            container
            direction={"row"}
            alignItems="center"
            sx={{ width: "auto", marginLeft: "-46px", flexWrap: "nowrap" }}
          >
            {!isDestination &&
            !isOrigin &&
            stopStatus === Status.AWAITING_AV &&
            !isCardSkipped &&
            !disableMove ? (
              <Grid
                item
                m={2}
                className="handle"
                ref={dragRef}
                sx={{
                  width: "24px",
                  height: "24px",
                  cursor: "grab",
                }}
              >
                <Icon
                  sx={
                    readOnly
                      ? {
                          pointerEvents: "none",
                          userSelect: "none",
                          display: "none",
                        }
                      : undefined
                  }
                >
                  <HandleIcon />
                </Icon>
              </Grid>
            ) : (
              <Grid item m={2} sx={{ width: "24px", height: "24px" }} />
            )}
            <Grid item>
              <StyledTypography
                sx={{
                  ...typeset.Headline,
                  fontWeight: "400",
                  width: isDestination ? 100 : 80,
                  opacity: isBeingDeleted ? "0.33" : "1",
                  color: colors.TrueGray[500],
                }}
              >
                {isOrigin ? (
                  "ORIGIN"
                ) : isDestination ? (
                  <>
                    FINAL
                    <br /> DESTINATION
                  </>
                ) : (
                  stopLabel
                )}
              </StyledTypography>
            </Grid>
            <Grid
              item
              alignItems="center"
              ml={1}
              sx={{
                width: isMobile ? undefined : "250px",
              }}
            >
              <StyledTypography
                sx={{
                  ...typeset.Header3,
                  fontSize: "16px",
                  fontWeight: "700",
                  opacity: isBeingDeleted ? "0.33" : "1",
                }}
              >
                {siteName}
                {sequenceStatus === SequenceStatus.SKIPPED
                  ? " (Skipped)"
                  : sequenceStatus === SequenceStatus.OUT_OF_SEQUENCE
                  ? " (Out-of-sequence)"
                  : null}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "fit-content",
              padding: 1,
              marginLeft: "0",
            }}
          >
            <Grid
              item
              sx={{
                // matches "Scheduled arrival" text width
                width: "150px",
                opacity: isBeingDeleted ? 0.33 : 1,
                paddingRight: 2.5,
                color: colors.TrueGray[500],
              }}
            >
              <StyledTypography>
                {stopStatus === Status.AV_ARRIVED ||
                stopStatus === Status.AV_DEPARTED
                  ? "Arrived at"
                  : "Scheduled arrival"}
              </StyledTypography>
              {isOrigin || !previousTime ? (
                <StyledTypography>N/A</StyledTypography>
              ) : (
                <StyledTypography
                  sx={{
                    color: colors.TrueGray[800],
                  }}
                >
                  <ArrivalTime
                    arrivedAt={arrivedAt}
                    baseTime={previousTime}
                    stop={stop}
                    stopStatus={stopStatus}
                  />
                </StyledTypography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                // matches "Scheduled departure" text width
                width: "158px",
                opacity: isBeingDeleted ? 0.33 : 1,
                color: colors.TrueGray[500],
              }}
            >
              <StyledTypography>
                {stopStatus === Status.AV_DEPARTED
                  ? "Departed at"
                  : "Scheduled departure"}
              </StyledTypography>
              {isDestination || !previousTime ? (
                <StyledTypography>N/A</StyledTypography>
              ) : (
                <StyledTypography
                  sx={{
                    color: colors.TrueGray[800],
                  }}
                >
                  <DepartureTime
                    departedAt={departedAt}
                    baseTime={previousTime}
                    stop={stop}
                    stopStatus={stopStatus}
                  />
                </StyledTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isBeingDeleted && (
          <Grid item>
            <StyledTypography sx={{ color: colors.Red[500], fontWeight: 700 }}>
              Deleted
            </StyledTypography>
          </Grid>
        )}
        {isBeingMarkedArrived && !isBeingMarkedDeparted && (
          <Grid item>
            <StyledTypography
              sx={{ color: colors.Emerald[600], fontWeight: 700 }}
            >
              Arrived
            </StyledTypography>
          </Grid>
        )}
        {isBeingMarkedDeparted && (
          <Grid item>
            <StyledTypography
              sx={{ color: colors.Emerald[600], fontWeight: 700 }}
            >
              Departed
            </StyledTypography>
          </Grid>
        )}
        {!readOnly &&
          stopStatus !== Status.AV_ARRIVED &&
          stopStatus !== Status.AV_DEPARTED &&
          !isBeingDeleted &&
          !isBeingMarkedArrived &&
          !isBeingMarkedDeparted && (
            <Grid item>
              <Icon sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                <EditPen />
              </Icon>
            </Grid>
          )}{" "}
        {menuItems && stopStatus !== Status.AV_DEPARTED && (
          <TripMenu options={menuItems} />
        )}
      </Grid>
    );

  return (
    <Formik
      onSubmit={(val) => {
        const newStop: CreatedStop = {
          ...val,
          site_id: newSite?.id || "",
        };
        if (onSave) {
          onSave(newStop);
          if (newSite) {
            setSites([...sites, newSite]);
          }
          // Not needed if we are using newSite as the currently selected site when editting?
          // if (!sites.find((findSite) => findSite.id === site.id)) {
          //   setSites([...sites, site]);
          // }
        } else {
          throw new Error(
            "You must pass a save function to stop create card when no readOnly"
          );
        }
      }}
      initialValues={stop || initialValues}
      // travel time is not required on origin stop
      validationSchema={
        isOrigin || isDestination ? undefined : CreatedStopSchema
      }
      validateOnMount={true}
    >
      {({ values, isValid, dirty, resetForm }) => (
        <Grid
          container
          sx={{
            borderRadius: "8px",
            border: `2px solid ${colors.Violet[700]}`,
            width: "100%",
          }}
          flexWrap="nowrap"
        >
          <Grid
            container
            direction="column"
            sx={{
              width: 50,
              borderRight: `1px solid ${colors.TrueGray[200]}`,
            }}
          >
            <Grid item></Grid>
          </Grid>
          <Grid container direction="column" sx={{ width: 22 }}>
            <Grid item></Grid>
          </Grid>
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: 2, paddingRight: 1 }}
            >
              <Grid item>
                <StyledTypography sx={typeset.Headline} mb={1}>
                  {isOrigin
                    ? "ORIGIN"
                    : isDestination
                    ? "FINAL DESTINATION"
                    : stopLabel}
                </StyledTypography>
              </Grid>
            </Grid>
            <Grid
              container
              mb={2}
              direction={isMobile ? "column" : "row"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              <Grid item sx={{ minWidth: SITE_FILTER_WIDTH }}>
                {selectedCustomer?.id && (
                  <SiteFilter
                    id="site_id"
                    customer_id={selectedCustomer.id}
                    isDisabled={!selectedCustomer.id}
                    defaultValue={site.properties.name}
                    onChangeSingle={(val) => {
                      setNewSite(val.data);
                    }}
                  />
                )}
              </Grid>
              <Grid
                container
                alignItems="center"
                ml={isMobile ? 0 : 2}
                mt={isMobile ? 1 : 0}
                sx={{ maxWidth: "260px" }}
              >
                <Grid item sx={{ width: 200 }}>
                  {siteLocation}
                </Grid>

                {siteLocation && (
                  <Grid item sx={{ height: "24px" }} ml={2}>
                    <Link
                      to={generateMapLink(siteLocation)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NavIcon />
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isOrigin && (
              <>
                <Grid container mb={1}>
                  <Grid item>
                    <StyledTypography sx={typeset.HeadlineSmall}>
                      Travel time from previous stop
                    </StyledTypography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  mb={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "flex-start" : "center"}
                >
                  <Grid
                    container
                    sx={{ width: "fit-content" }}
                    width={SITE_FILTER_WIDTH}
                    mr={isMobile ? 0 : isPortraitTablet ? 8 : 16}
                    mb={isMobile ? 1 : 0}
                  >
                    <StyledTextfield
                      name="travel_time"
                      type="number"
                      size="small"
                    />{" "}
                    <StyledTypography
                      sx={{ ...typeset.Caption3, lineHeight: "40px" }}
                      ml={1}
                    >
                      mins
                    </StyledTypography>
                  </Grid>
                  {previousTime && !isNewInsertedStop && (
                    <Grid item ml={isPortraitTablet ? 0 : 16}>
                      <StyledTypography
                        sx={{
                          color: colors.TrueGray[500],
                        }}
                      >
                        Scheduled arrival
                      </StyledTypography>
                      <StyledTypography>
                        <ArrivalTime
                          baseTime={previousTime}
                          stop={stop}
                          stopStatus={stopStatus}
                        />
                      </StyledTypography>
                    </Grid>
                  )}
                  <Grid item />
                </Grid>
              </>
            )}
            {!isDestination && (
              <>
                <Grid container mb={1}>
                  <Grid item>
                    <StyledTypography sx={typeset.HeadlineSmall}>
                      Stop duration
                    </StyledTypography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  mb={2}
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "flex-start" : "center"}
                >
                  <Grid
                    container
                    sx={{ width: "fit-content" }}
                    width={SITE_FILTER_WIDTH}
                    mr={isMobile ? 0 : isPortraitTablet ? 8 : 16}
                    mb={isMobile ? 1 : 0}
                  >
                    <StyledTextfield
                      name="service_time"
                      type="number"
                      size="small"
                    />{" "}
                    <StyledTypography
                      sx={{ ...typeset.Caption3, lineHeight: "40px" }}
                      ml={1}
                    >
                      mins
                    </StyledTypography>
                  </Grid>
                  {previousTime && !isNewInsertedStop && (
                    <Grid item ml={isPortraitTablet ? 0 : 16}>
                      <StyledTypography
                        sx={{
                          color: colors.TrueGray[500],
                        }}
                      >
                        Scheduled departure
                      </StyledTypography>
                      <StyledTypography>
                        <DepartureTime
                          baseTime={previousTime}
                          stop={stop}
                          stopStatus={stopStatus}
                        />
                      </StyledTypography>
                    </Grid>
                  )}
                  <Grid item />
                </Grid>
              </>
            )}
            <Grid container justifyContent="space-between" p={2}>
              {onDelete ? (
                <Grid item>
                  <StyledButton
                    variant="text"
                    size="small"
                    startIcon={<TrashIcon />}
                    color="errorAction"
                    onClick={handleDelete}
                  >
                    Delete stop
                  </StyledButton>
                </Grid>
              ) : (
                <Grid item></Grid>
              )}
              <Grid item>
                <StyledButton
                  size="small"
                  sx={{ marginRight: 2 }}
                  variant="text"
                  buttonStyle="rectangle"
                  color="secondary"
                  onClick={() => {
                    resetForm();
                    if (onCancel) {
                      onCancel();
                    } else setOpen(false);
                  }}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  type="submit"
                  variant="contained"
                  size="small"
                  color="secondaryAction"
                  disabled={
                    !newSite?.id ||
                    !isValid ||
                    (!dirty && !isOrigin && !isDestination)
                  }
                  buttonStyle="rectangle"
                  onClick={() => setOpen(false)}
                >
                  Save
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default StopCreateCard;
