import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Notification } from "../../types/notifications";
import { PagingResults } from "../../types/util.type";

export interface NotificationState {
  count: number;
  notifications: Array<Notification>;
}

const initialState: NotificationState = {
  count: 0,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<PagingResults<Notification>>) => {
      state.notifications = action.payload.results;
      state.count = action.payload.count;
    },
  },
});

export const { setNotifications } = notificationsSlice.actions;

export const getNotifications = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
