import fleetAxios from "./axios";

export const getTimeStopsDisplay = async () => {
  return fleetAxios
    .get(`/custom-settings/?name=EST_TIME_STOPS_DISPLAY`)
    .then((resp) => resp.data.value);
};

export const getManualBackup = async () => {
  return fleetAxios
    .get(`/custom-settings/?name=MANUAL_BACKUP`)
    .then((resp) => resp.data.value);
};

export const getCustomerSettings = async (customerId: string | undefined) => {
  return fleetAxios
    .get(`/custom-settings/${customerId ? "?customer_id=" + customerId : ""}`)
    .then((resp) => resp.data);
};

export interface SkippedStopsValues {
  name?: string;
  value: 0 | 1;
  level?: string;
}

export const updateSkippedStopsSetting = async (
  customer_id: string,
  payload: SkippedStopsValues
) => {
  return await fleetAxios.put(
    `/custom-settings/skipped-stops-detection/${customer_id}`,
    payload
  );
};
